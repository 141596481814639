import { FormEvent, useState, useEffect } from 'react';
import { usePerfil } from "../../hooks/usePerfil";
import { FormGroup, ButtonToolbar, Button, Icon} from 'rsuite';
import * as S from "./styles";
import InputMask from "react-input-mask";
import {Loading} from '../Loading'

export function PerfilForm() {
    const { perfil, isLoading, editPerfil, updateAvatar, updateSenha } = usePerfil();

    const [nome, setNome] = useState(perfil[0]?.nome || '');
    const [cpf, setCPF] = useState(perfil[0]?.cpf.br || '');
    const [email, setEmail] = useState(perfil[0]?.email || '');
    const [telefone, setTelefone] = useState(perfil[0]?.telefone?.br || '');
    const [celular, setCelular] = useState(perfil[0]?.celular?.br || '');
    const [data_nascimento, setDataNascimento] = useState(perfil[0]?.data_nascimento?.br || '');
    const [avatar, setAvatar] = useState(perfil[0]?.avatar || '');
    const [avatar_upload, setAvatarUpload] = useState('');

    const [senha_atual, setSenhaAtual] = useState('');
    const [nova_senha, setNovaSenha] = useState('');
    const [confirmar_senha, setConfirmarSenha] = useState('');


    useEffect(() => {
        if(perfil.length > 0){
            setNome(perfil[0].nome);
            setCPF(perfil[0].cpf.br);
            setEmail(perfil[0].email);
            setTelefone(perfil[0].telefone.br);
            setCelular(perfil[0].celular.br);
            setDataNascimento(perfil[0].data_nascimento?.br);
            setAvatar(perfil[0]?.avatar);
        }
    }, [perfil]);
    

    async function handlUpdatePerfil(event: FormEvent){
        event.preventDefault();

        let data = {  
            nome,
            cpf,
            email,
            telefone,
            celular,
            data_nascimento,
            avatar
        }

        await editPerfil(data, perfil[0]?.uuid);
    } 


    async function handleUpdatePerfilSenha(e: FormEvent){
        e.preventDefault();

        let data = {  
            senha_atual,
            nova_senha,
            confirmar_senha
        }
        

        await updateSenha(data);
    } 

  
    
    async function onSubmitAvatar(event: FormEvent){
        event.preventDefault();
        let data = {
            avatar: avatar_upload
        }
        await updateAvatar(data, perfil[0]?.uuid);
        setAvatarUpload('');
    }

    const onImageChange = (event: any ) => {
        const { files } = event.target;
        setAvatar(URL.createObjectURL(files[0]));
        setAvatarUpload(files[0]);
        
    };

    const handlClearFoto = async () => {
        setAvatar('');
        setAvatarUpload('');
    };
    
    

    return (
        <div>
        <S.FormFoto onSubmit={onSubmitAvatar} style={{ marginLeft: 30, marginTop: 30 }}>
          <FormGroup>
            <S.FotoContainer>
                {avatar ? (
                <>
                <img src={avatar} alt="imagem"  width="300" height="300" 
                onChange={onImageChange} />
                <input
                    type="file"
                    id="upload-button"
                    onChange={onImageChange}
                />
                <ButtonToolbar>
                    <Button appearance='ghost' type='reset' >
                    Trocar foto
                    </Button>
                </ButtonToolbar>
                </>
                ) : (
                <>
                <Icon icon="file-image-o" size="4x" />
                <input
                    type="file"
                    id="upload-button"
                    onChange={onImageChange}
                />
                <label>Clique aqui para inserir ou atualizar a imagem</label>
                </>
                )}
            </S.FotoContainer>
          </FormGroup>
          {avatar_upload !== '' ? 
          <ButtonToolbar>
            <Button appearance='primary' color='violet' type='submit'>
              Confirme o Upload
            </Button>
            <Button appearance='ghost' type='reset' onClick={() => handlClearFoto()}>
              Cancelar
            </Button>
          </ButtonToolbar>
          : null}
        </S.FormFoto>
        <hr/>
        <S.Container onSubmit={handlUpdatePerfil}>
            <div>
                {isLoading ? Loading() : ''}
            </div>
            <S.ContainerCardForm>
                <div className="card-form">
                    <FormGroup>
                        <label>CPF: </label>
                        <InputMask 
                            type="text" 
                            mask="999.999.999-99"
                            placeholder="CPF" 
                            onChange={event => setCPF(event.target.value)}    
                            value={cpf} 
                            disabled
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Nome</label>
                        <input
                            type="text"
                            placeholder="Nome"
                            value={nome} 
                            onChange={event => setNome(event.target.value)}    
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>E-mail: </label>
                        <input
                            type="text"
                            placeholder="E-mail"
                            value={email} 
                            onChange={event => setEmail(event.target.value)}   
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Telefone: </label>
                        <InputMask 
                            type="text" 
                            mask="(99) 9999-9999"
                            placeholder="Telefone" 
                            onChange={event => setTelefone(event.target.value)}    
                            value={telefone} 
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Celular: </label>
                        <InputMask 
                            type="text" 
                            mask="(99) 99999-9999"
                            placeholder="Celular"  
                            value={celular} 
                            onChange={event => setCelular(event.target.value)} 
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Data de nascimento: </label>
                        <InputMask 
                            type="text" 
                            mask="99/99/9999"
                            placeholder="Data de nascimento"  
                            value={data_nascimento} 
                            onChange={event => setDataNascimento(event.target.value)} 
                        />
                    </FormGroup>
                </div>
            </S.ContainerCardForm>
            <button type="submit">
                {isLoading ? "Carregando..." : "Atualizar"}
            </button>
        </S.Container>
        <hr/>
        <S.ContainerSenha onSubmit={handleUpdatePerfilSenha} >
            <S.ContainerCardForm >
                <div className="card-form">
                    <FormGroup>
                        <label>Senha Atual</label>
                        <input
                            type="password"
                            placeholder="Senha Atual"
                            autoComplete="off"
                            value={senha_atual} 
                            onChange={e => setSenhaAtual(e.target.value)}    
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Nova Senha</label>
                        <input
                            type="password"
                            placeholder="Nova senha"
                            autoComplete="off"
                            value={nova_senha} 
                            onChange={e => setNovaSenha(e.target.value)}    
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Confirmar Senha</label>
                        <input
                            type="password"
                            placeholder="Confirmar senha"
                            autoComplete="off"
                            value={confirmar_senha} 
                            onChange={e => setConfirmarSenha(e.target.value)}    
                        />
                    </FormGroup>
                </div>
            </S.ContainerCardForm>
            <button type="submit">
                {isLoading ? "Carregando..." : "Atualizar Senha"}
            </button>
        </S.ContainerSenha>
        </div>
    );
}
