import { usePedido } from "../../hooks/usePedido";
import * as S from "./styles";
import {Loading} from '../Loading'
import Skeleton from "react-loading-skeleton";
import { useCallback, useEffect, useState} from 'react'



export function PedidoTable() {

    const { pedido, detalhePedido, isLoading, detalhePedidoPorUUID, isLoadingDetalhe, alteraPedido } = usePedido();
    
    const [pedidoUuid, setPedido] = useState('');
    
    const handleLojaEdit = useCallback(async () => {
        if(pedido && pedido.length > 0) {
            setPedido(pedido[0].uuid);
        }

        if(pedidoUuid !== ''){
            await detalhePedido(pedidoUuid);
        }
        
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [pedido, pedidoUuid]);

    useEffect(() => {
        handleLojaEdit();
    }, [handleLojaEdit]);
    
    const handleClickVerPedido = async (uuid: string) => {

        await detalhePedido(uuid);
    };

    const handleClickAlterarPedido = async (uuid: string, status: number, loja: string) => {
        
        var status_alterado = 0;
        if(status === 2){
            status_alterado = 3;
        } else if(status === 3){
            status_alterado = 4;
        } else if(status === 4){
            return false;
        };

        await alteraPedido(uuid, status_alterado, loja);
    }

    return (
        <S.Container>
            <div>
                {isLoading ? <Loading /> : ''}
            </div>
            <S.Centraliza>
                <S.Esquerdo>
                    {pedido ? pedido.map(lista => (
                        <article key={lista.uuid} className={
                            lista.status === 1 && detalhePedidoPorUUID[0]?.uuid === lista.uuid ?
                            'active pagamento' :
                            lista.status === 2 && detalhePedidoPorUUID[0]?.uuid === lista.uuid ?
                            'active recebido' :
                            lista.status === 3 && detalhePedidoPorUUID[0]?.uuid === lista.uuid ?
                            'active preparando' :
                            lista.status === 4 && detalhePedidoPorUUID[0]?.uuid === lista.uuid  ?
                            'active entrega' :
                            lista.status === 6 && detalhePedidoPorUUID[0]?.uuid === lista.uuid  ?
                            'active entregue' : 
                            lista.status === 7 && detalhePedidoPorUUID[0]?.uuid === lista.uuid  ?
                            'active cancelado' : 
                            lista.status === 8 && detalhePedidoPorUUID[0]?.uuid === lista.uuid  ?
                            'active cancelado' : 
                            lista.status === 9 && detalhePedidoPorUUID[0]?.uuid === lista.uuid  ?
                            'active cancelado' : 
                            lista.status === 1 && detalhePedidoPorUUID[0]?.uuid !== lista.uuid ?
                            'pagamento' :
                            lista.status === 2 && detalhePedidoPorUUID[0]?.uuid !== lista.uuid ?
                            'recebido' :
                            lista.status === 3 && detalhePedidoPorUUID[0]?.uuid !== lista.uuid ?
                            'preparando' :
                            lista.status === 4 && detalhePedidoPorUUID[0]?.uuid !== lista.uuid  ?
                            'entrega' :
                            lista.status === 6 && detalhePedidoPorUUID[0]?.uuid !== lista.uuid  ?
                            'entregue' : 
                            lista.status === 7 && detalhePedidoPorUUID[0]?.uuid !== lista.uuid  ?
                            'cancelado' :
                            lista.status === 8 && detalhePedidoPorUUID[0]?.uuid !== lista.uuid  ?
                            'cancelado' :
                            lista.status === 9 && detalhePedidoPorUUID[0]?.uuid !== lista.uuid  ?
                            'cancelado' :
                            ''} 
                            onClick={() => handleClickVerPedido(lista.uuid)}
                            >
                            <div className="titulo">
                                <h1>
                                    {isLoading ? <Skeleton /> : lista.usuario?.dados?.nome}
                                </h1>
                                <span className={
                                    lista.status === 1 ?
                                    'pagamento' :
                                    lista.status === 2 ?
                                    'recebido' :
                                    lista.status === 3 ?
                                    'preparando' :
                                    lista.status === 4  ?
                                    'entrega' :
                                    lista.status ===6 ?
                                    'entregue' :
                                    lista.status === 7  ?
                                    'cancelado' : 
                                    lista.status === 8  ?
                                    'cancelado' : 
                                    lista.status === 9 ?
                                    'cancelado' : 
                                    ''}>
                                    {isLoading ? <Skeleton /> : 
                                    lista.status === 1 ?
                                    'Aguardando Pagamento' :
                                    lista.status === 2 ?
                                    'Pedido recebido' :
                                    lista.status === 3 ?
                                    'Pedido sendo preparado' :
                                    lista.status === 4 ?
                                    'Aguardando entregador' :
                                    lista.status === 6  ?
                                    'Pedido entregue' :
                                    lista.status === 7  ?
                                    'Pedido com problema' : 
                                    lista.status === 8  ?
                                    'Pedido cancelado' : 
                                    lista.status === 9  ?
                                    'Pedido estornado' :
                                    ''}
                                </span>
                            </div>
                        </article>
                    )) : 
                    <article>
                        <h1>Sem pedidos no momento</h1>
                    </article>
                    }
                </S.Esquerdo>
                <S.Direito>
                {isLoadingDetalhe === false && detalhePedidoPorUUID[0] ?
                    <div className="container">
                        <div className="pedido">
                            <article>
                                <h1>Cliente</h1>
                                <p>{detalhePedidoPorUUID[0].usuario?.dados?.nome}</p>
                                <p>{detalhePedidoPorUUID[0].usuario?.dados?.cpf}</p>
                            </article>
                            <article>
                                <h1>Endereço</h1>
                                <p>{detalhePedidoPorUUID[0].endereco}</p>
                            </article>
                            <article className="produto">
                                <h1>Produtos</h1>
                                {detalhePedidoPorUUID[0].produtos && detalhePedidoPorUUID[0].produtos.map((item : any) => (
                                    <>
                                    <div key={item.uuid}>
                                        <span>Nome</span>
                                        <p>{item?.nome}</p>
                                        <span>Descrição</span>
                                        <p>{item?.descricao}</p>
                                        <span>Adicionais</span>
                                        {item.adicionais && item.adicionais.map((item2 : any) => (
                                            <div>
                                                <span>{item2[0]}</span>
                                                <table width="300">
                                                    <tr>
                                                        <th align="left">Item</th>
                                                        <th align="center">Qntd.</th>
                                                        <th align="right">Valor</th>
                                                    </tr>
                                                    {item2[1] && item2[1].map((a : any) => (
                                                        <tr className="itemPedido">
                                                            <td>{a?.item}</td>
                                                            <td>{a?.quantidade}</td>
                                                            <td>R$ {a?.valor}</td>
                                                        </tr>
                                                    ))}
                                                </table>
                                            </div>
                                        ))}
                                    </div>
                                    </>
                                ))}
                            </article>
                        </div>
                        <div className="observacao">
                            <article>
                                <h1>Observação</h1>
                                <p>{detalhePedidoPorUUID[0].observacao}</p>
                            </article>
                            <article>
                                <h1>Valor</h1>
                                <p>Total: {detalhePedidoPorUUID[0].valor_total}</p>
                            </article>
                        </div>
                        <div className="botao">
                            {detalhePedidoPorUUID[0].status === 2 || detalhePedidoPorUUID[0].status === 3   || detalhePedidoPorUUID[0].status === 4 ?
                                <button className={
                                        detalhePedidoPorUUID[0].status === 1 ?
                                        'pagamento' :
                                        detalhePedidoPorUUID[0].status === 2 ?
                                        'recebido' :
                                        detalhePedidoPorUUID[0].status === 3 ?
                                        'preparando' :
                                        detalhePedidoPorUUID[0].status === 4  ?
                                        'entrega' :
                                        detalhePedidoPorUUID[0].status === 6 ?
                                        'entregue' :
                                        detalhePedidoPorUUID[0].status === 7  ?
                                        'cancelado' : 
                                        detalhePedidoPorUUID[0].status === 8  ?
                                        'cancelado' : 
                                        detalhePedidoPorUUID[0].status === 9 ?
                                        'cancelado' : 
                                        ''}
                                        onClick={() => handleClickAlterarPedido(detalhePedidoPorUUID[0].uuid, detalhePedidoPorUUID[0].status, detalhePedidoPorUUID[0].loja.uuid)}>
                                {
                                    detalhePedidoPorUUID[0].status === 2 ?
                                    'Preparar pedido' :
                                    detalhePedidoPorUUID[0].status === 3 ?
                                    'Chamar entregador' :
                                    detalhePedidoPorUUID[0].status === 4 ?
                                    'Aguardando entregador' : null
                                }
                                    
                                </button>  
                                : null}
                        </div>
                    </div>
                : 
                <article>
                </article>
                }
                </S.Direito>
            </S.Centraliza>
        </S.Container>
    );
}
