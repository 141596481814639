/* eslint-disable no-cond-assign */
import React, { FormEvent, useState} from 'react';
import { useProduto } from "../../hooks/useProduto"
import * as S from "./styles"
import formatReal from "../../utils/formatReal"
import { v4 as uuidv4 } from 'uuid';
import {Loading} from '../Loading';


import {FormGroup, InputGroup, Icon} from 'rsuite';

export function CardItem() {  

    return (
        <S.CardAdicionalItem className="containerAdicionalItem" data-uuiditem={uuidv4()}>
            <div className="excluirElementoItem">x</div>
            <S.Row>
                <FormGroup  className="item">
                    <label>Nome: </label>
                    <input 
                        type="text" 
                        placeholder="Nome"
                        name="nomeItem"
                        
                    /> 
                </FormGroup>
                <FormGroup className="item">
                    <label>Valor: </label>
                    <input 
                        type="text" 
                        placeholder="Valor"
                        name="valorItem"
                    /> 
                </FormGroup>
            </S.Row>
            <S.Row >
                <div className="quantidade">
                    <FormGroup>
                        <label>Quantidade</label>
                        <input 
                            type="number" 
                            name="quantidadeItem" 
                            placeholder="Quantidade" />
                    </FormGroup>
                    <FormGroup className="obrigatorio">
                        <input type="checkbox" name="status_adicional_item" onChange={()=>{}} />
                        <label>Pausar?</label>
                    </FormGroup>
                </div>
            </S.Row>
        </S.CardAdicionalItem>
    );
}

export function NovoProduto() {
    const { isLoading, createProduto } = useProduto();
    const LCHAMA = localStorage.getItem('LCHAMA');

 
    const [imagem, setImagem] = useState('');
    const [imagem_upload, setImagemUpload] = useState('');
    const [nomeProduto, setNome] = useState('');
    const [descricao, setDescricao] = useState('');
    const [valor, setValor] = useState('');
    const [status, setStatus] = useState('');
    const [elemento, setElemento] = useState(() => [<p key={0}></p>]);


    async function handleEditProduto(event: FormEvent){
        event.preventDefault();

        var inputs, index, indexItem, len, inputsItem;

        inputs =  document.querySelectorAll(".containerAdicional");
        len = inputs.length;
        var arrayAdicional : any[] = [];
   
        var AdicionalItem : any[] = [];
        for (index = 0; index < len; ++index) {
            if(inputs){

               inputsItem =  inputs[index].querySelectorAll(".containerAdicionalItem");
                AdicionalItem = [];
                if(inputsItem){
                    for (indexItem = 0; indexItem < inputsItem.length; ++indexItem) {
                        AdicionalItem[indexItem] = {
                            uuid: inputsItem[indexItem].getAttribute('data-uuiditem'),
                            adicional: (inputsItem[indexItem]?.parentNode?.parentNode as HTMLInputElement)?.getAttribute('data-uuid') || "",
                            nome: (inputsItem[indexItem].querySelector('input[name="nomeItem"]') as HTMLInputElement).value || "",
                            valor: (inputsItem[indexItem].querySelector('input[name="valorItem"]') as HTMLInputElement).value || "",
                            quantidade: (inputsItem[indexItem].querySelector('input[name="quantidadeItem"]') as HTMLInputElement).value || "",
                            tipo: Number(1),
                            status: (inputsItem[indexItem].querySelector('input[name="status_adicional_item"]:checked') as HTMLInputElement) === null ? Number(1) : Number(2),
                        }
                    }
                }

                 arrayAdicional[index] = {
                    uuid: inputs[index].getAttribute('data-uuid'),
                    titulo: (inputs[index].querySelector('input[name="tituloAdicional"]') as HTMLInputElement).value || "",
                    quantidade: (inputs[index].querySelector('input[name="quantidade"]') as HTMLInputElement).value || "",
                    obrigatorio: (inputs[index].querySelector('input[name="obrigatorio"]:checked') as HTMLInputElement) === null ? Number(2) : Number(1),
                    status: (inputs[index].querySelector('input[name="statusAdicional"]:checked') as HTMLInputElement) === null ? Number(1) : Number(2),
                    tipo: Number(1),
                    itens: AdicionalItem || ""
                }
            }

        };

                

        const data = {
            imagem: imagem_upload,
            nome: nomeProduto,
            descricao,
            valor,
            loja:LCHAMA,
            status,
            adicionais:arrayAdicional
        };

        await createProduto(data);
    }

    //item

    const onImageChange = (event: any) => {
        const { files } = event.target;
        setImagem(URL.createObjectURL(files[0]));
        setImagemUpload(files[0]);
        
    };

    const handlClearFoto = async () => {
        setImagem('');
        setImagemUpload('');
    };


    const Cards = () => {
        return (
            <div>
                <S.CardAdicional className="containerAdicional" data-uuid={uuidv4()}>
                    <div className="excluirElemento" onClick={(e) => handleExcluir((e.target as Element)?.parentNode)}>x</div>
                    <FormGroup>
                        <label>Título: </label>
                        <input 
                            type="text" 
                            placeholder="Título"
                            name="tituloAdicional"
                        /> 
                    </FormGroup>
                    <div className="quantidade">
                        <FormGroup>
                            <label>Quantidade</label>
                            <input type="text" 
                            placeholder="Quantidade"
                            name="quantidade" />
                        </FormGroup>
                        <FormGroup className="obrigatorio">
                            <input type="checkbox" name="obrigatorio" onClick={(e) => clickObrigatorio(e)}  />
                            <label htmlFor="obrigatorio">Obrigatório?</label>
                        </FormGroup>
                        <FormGroup className="obrigatorio">
                            <input type="checkbox" name="statusAdicional" onChange={()=>{}} />
                            <label htmlFor="statusAdicional">Pausar?</label>
                        </FormGroup>
                    </div>
                    <div className="container_item"  onClick={(e) => clickAdditem(e)} onKeyUp={(e) => onkeyUp(e)}>
                        <CardItem />
                    </div>
                    
                    <div className="adicionar_item"  onClick={(e) => addJItemsx((e.target as Element)?.parentNode?.parentNode)}>
                        <p >+ Adicionar item</p>
                    </div>
                </S.CardAdicional>
            </div>
        )
    } 

    const addJsx = () => {
        let item = <Cards key={uuidv4()} />;
        let newItems = [...elemento, item];
        setElemento(newItems);
    };   
     var n;
    function cleanUpInputs(obj:any) {
        for (var i = 0; n = obj.childNodes[i]; ++i) {
      
            if (n.childNodes && n.tagName !== 'INPUT') {
                cleanUpInputs(n);
            } else if (n.tagName === 'INPUT' && n.type === 'text') {
                n.value = '';
            } else if (n.tagName === 'INPUT' && n.type === 'number') {
                n.value = '';
            }
        }
    }

    const addJItemsx = (e:any) => {
        let adicional = e.querySelector('.container_item').querySelector('.containerAdicionalItem');

        let clone = adicional.cloneNode(true);

        clone.dataset.uuiditem = uuidv4();
        cleanUpInputs(clone);
        e.querySelector('.container_item').appendChild(clone);
    }; 

    const handleExcluir = (e:any) => {
        e.remove();
    };     

    function clickObrigatorio(e:any) {
       if(e.target.checked === true){
           e.target.parentNode.parentNode.querySelector('input[name="quantidade"]').disabled = true;
           e.target.parentNode.parentNode.querySelector('input[name="quantidade"]').value = '';
       }else if(e.target.checked === false){
           e.target.parentNode.parentNode.querySelector('input[name="quantidade"]').disabled = false;
     

       }
    }

    function clickAdditem(e:any) {
        if (e.target.className === 'excluirElementoItem') {
            e.target.parentNode.remove();
        }
    }
    function onkeyUp(e:any) {
        if (e.target.name === 'valorItem') {
            e.target.value = formatReal(e.target.value);
        }
    }


    return (
       
        <S.Container onSubmit={handleEditProduto} id="form">     
            <div>
                {isLoading ? <Loading /> : ''}
            </div>
            <S.ContainerCardForm>
                <div className="card-form ">
                    <S.Row className="produto">
                        <S.FotoContainer>
                            {imagem ? (
                            <>
                                <InputGroup className="close_search" label="Excluir foto">
                                    <InputGroup.Button onClick={()=> handlClearFoto()}>
                                        <Icon icon="close" />
                                    </InputGroup.Button>
                                </InputGroup>
                                <img src={imagem} alt="imagem" width="300" height="300" onChange={onImageChange} />
                            </>
                            ) : (
                            <div className="crop">
                                <Icon icon="file-image-o" size="4x" />
                                <input type="file" id="upload-button" onChange={onImageChange} />
                            </div>
                            )}
                        </S.FotoContainer>
                        <S.Column>
                            <S.Row>
                                <FormGroup>
                                    <label>Nome: </label>
                                    <input 
                                        type="text" 
                                        placeholder="Nome"
                                        value={nomeProduto}
                                        onChange={event => setNome(event.target.value)}
                                        
                                    /> 
                                </FormGroup>
                                <FormGroup>
                                    <label>Valor:</label>
                                    <input 
                                        type="text" 
                                        placeholder="Valor" 
                                        value={valor}
                                        onChange={event => setValor(formatReal(event.target.value))}
                                    />
                                </FormGroup>
                            </S.Row>
                            <FormGroup>
                                <label>Descrição: </label>
                                <textarea 
                                    placeholder="Descrição" 
                                    value={descricao}
                                    className="descricao"
                                    onChange={event => setDescricao(event.target.value)}
                                    
                                ></textarea>
                            </FormGroup>
                            <FormGroup>
                                <label>Status:</label>
                                <S.StatusFilialContainer>
                                    <S.RadioBox 
                                        type="button"
                                        onClick={() => setStatus(String(1))}
                                        isActive={status === '1'}
                                        activeColor="green"
                                        activeColorFonte="white"
                                    >   
                                        <span>Ativo</span>
                                    </S.RadioBox>
                                    <S.RadioBox 
                                        type="button"
                                        onClick={() => setStatus(String(2))}
                                        isActive={status === '2'}
                                        activeColor="red"
                                        activeColorFonte="white"
                                    >
                                        <span>Inativo</span>
                                    </S.RadioBox>
                                </S.StatusFilialContainer>
                            </FormGroup>
                        </S.Column>
                    </S.Row>
                </div>
                <div className="card-form adicional">
                    <h2>Adicionais</h2>
                    <div id="adicionalContainer">
                        {elemento}
                    </div>
                    <div className="adicionar_categoria" onClick={() => addJsx()}>
                        <p>+ Adicionar</p>
                    </div>
                </div>
            </S.ContainerCardForm>
            
            <button type="submit">{isLoading ? 'Carregando...' : 'Cadastrar'}</button>
        </S.Container>
    )
}
