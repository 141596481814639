import { FormEvent, useState } from 'react'
import Modal from 'react-modal'
import { useFilial } from "../../hooks/useFilial"
import InputMask from "react-input-mask";


import { MdClose } from "react-icons/md"
import * as S from "./styles"
import api from "../../services/api";

import {FormGroup, InputGroup, Icon,Alert} from 'rsuite';
import {Loading} from '../Loading';


interface NewFilialModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
}

export function NewFilialModal({isOpen, onRequestClose}: NewFilialModalProps) {
    const { createFilial, isLoading } = useFilial();

    const [logo, setLogo] =  useState('');
    const [logo_upload, setLogoUpload] = useState('')
    const [razao_social, setRazaoSocial] = useState('');
    const [nome_fantasia, setNomeFantasia] = useState('');
    const [email, setEmail] = useState('');
    const [cnpj, setCNPJ] = useState('');
    const [telefone, setTelefone] = useState('');
    const [celular, setCelular] = useState('');
    const [nome, setNome] = useState('');
    const [cep, setCEP] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [complemento, setComplemento] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [comissao, setComissao] = useState('');
    const [comissao_loja, setComissaoLoja] = useState('');
    const [password, setSenha] = useState('');
    const [password_confirm, setConfirmeSenha] = useState('');
    const [status, setStatus] = useState('');
    const [login, setLogin] = useState('');
    const [cpf, setCPF] = useState('');
    const [nascimento, setNascimento] = useState('');
    
     async function handleCreateNewFilial(event: FormEvent){
        event.preventDefault();

        if(logo_upload === ''){
            Alert.error('A imagem é obrigatória!');
            return false;
        }else if(password !== password_confirm){
            Alert.error('As senhas não estão iguais!');
            return false;
        }else if(cnpj === ''){
            Alert.error('O campo cnpj é obrigatório!');
            return false;
        }else if(razao_social === ''){
            Alert.error('O campo razão social é obrigatório!');
            return false;
        }else if(nome_fantasia === ''){
            Alert.error('O campo nome fantasia é obrigatório!');
            return false;
        }else if(cep === ''){
            Alert.error('O campo cep é obrigatório!');
            return false;
        }else if(logradouro === ''){
            Alert.error('O campo logradouro é obrigatório!');
            return false;
        }else if(complemento === ''){
            Alert.error('O campo complemento é obrigatório!');
            return false;
        }else if(numero === ''){
            Alert.error('O campo número é obrigatório!');
            return false;
        }else if(bairro === ''){
            Alert.error('O campo bairro é obrigatório!');
            return false;
        }else if(cidade === ''){
            Alert.error('O campo cidade é obrigatório!');
            return false;
        }else if(cidade === ''){
            Alert.error('O campo cidade é obrigatório!');
            return false;
        }else if(estado === ''){
            Alert.error('O campo estado é obrigatório!');
            return false;
        }else if(nome === ''){
            Alert.error('O campo nome é obrigatório!');
            return false;
        }else if(email === ''){
            Alert.error('O campo email é obrigatório!');
            return false;
        }else if(telefone === ''){
            Alert.error('O campo telefone é obrigatório!');
            return false;
        }else if(comissao === ''){
            Alert.error('O campo comissão é obrigatório!');
            return false;
        }else if(comissao_loja === ''){
            Alert.error('O campo comissão da loja é obrigatório!');
            return false;
        }else if(status === ''){
            Alert.error('O campo status é obrigatório!');
            return false;
        } else if(password === ''){
            Alert.error('O campo senha é obrigatório!');
            return false;
        }
        else if(login === ''){
            Alert.error('O campo login é obrigatório!');
            return false;
        }
        else if(cpf === ''){
            Alert.error('O campo cpf é obrigatório!');
            return false;
        }
        else if(nascimento === ''){
            Alert.error('O campo Data de nascimento é obrigatório!');
            return false;
        }




        const data = {
            logo: logo_upload,
            cnpj,
            razao_social,
            nome_fantasia,
            cep,
            logradouro,
            complemento,
            numero,
            bairro,
            cidade,
            estado,
            nome,
            email,
            telefone,
            celular,
            comissao,
            comissao_loja,
            status,
            password,
            login,
            cpf,
            nascimento
        };

        await createFilial(data, onRequestClose);

    }

	/**
	 * Pesquisar cep
	 */
	const estados = {
		Estado: "Estado",
		AC: "Acre",
		AL: "Alagoas",
		AP: "Amapá",
		AM: "Amazonas",
		BA: "Bahia",
		CE: "Ceará",
		DF: "Distrito Federal",
		ES: "Espírito Santo",
		GO: "Goiás",
		MA: "Maranhão",
		MT: "Mato Grosso",
		MS: "Mato Grosso do Sul",
		MG: "Minas Gerais",
		PA: "Pará",
		PB: "Paraíba",
		PR: "Paraná",
		PE: "Pernambuco",
		PI: "Piauí",
		RJ: "Rio de Janeiro",
		RN: "Rio Grande do Norte",
		RS: "Rio Grande do Sul",
		RO: "Rondônia",
		RR: "Roraima",
		SC: "Santa Catarina",
		SP: "São Paulo",
		SE: "Sergipe",
		TO: "Tocantins",
	};

	function options() {
		var es = Object.entries(estados);
		return es.map((item) => (
			<option key={`estado${item[0]}`} value={item[0]}>
				{item[1]}
			</option>
		));
	}

    const onImageChange = (event: any ) => {
        const { files } = event.target;
        setLogo(URL.createObjectURL(files[0]));
        setLogoUpload(files[0]);
        
    };

    const handlClearFoto = async () => {
        setLogo('');
        setLogoUpload('');
    };    
    
    async function pesquisacep(valor:any) {
		var cep = valor;

		if (cep !== "") {
            api.get('/endereco/geolocalizacao/'+cep+'/cep')
                .then((res: any) => {
                    setLogradouro(res.data.endereco.logradouro);
                    setBairro(res.data.endereco.bairro);
                    setCidade(res.data.endereco.localidade);
                    setEstado(res.data.endereco.uf);
                 
            }).catch((error: any) => console.log(error.response));
                 
		}
	}
    
    

    return (
        
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <button type="button" onClick={onRequestClose} className="react-modal-close">
                <MdClose />
            </button>
            <S.Container onSubmit={handleCreateNewFilial}>
                <div>
                    {isLoading ? <Loading /> : ''}
                </div>
                <h2>Cadastrar Filial</h2>
                <S.ContainerCardForm>
                    <div className="card-form">
                        <FormGroup>
                            <S.FotoContainer>
                                {logo ? (
                                <>
                                <InputGroup className="close_search" label="Excluir logo">
                                    <InputGroup.Button onClick={() => handlClearFoto()}>
                                        <Icon icon="close" />
                                    </InputGroup.Button>
                                </InputGroup>
                                <img src={logo} alt="imagem"  width="300" height="300" 
                                onChange={onImageChange} />
                                </>
                                ) : (
                                <>
                                <Icon icon="file-image-o" size="4x" />
                                <input
                                    type="file"
                                    id="upload-button"
                                    onChange={onImageChange}
                                />
                                <label>Clique aqui para inserir uma imagem</label>
                                </>
                                )}
                            </S.FotoContainer>
                        </FormGroup>
                    </div>
                    <S.Row>
                    <div className="card-form">
                        <h3>Dados da empresa</h3>
                        <FormGroup>
                            <label>CNPJ:</label>
                            <InputMask 
                                type="text" 
                                mask="99.999.999/9999-99"
                                placeholder="CNPJ" 
                                value={cnpj}
                                onChange={event => setCNPJ(event.target.value)}
                            />
                        </FormGroup>
                         <FormGroup>
                             <label>Razão Social: </label>
                            <input 
                                type="text" 
                                placeholder="Razão social"
                                value={razao_social}
                                onChange={event => setRazaoSocial(event.target.value)}
                                
                            />
                         </FormGroup>
                         <FormGroup>
                             <label>Nome fantasia: </label>
                            <input 
                                type="text" 
                                placeholder="Nome fantasia"
                                value={nome_fantasia}
                                onChange={event => setNomeFantasia(event.target.value)}
                                
                            />
                         </FormGroup>
                        <FormGroup>
                            <label>CEP:</label>
                            <InputMask
                                mask="99999-999"
                                value={cep}
                                placeholder="CEP"
                                type="text" 
                                onChange={(event) => {
                                    setCEP(event.target.value);
                                }}
                                onBlur={(event) =>
                                    pesquisacep(event.target.value)
                                }
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Logradouro:</label>
                            <input 
                                type="text" 
                                placeholder="Logradouro"    
                                value={logradouro}
                                onChange={event => setLogradouro(event.target.value)}
                                
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Complemento:</label>
                            <input 
                                type="text" 
                                placeholder="Complemento"    
                                value={complemento}
                                onChange={event => setComplemento(event.target.value)}
                                
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Número:</label>
                            <input 
                                type="text" 
                                placeholder="Número" 
                                value={numero}
                                onChange={event => setNumero(event.target.value)}
                                
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Bairro:</label>
                            <input 
                                type="text" 
                                placeholder="Bairro" 
                                value={bairro}
                                onChange={event => setBairro(event.target.value)}
                                
                        />
                        </FormGroup>
                        <FormGroup>
                            <label>Cidade:</label>
                            <input 
                                type="text" 
                                placeholder="Cidade" 
                                value={cidade}
                                onChange={event => setCidade(event.target.value)}
                                
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Estado:</label>
                            <select
                                placeholder="Estado"
                                onChange={(e) => {
                                    setEstado(e.target.value);
                                }}
                                value={estado}
                            >
                                {options()}
                        </select>
                        </FormGroup>
                        
                    </div>
                    <S.Column>
                        <div className="card-form">
                            <h3>Dados dos responsáveis - adm</h3>
                            <FormGroup>
                                <label>Nome: </label>
                                <input 
                                    type="text" 
                                    placeholder="Nome" 
                                    value={nome}
                                    onChange={event => setNome(event.target.value)}
                                    
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>CPF: </label>
                                <InputMask 
                                    type="text" 
                                    mask="999.999.999-99"
                                    placeholder="CPF" 
                                    onChange={event => setCPF(event.target.value)}    
                                    value={cpf} 
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>E-mail: </label>
                                <input 
                                    type="email" 
                                    placeholder="E-mail" 
                                    value={email}
                                    onChange={event => setEmail(event.target.value)}
                                    
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Data de nascimento: </label>
                                <InputMask 
                                    type="text" 
                                    mask="99/99/9999"
                                    placeholder="Data de nascimento"  
                                    value={nascimento} 
                                    onChange={event => setNascimento(event.target.value)} 
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Telefone:</label>
                                <InputMask 
                                    type="text" 
                                    mask="(99) 9999-9999"
                                    placeholder="Telefone" 
                                    onChange={event => setTelefone(event.target.value)}    
                                    value={telefone} 
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Celular:</label>
                                <InputMask 
                                    type="text" 
                                    mask="(99) 99999-9999"
                                    placeholder="Celular"  
                                    value={celular}
                                    onChange={event => setCelular(event.target.value)} 
                                />
                            </FormGroup>
                        </div>

                        <div className="card-form">
                            <h3>Controle</h3>
                            <FormGroup>
                                <label>Comissão:</label>
                                <input 
                                    type="number" 
                                    min="1" 
                                    max="100" 
                                    placeholder="Comissão, Ex: 00.00" 
                                    value={comissao}
                                    onChange={event => setComissao(event.target.value)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Comissão da Loja:</label>
                                <input 
                                    type="number" 
                                    min="1" 
                                    max="100" 
                                    placeholder="Comissão loja, Ex: 00.00" 
                                    value={comissao_loja}
                                    onChange={event => setComissaoLoja(event.target.value)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Status:</label>
                                <S.StatusFilialContainer>
                                    <S.RadioBox 
                                        type="button"
                                        onClick={() => setStatus(String(1))}
                                        isActive={status === '1'}
                                        activeColor="green"
                                        activeColorFonte="white"
                                    >   
                                        <span>Ativo</span>
                                    </S.RadioBox>
                                    <S.RadioBox 
                                        type="button"
                                        onClick={() => setStatus(String(2))}
                                        isActive={status === '2'}
                                        activeColor="red"
                                        activeColorFonte="white"
                                    >
                                        <span>Inativo</span>
                                    </S.RadioBox>
                                </S.StatusFilialContainer>
                            </FormGroup>
                        </div>
                    </S.Column>
                    <div className="card-form">
                        <h3>Dados de login</h3>
                        <FormGroup>
                            <label>E-mail: </label>
                            <input 
                                type="email" 
                                placeholder="E-mail" 
                                value={login}
                                onChange={event => setLogin(event.target.value)}
                                
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Senha: </label>
                            <input
                                type="password" 
                                placeholder="Senha"  
                                value={password} 
                                onChange={event => setSenha(event.target.value)} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Confirme a senha: </label>
                            <input
                                type="password" 
                                placeholder="Senha"  
                                value={password_confirm} 
                                onChange={event => setConfirmeSenha(event.target.value)} 
                            />
                        </FormGroup>
                    </div>
                    </S.Row>
                </S.ContainerCardForm>
                
                <button type="submit">{isLoading ? 'Carregando...' : 'Cadastrar'}</button>
            </S.Container>
        </Modal>
    )
}
