import { FormEvent, useState } from 'react'
import Modal from 'react-modal'
import { useCategoria } from "../../hooks/useCategoria"

import { MdClose } from "react-icons/md"
import * as S from "./styles"

import {FormGroup, InputGroup, Icon} from 'rsuite';
import {Loading} from '../Loading';


interface NewCategoriaModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
}

export function NewCategoriaModal({isOpen, onRequestClose}: NewCategoriaModalProps) {
    const { isLoading, createCategoria } = useCategoria();

    const [imagem, setImagem] =  useState('');
    const [imagem_uplaod, setImagemUpload] =  useState('');
    const [nome, setNome] = useState('');
    const [status, setStatus] = useState('');

     async function handleCreateNewUsuarioFilial(event: FormEvent){
        event.preventDefault();

        const data = {
            nome,
            imagem: imagem_uplaod,
            status
        };

        await createCategoria(data, onRequestClose);

      
    }
    
  
    const onImageChange = (event: any ) => {
        const { files } = event.target;
        setImagem(URL.createObjectURL(files[0]));
        setImagemUpload(files[0]);
        
    };

    const handlClearFoto = async () => {
        setImagem('');
        setImagemUpload('');
    };


    return (
        
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <button type="button" onClick={onRequestClose} className="react-modal-close">
                <MdClose />
            </button>
            <S.Container onSubmit={handleCreateNewUsuarioFilial}>
                <div>
                    {isLoading ? <Loading /> : ''}
                </div>
                <h2>Cadastrar Categoria</h2>
                <S.ContainerCardForm>
                <div className="card-form">
                    <FormGroup>
                        <S.FotoContainer>
                        {imagem ? (
                        <>
                        <InputGroup className="close_search" label="Excluir avatar">
                            <InputGroup.Button onClick={() => handlClearFoto()}>
                                <Icon icon="close" />
                            </InputGroup.Button>
                        </InputGroup>
                        <figure style={{ backgroundImage: `url(${imagem})` }} onChange={onImageChange}></figure>
                        </>
                        ) : (
                        <>
                        <Icon icon="file-image-o" size="4x" />
                        <input
                            type="file"
                            id="upload-button"
                            onChange={onImageChange}
                        />
                        <label>Clique aqui para inserir uma imagem</label>
                        </>
                        )}
                        </S.FotoContainer>
                    </FormGroup>
                    <FormGroup>
                        <label>Nome</label>
                        <input
                            type="text"
                            placeholder="Nome"
                            value={nome} 
                            onChange={event => setNome(event.target.value)}    
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Status:</label>
                        <S.StatusFilialContainer>
                            <S.RadioBox 
                                type="button"
                                onClick={() => setStatus(String(1))}
                                isActive={status === '1'}
                                activeColor="green"
                                activeColorFonte="white"
                            >   
                                <span>Ativo</span>
                            </S.RadioBox>
                            <S.RadioBox 
                                type="button"
                                onClick={() => setStatus(String(2))}
                                isActive={status === '2'}
                                activeColor="red"
                                activeColorFonte="white"
                            >
                                <span>Inativo</span>
                            </S.RadioBox>
                        </S.StatusFilialContainer>
                    </FormGroup>

                </div>
                </S.ContainerCardForm>
                
                <button type="submit">{isLoading ? 'Carregando...' : 'Cadastrar'}</button>
            </S.Container>
        </Modal>
    )
}
