import {useState, useCallback, useEffect} from "react";
import {Dropdown, Nav, Sidebar, Sidenav, Icon, Navbar} from "rsuite";
import 'rsuite/dist/styles/rsuite-default.css';
import {routes} from "../../routes/constants";
import {Link} from "react-router-dom";
import logoImg from "../../assets/logo_mascote.png"
import { logout } from "../../services/auth";
import {useLocation} from "react-router-dom"


const headerStyles = {
    display: 'flex',
    alignItens: 'center',
    justifyContent: 'center',
    padding: 18,
    fontSize: 16,
    height: 'auto',
    background: '#F6F6F6',
    color: ' #fff',
    overflow: 'hidden'
};

const Aside = () => {
    const url_atual = useLocation();
    const tipoUsuario = Number(localStorage.getItem('TCHAMA'));

    const [expand, setExpand] = useState(true);

    const [selectedPanel, setSelectedPanel] = useState(url_atual.pathname);

    const onChange = useCallback(() => {
        setExpand((expand) => !expand);
    }, []);
    
    const checkActive = (eventKey: string) => selectedPanel === eventKey;

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth > 850) {
                setExpand(true);
            } else {
                setExpand(false);
            }
        }

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleClickSair = () => {
        logout(); 
    }

    return (
        <Sidebar
            style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', background: '#FFF', minHeight: '100vh', borderRight: '1px solid rgba(0, 0, 0, 0.24)', boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px'}}
            width={expand ? 260 : 56}
            collapsible
        >
            <Sidenav.Header>
                <Nav pullRight style={{ height: '68px', display: 'flex', alignItems: 'center' }}>
                    <Nav.Item
                        onClick={onChange}
                        style={{ width: '100%', height: '100%', textAlign: 'left', display: 'flex', alignItems: 'center' }}
                        className={'ul-pullRight'}
                    >
                        <Icon icon={expand ? 'angle-left' : 'angle-right'}  />
                    </Nav.Item>
                </Nav>
                <div style={headerStyles}>
                    <img src={logoImg} height={expand ? '100' : '20'} alt="Chama Delivery" />
                </div>
            </Sidenav.Header>
            <Sidenav
                defaultOpenKeys={['3']} 
                expanded={expand} 
                appearance="subtle"
                style={{ height: '100%'}}
                onSelect={(eventKey) => setSelectedPanel(eventKey)}
            >
                <Sidenav.Body style={{ flexGrow: 1 }}>
                    <Nav>
                        {routes.map((route, n) => {

                            if (route.subRoutes) {
                                return <Dropdown
                                    key={n}
                                    trigger="hover"
                                    title={route.title}
                                    icon={<Icon icon={route.icon}/>}
                                    placement="rightStart"
                                    eventKey={route.path}
                                    active={checkActive(route.path)}
                                >
                                    {route.subRoutes.map((subRoute, i) => {
                                        if (subRoute.show !== false){
                                            return <Dropdown.Item  eventKey={route.path} active={checkActive(route.path)} componentClass={Link} to={subRoute.path} key={i*n}>{subRoute.title}</Dropdown.Item>
                                        }
                                        return null; 
                                    })}
                                </Dropdown>
                            } else {
                                if (route.show !== false){
                                    if(tipoUsuario && route.permissao.includes(tipoUsuario)){
                                        return <Nav.Item componentClass={Link} active={checkActive(route.path)} eventKey={route.path} to={route.path} key={n} children={route.title} icon={<Icon icon={route.icon}/>}/>
                                    }
                                    return false;
                                }
                            }
                            return null; 
                        })}
                    </Nav>
                    
                </Sidenav.Body>
            </Sidenav>
            
            <Navbar appearance="subtle" className="nav-toggle" style={{ position: 'fixed', bottom: '10px', height: '50px'}}>
                <Navbar.Body>
                    <Nav style={{ width: '100%' }}>
                        <Nav.Item onClick={handleClickSair} eventKey="sair" style={{ width: '100%', textAlign: 'left' }} icon={<Icon icon="sign-out" />}>
                            {expand ? 'Sair' : ''}
                        </Nav.Item>
                    </Nav>
                </Navbar.Body>
            </Navbar>
        </Sidebar>
    );
}

export default Aside;
