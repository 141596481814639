import { PedidoTable } from "../../components/PedidoTable";
import * as S from "./styles";
import { PedidoProvider } from '../../hooks/usePedido'
import { CategoriaProvider } from '../../hooks/useCategoria'
import { PerfilProvider } from '../../hooks/usePerfil'
import Admin from '../../components/Layouts/admin';


export function Pedido() {
    

    return (
        <PedidoProvider>
            <PerfilProvider>
            <CategoriaProvider>
                <Admin pageTitle={'Pedido'} > 
                    <S.Container>
                        <PedidoTable />
                    </S.Container>
                </Admin>    
            </CategoriaProvider>
            </PerfilProvider>
        </PedidoProvider>
    );
}
