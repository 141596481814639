import {createContext, useState, ReactNode, useContext, useCallback, useEffect } from 'react'
import api from "../services/api";
import { Alert } from 'rsuite';
import { v4 as uuidv4 } from 'uuid';

interface UsuarioFilial {
    id: number;
    uuid: string;
    nome: string;
    password: string;
    avatar: any;
    cpf: any;
    email: string;
    telefone: any;
    celular: any;
    tipo: any;
    filial: any;
    loja: any;
    data_criacao: any;
    data_atualizacao: any;
    nascimento: string;
    status: any;
}

interface UsuarioNomeUUID {
    uuid:  any,
    nome: any, 
    filial: any,
    loja: any,
    cpf: any,
    avatar: any,
    telefone: any,
    celular: any;
    email: any,
    password: any,
    tipo: any,
    data_nascimento: any;
    filial_nome: any;
    status: any
}

interface UserPagination {
    count: any,
    current_page: any,
    next_page: any,
    prev_page: any,
    last_page: any,
    total: any
}

interface UsuarioFilialProviderProps {
    children: ReactNode; 
}

interface UsuarioFilialContextData {
    usuarioLista: UsuarioFilial[];
    createUsuarioFilial: (filial: FilialUsuarioInput, modal: any) => Promise<void>; 
    getUsuarioPorUuid: (uuid: any, page:any) => Promise<void>;
    editUsuario: (filial: FilialUsuarioInputEdit, modal: any, uuid: any, page: any) => Promise<void>;
    userPage:(page:any) => Promise<void>;
    searchUsuario:(search:any, Modal: any) => Promise<void>;
    searchUsuarioRapido:(search:any) => Promise<void>;
    activePage: string;
    usuarioPorUUID: UsuarioNomeUUID[]; 
    isLoading: boolean; 
    isLoadingSearch: boolean;
    isLoadingEdit: boolean;
    limpaUser: any;
    paginationUsuario: UserPagination[]; 
    usuarioDelete: (uuid:any, modal: any) => Promise<void>;
}

type FilialUsuarioInput = Omit<UsuarioFilial, 'id' | 'uuid' | 'data_criacao' | 'data_atualizacao' | 'filial_nome'  > 
type FilialUsuarioInputEdit = Omit<UsuarioFilial, 'id' | 'uuid' | 'data_criacao' | 'data_atualizacao'  | 'password' | 'filial_nome'  > 

const UsuarioFilialContext = createContext<UsuarioFilialContextData>(
    {} as UsuarioFilialContextData //criando um Contexto, começa com um objeto vazio como default
);


export function UsuarioFilialProvider({children} : UsuarioFilialProviderProps) {
    const [usuarioLista, setUsuarioLista] = useState<UsuarioFilial[]>([]);
    const [usuariofilial, setUsuarioFilial] = useState<UsuarioFilial[]>([]);
    const [usuarioPorUUID, setFilialPorUUID] = useState<UsuarioNomeUUID[]>([]);
    const [paginationUsuario, setPagination] = useState<UserPagination[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [activePage, setPageActive] = useState('');
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    var LCHAMA = String(localStorage.getItem('LCHAMA'));
    var TCHAMA = Number(localStorage.getItem('TCHAMA'));
    var FCHAMA = String(localStorage.getItem('FCHAMA'));
    

    const handleUsuarioFilial = useCallback(async () => {
        
        const buscaAtiva = localStorage.getItem('salvarBuscaUsuario');

        if(buscaAtiva && buscaAtiva !== ''){
            let search = JSON.parse(buscaAtiva);
            
            let tipo = TCHAMA;
            let loja = LCHAMA;
            let filial = FCHAMA;
            if(tipo !== undefined && loja !== undefined && filial !== undefined ){
                if(tipo === 1){
                    const response = await api.get('/filial/usuario?nome='+search.nome+'&cpf='+search.cpf.replace(/\D/g, '')+'&status='+search.status+'&tipo='+search.tipo); 
                    setIsLoading(true);
                    
                    const datapage = [{
                            'count' : response.data.count,
                            'current_page' : response.data.current_page,
                            'next_page' : response.data.next_page,
                            'prev_page' : response.data.prev_page,
                            'last_page' : response.data.last_page,
                            'total' : response.data.total
                        }
                    ];
                    setUsuarioLista(response.data.items);
                    setPagination(datapage);
                    setIsLoading(false); 
                } else if(tipo === 2){
                    const response = await api.get('/filial/usuario?nome='+search.nome+'&cpf='+search.cpf.replace(/\D/g, '')+'&status='+search.status+'filial='+filial+'&tipo=4'); 
                    setIsLoading(true);
                    
                    const datapage = [{
                            'count' : response.data.count,
                            'current_page' : response.data.current_page,
                            'next_page' : response.data.next_page,
                            'prev_page' : response.data.prev_page,
                            'last_page' : response.data.last_page,
                            'total' : response.data.total
                        }
                    ];
                    setUsuarioLista(response.data.items);
                    setPagination(datapage);
                    setIsLoading(false); 
                } else if(tipo === 3){
                    const response = await api.get('/filial/usuario?nome='+search.nome+'&cpf='+search.cpf.replace(/\D/g, '')+'&status='+search.status+'loja='+loja+'&tipo=3'); 
                    setIsLoading(true);
                    
                    const datapage = [{
                            'count' : response.data.count,
                            'current_page' : response.data.current_page,
                            'next_page' : response.data.next_page,
                            'prev_page' : response.data.prev_page,
                            'last_page' : response.data.last_page,
                            'total' : response.data.total
                        }
                    ];
                    setUsuarioLista(response.data.items);
                    setPagination(datapage);
                    setIsLoading(false); 
                }
            }
        }else{
            setIsLoading(true);
            
            let tipo = TCHAMA;
            let loja = LCHAMA;
            let filial = FCHAMA;
            if(tipo !== undefined && loja !== undefined && filial !== undefined ){
                if(tipo === 1){
                    const response = await api.get('/filial/usuario');
                    
                    setUsuarioLista(response.data.items);
                    const datapage = [{
                            'count' : response.data.count,
                            'current_page' : response.data.current_page,
                            'next_page' : response.data.next_page,
                            'prev_page' : response.data.prev_page,
                            'last_page' : response.data.last_page,
                            'total' : response.data.total
                        }
                    ];
                    setPagination(datapage);
                    setIsLoading(false);
                } else if(tipo === 2){
                    const response = await api.get('/filial/usuario?filial='+filial+'&tipo=4');
                    
                    setUsuarioLista(response.data.items);
                    const datapage = [{
                            'count' : response.data.count,
                            'current_page' : response.data.current_page,
                            'next_page' : response.data.next_page,
                            'prev_page' : response.data.prev_page,
                            'last_page' : response.data.last_page,
                            'total' : response.data.total
                        }
                    ];
                    setPagination(datapage);
                    setIsLoading(false);
                } else if(tipo === 3){
                    const response = await api.get('/filial/usuario?loja='+loja+'&tipo=5');
                    
                    setUsuarioLista(response.data.items);
                    const datapage = [{
                            'count' : response.data.count,
                            'current_page' : response.data.current_page,
                            'next_page' : response.data.next_page,
                            'prev_page' : response.data.prev_page,
                            'last_page' : response.data.last_page,
                            'total' : response.data.total
                        }
                    ];
                    setPagination(datapage);
                    setIsLoading(false);
                }
            }
        }
       
      }, [FCHAMA, LCHAMA, TCHAMA]);

    useEffect(() => {
        handleUsuarioFilial();
      }, [handleUsuarioFilial]);

         

    // FILIAL POR UUID

    async function getUsuarioPorUuid(uuid : any, page:any) {
        try {
            setIsLoadingEdit(true);
                
            const responseedit = await api.get('/filial/usuario/'+uuid);  

            setFilialPorUUID([responseedit.data]);
            setIsLoadingEdit(false);
            setPageActive(page);

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoadingEdit(false);
        }
    }

    
    // EDITANDO OS USUÁRIOS
    async function editUsuario(FilialUsuarioInputEdit: FilialUsuarioInputEdit, Modal: any, uuid: any, page:any) {
        try {
            setIsLoadingEdit(true);
         
            const response = await api.put('/filial/usuario/'+uuid, FilialUsuarioInputEdit); 
           
            const buscaAtiva = localStorage.getItem('salvarBuscaUsuario');
            if(buscaAtiva && buscaAtiva !== ''){

                let search = JSON.parse(buscaAtiva);
       
                let tipo = TCHAMA;
                let loja = LCHAMA;
                let filial = FCHAMA;
                if(tipo !== undefined && loja !== undefined && filial !== undefined ){
                    if(tipo === 1){
                        const responsepage = await api.get('/filial/usuario?nome='+search.nome+'&cpf='+search.cpf.replace(/\D/g, '')+'&status='+search.status+'&page='+page+'&tipo='+search.tipo);
                        setIsLoadingEdit(true);
                        setUsuarioLista(responsepage.data.items);
                        const datapage = [{
                                'count' : responsepage.data.count,
                                'current_page' : responsepage.data.current_page,
                                'next_page' : responsepage.data.next_page,
                                'prev_page' : responsepage.data.prev_page,
                                'last_page' : responsepage.data.last_page,
                                'total' : responsepage.data.total
                            }
                        ];
                        setPagination(datapage);
            
                        handleUsuarioFilial();
                        Alert.success(response.data.message);
                        Modal();
                        setIsLoadingEdit(false);
                    } else if(tipo === 2){
                        const responsepage = await api.get('/filial/usuario?nome='+search.nome+'&cpf='+search.cpf.replace(/\D/g, '')+'&status='+search.status+'&page='+page+'&filial='+filial+'&tipo=4'); 
                        setIsLoadingEdit(true);
                        setUsuarioLista(responsepage.data.items);
                        const datapage = [{
                                'count' : responsepage.data.count,
                                'current_page' : responsepage.data.current_page,
                                'next_page' : responsepage.data.next_page,
                                'prev_page' : responsepage.data.prev_page,
                                'last_page' : responsepage.data.last_page,
                                'total' : responsepage.data.total
                            }
                        ];
                        setPagination(datapage);
            
                        handleUsuarioFilial();
                        Alert.success(response.data.message);
                        Modal();
                        setIsLoadingEdit(false);
                    } else if(tipo === 3){
                        const responsepage = await api.get('/filial/usuario?nome='+search.nome+'&cpf='+search.cpf.replace(/\D/g, '')+'&status='+search.status+'&page='+page+'&loja='+loja+'&tipo=4'); 
                       
                        setIsLoadingEdit(true);
                        setUsuarioLista(responsepage.data.items);
                        const datapage = [{
                                'count' : responsepage.data.count,
                                'current_page' : responsepage.data.current_page,
                                'next_page' : responsepage.data.next_page,
                                'prev_page' : responsepage.data.prev_page,
                                'last_page' : responsepage.data.last_page,
                                'total' : responsepage.data.total
                            }
                        ];
                        setPagination(datapage);
            
                        handleUsuarioFilial();
                        Alert.success(response.data.message);
                        Modal();
                        setIsLoadingEdit(false);
                    }
                }
    
            }else{
                let keysToRemove = ["buscaAtivaUsuario", "salvarBuscaUsuario"];
    
                keysToRemove.forEach(k =>
                    localStorage.removeItem(k));
                    
                
                let tipo = TCHAMA;
                let loja = LCHAMA;
                let filial = FCHAMA;
                if(tipo !== undefined && loja !== undefined && filial !== undefined ){
                    if(tipo === 1){
                        setIsLoadingEdit(true);
                        const responsepage = await api.get('/filial/usuario?tipo=2&page='+page); 
                        
                        setUsuarioLista(responsepage.data.items);
                        const datapage = [{
                                'count' : responsepage.data.count,
                                'current_page' : responsepage.data.current_page,
                                'next_page' : responsepage.data.next_page,
                                'prev_page' : responsepage.data.prev_page,
                                'last_page' : responsepage.data.last_page,
                                'total' : responsepage.data.total
                            }
                        ];
                        setPagination(datapage);
                
                        Alert.success(response.data.message);
                        Modal();
                        setIsLoadingEdit(false);
                    } else if(tipo === 2){
                        setIsLoadingEdit(true);
                        const responsepage = await api.get('/filial/usuario?filial='+filial+'&page='+page+'&tipo=4'); 
                        
                        setUsuarioLista(responsepage.data.items);
                        const datapage = [{
                                'count' : responsepage.data.count,
                                'current_page' : responsepage.data.current_page,
                                'next_page' : responsepage.data.next_page,
                                'prev_page' : responsepage.data.prev_page,
                                'last_page' : responsepage.data.last_page,
                                'total' : responsepage.data.total
                            }
                        ];
                        setPagination(datapage);
                
                        Alert.success(response.data.message);
                        Modal();
                        setIsLoadingEdit(false);
                    } else if(tipo === 3){
                        setIsLoadingEdit(true);
                        const responsepage = await api.get('/filial/usuario?loja='+loja+'&page='+page+'&tipo=5'); 
                        
                        setUsuarioLista(responsepage.data.items);
                        const datapage = [{
                                'count' : responsepage.data.count,
                                'current_page' : responsepage.data.current_page,
                                'next_page' : responsepage.data.next_page,
                                'prev_page' : responsepage.data.prev_page,
                                'last_page' : responsepage.data.last_page,
                                'total' : responsepage.data.total
                            }
                        ];
                        setPagination(datapage);
                
                        Alert.success(response.data.message);
                        Modal();
                        setIsLoadingEdit(false);
                    }
                }  
            }
           

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); 
        }
    } 

    //   CRIA FILIAL
    
    async function createUsuarioFilial(FilialUsuarioInput: FilialUsuarioInput, Modal: any) {
        try {
            setIsLoading(true);
                
            const data = new FormData();
            let uuid = uuidv4();
            data.append("uuid", uuid);
            data.append("avatar", FilialUsuarioInput.avatar);
            data.append("nome", FilialUsuarioInput.nome);
            data.append("email", FilialUsuarioInput.email);
            data.append("password", FilialUsuarioInput.password);
            data.append("cpf", FilialUsuarioInput.cpf);
            data.append("telefone", FilialUsuarioInput.telefone);
            data.append("nascimento", FilialUsuarioInput.nascimento);
            data.append("celular", FilialUsuarioInput.celular);
            if(FilialUsuarioInput.tipo === 2 || FilialUsuarioInput.tipo === 4  ){
                data.append("filial", FilialUsuarioInput.filial);
            }else if(FilialUsuarioInput.tipo === 3 || FilialUsuarioInput.tipo === 5 ){
                data.append("filial", FilialUsuarioInput.filial);
                data.append("loja", FilialUsuarioInput.loja);
            }
            data.append("tipo", FilialUsuarioInput.tipo);
            data.append("status", FilialUsuarioInput.status);

            await api.post('/filial/usuario', data);  
            
            let keysToRemove = ["buscaAtivaUsuario", "salvarBuscaUsuario"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));

            handleUsuarioFilial();
            setUsuarioFilial(usuariofilial);
            Modal();
            setIsLoading(false);

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); 
        }
    }

    
    // Paginação
    async function userPage(page:any) {
        try {
                
            const buscaAtiva = localStorage.getItem('salvarBuscaUsuario');
            if(buscaAtiva && buscaAtiva !== ''){
                setIsLoading(true);

                let search = JSON.parse(buscaAtiva);
                
                let tipo = TCHAMA;
                let loja = LCHAMA;
                let filial = FCHAMA;
                let response: any;
                if(tipo !== undefined && loja !== undefined && filial !== undefined ){
                    if(tipo === 1){
                        response = await api.get('/filial/usuario?nome='+search.nome+'&cpf='+search.cpf.replace(/\D/g, '')+'&tipo='+search.tipo+'&status='+search.status+'&page='+page+'&tipo='+search.tipo+'&filial='+search.filial);

                    } else if(tipo === 2){
                        response = await api.get('/filial/usuario?nome='+search.nome+'&cpf='+search.cpf.replace(/\D/g, '')+'&tipo='+search.tipo+'&status='+search.status+'&page='+page+'&filial='+filial+'&tipo=4');
                    } else if(tipo === 3){

                        response = await api.get('/filial/usuario?nome='+search.nome+'&cpf='+search.cpf.replace(/\D/g, '')+'&tipo='+search.tipo+'&status='+search.status+'&page='+page+'&loja='+loja+'&tipo=5');
                    }     
                }     

                setUsuarioLista(response.data.items);
                const data = [{
                    'count' : response.data.count,
                        'current_page' : response.data.current_page,
                        'next_page' : response.data.next_page,
                        'prev_page' : response.data.prev_page,
                        'last_page' : response.data.last_page,
                        'total' : response.data.total
                    }
                ];
                setIsLoading(false);
                setPagination(data)
            }else{

                 setIsLoading(true);
            
                let tipo = TCHAMA;
                let loja = LCHAMA;
                let filial = FCHAMA;
                 let response: any;
                 if(tipo !== undefined && loja !== undefined && filial !== undefined ){
                     if(tipo === 1){
                         response = await api.get('/filial/usuario?page='+page);
 
                     } else if(tipo === 2){
                         response = await api.get('/filial/usuario?page='+page+'&filial='+filial+'&tipo=4');
                     } else if(tipo === 3){
 
                         response = await api.get('/filial/usuario?page='+page+'&loja='+loja+'&tipo=5');
                     }     
                 }    
   
                setUsuarioLista(response.data.items);
                const data = [{
                        'count' : response.data.count,
                        'current_page' : response.data.current_page,
                        'next_page' : response.data.next_page,
                        'prev_page' : response.data.prev_page,
                        'last_page' : response.data.last_page,
                        'total' : response.data.total
                    }
                ];
                setIsLoading(false);
                setPagination(data)
            }

        } catch (err: any) {
            setIsLoading(false); 
        }
    }

    async function searchUsuario(search:any, Modal:any) {
        try {
            setIsLoadingSearch(true);
            
            if(search.nome === '' && search.cpf === '' && search.status === '' && search.tipo === '' && search.filial === '' && search.loja === ''){
                handleUsuarioFilial();
            } else {
            
                let tipo = TCHAMA;
                let loja = LCHAMA;
                let filial = FCHAMA;
                let response: any;

                if(tipo !== undefined && loja !== undefined && filial !== undefined ){
                    if(tipo === 1){
                        response = await api.get('/filial/usuario?nome='+search.nome+'&cpf='+search.cpf.replace(/\D/g, '')+'&status='+search.status+'&tipo='+search.tipo+'&filial='+search.filial+'&loja='+search.loja);  
                    } else if(tipo === 2){
                        response = await api.get('/filial/usuario?nome='+search.nome+'&cpf='+search.cpf.replace(/\D/g, '')+'&status='+search.status+'&filial='+filial+'&tipo=4'); 
                    } else if(tipo === 3){
                        response = await api.get('/filial/usuario?nome='+search.nome+'&cpf='+search.cpf.replace(/\D/g, '')+'&status='+search.status+'&loja='+loja+'&tipo=5'); 
                    }     
                }   

                if(response.data.error === true ){
                    Alert.error(response.data.message);

                }else {
                    localStorage.setItem('buscaAtivaUsuario', 'ativa');
                    localStorage.setItem('salvarBuscaUsuario', JSON.stringify(search));
                    
                    setUsuarioLista(response.data.items);
                    const data = [{
                        'count' : response.data.count,
                        'current_page' : response.data.current_page,
                        'next_page' : response.data.next_page,
                        'prev_page' : response.data.prev_page,
                        'last_page' : response.data.last_page,
                        'total' : response.data.total
                        }
                    ];
                    setPagination(data);
                }
            }
            
            setIsLoadingSearch(false);
            Modal();
            

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoadingSearch(false); //pegando o response da requisição da api
        }
    }
    
    async function searchUsuarioRapido(search:any) {
        try {
            setIsLoadingSearch(true);
            
            if(search === ''){
                handleUsuarioFilial();
            } else {
            
                let tipo = TCHAMA;
                let loja = LCHAMA;
                let filial = FCHAMA;
                let response: any;

                if(tipo !== undefined && loja !== undefined && filial !== undefined ){
                    if(tipo === 1){
                        response = await api.get('/filial/usuario?tipo='+search);  
                    }    
                }   

                if(response.data.error === true ){
                    Alert.error(response.data.message);

                }else {
                            
                    let keysToRemove = ["buscaAtivaUsuario", "salvarBuscaUsuario"];

                    keysToRemove.forEach(k =>
                        localStorage.removeItem(k));
                
                    setUsuarioLista(response.data.items);
                    const data = [{
                        'count' : response.data.count,
                        'current_page' : response.data.current_page,
                        'next_page' : response.data.next_page,
                        'prev_page' : response.data.prev_page,
                        'last_page' : response.data.last_page,
                        'total' : response.data.total
                        }
                    ];
                    setPagination(data);
                }
            }
            
            setIsLoadingSearch(false);
            

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoadingSearch(false); //pegando o response da requisição da api
        }
    }

    async function limpaUser() {
        try {
            setIsLoadingSearch(true);
            
            handleUsuarioFilial();
            let keysToRemove = ["buscaAtivaUsuario", "salvarBuscaUsuario"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));
                window.location.reload();

            setIsLoadingSearch(false);

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoadingSearch(false);
        }
    }
 
    async function usuarioDelete(uuid : any, Modal: any) {
        try {
            setIsLoading(false);
        
            if(uuid === undefined){
                handleUsuarioFilial();
            } else {
                 const response = await api.delete('/filial/usuario/'+uuid);  
                    
                 if(response.data.error === true ){
                    Alert.error(response.data.message);

                 }else {
                    setUsuarioLista(response.data.items);
                    const data = [{
                        'count' : response.data.count,
                        'current_page' : response.data.current_page,
                        'next_page' : response.data.next_page,
                        'prev_page' : response.data.prev_page,
                        'last_page' : response.data.last_page,
                        'total' : response.data.total
                        }
                    ];
                    setPagination(data);
                 }
            }
            
            handleUsuarioFilial();
            Modal();
            

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }
    
 

    return (
        <UsuarioFilialContext.Provider value={{createUsuarioFilial, isLoading, usuarioLista, getUsuarioPorUuid, usuarioPorUUID, editUsuario, limpaUser, isLoadingSearch, paginationUsuario, userPage, searchUsuario, activePage, searchUsuarioRapido, isLoadingEdit,usuarioDelete }}>  
            {children} 
        </UsuarioFilialContext.Provider>
    )

}


export function useUsuarioFilial() {
    const context = useContext(UsuarioFilialContext);

    return context;
}
