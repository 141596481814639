/* eslint-disable no-cond-assign */
import * as S from "./styles"
import {
  useParams
} from "react-router-dom";
import { FormEvent, useState, useEffect, useCallback } from 'react';
import { useProduto } from "../../hooks/useProduto"
import formatReal from "../../utils/formatReal"
import {FormGroup, InputGroup, Icon, Modal, Button, Alert } from 'rsuite';
import { v4 as uuidv4 } from 'uuid';
import {Loading} from '../Loading';




export function CardItem() {  


    return (
        <S.CardAdicionalItem className="containerAdicionalItem" data-novo="sim" data-uuiditem={uuidv4()}>
            <div className="excluirElementoItem">x</div>
            <S.Row>
                <FormGroup  className="item">
                    <label>Nome: </label>
                    <input 
                        type="text" 
                        placeholder="Nome"
                        name="nomeItem"
                        
                    /> 
                </FormGroup>
                <FormGroup className="item">
                    <label>Valor: </label>
                    <input 
                        type="text" 
                        placeholder="Valor"
                        name="valorItem"
                        
                    /> 
                </FormGroup>
            </S.Row>
            <S.Row >
                <div className="quantidade">
                    <FormGroup>
                        <label>Quantidade</label>
                        <input 
                            type="text" 
                            name="quantidadeItem" 
                            placeholder="Quantidade" />
                    </FormGroup>
                    <FormGroup className="obrigatorio">
                        <input type="checkbox" name="status_adicional_item" onChange={()=>{}} />
                        <label>Pausar?</label>
                    </FormGroup>
                </div>
            </S.Row>
        </S.CardAdicionalItem>
    );
}

export function VerProduto() {
    const { id } = useParams<{ id: any }>();

    const { verDetalheProdutoURL, detalheProdutoURL, isLoading, editProduto, activePage, itemDelete, itemDeleteAdicional } = useProduto();

    const [imagem, setImagem] = useState(detalheProdutoURL[0]?.imagem || '');
    const [imagem_upload, setImagemUpload] = useState(detalheProdutoURL[0]?.imagem || '');
    const [nomeProduto, setNome] = useState(detalheProdutoURL[0]?.nome || '');
    const [descricao, setDescricao] = useState(detalheProdutoURL[0]?.descricao || '');
    const [valor, setValor] = useState(detalheProdutoURL[0]?.valor.br || '');
    const [status, setStatus] = useState(detalheProdutoURL[0]?.status || '');
    const [adicionais, setAdicional] = useState(detalheProdutoURL[0]?.adicionais  || '');
    const [elemento, setElemento] = useState(() => [<p key={0}></p>]);
    const [elementoDeleteItem, setElementoDeleteItem] = useState(() => [<p></p>]);

    const [itemUuuidDelete, setDeletar] = useState('');
    const [adicionalUuuidDelete, setDeletarAdicional] = useState('');

    useEffect(() => {
        
        if(verDetalheProdutoURL.length > 0){
            setImagem(detalheProdutoURL[0]?.imagem || '');
            setImagemUpload(detalheProdutoURL[0]?.imagem || '');
            setNome(detalheProdutoURL[0]?.nome || '');
            setDescricao(detalheProdutoURL[0]?.descricao || '');
            setValor(detalheProdutoURL[0]?.valor.br || '');
            setStatus(String(detalheProdutoURL[0]?.status) || '');
            setAdicional(detalheProdutoURL[0]?.adicionais || '');
            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [verDetalheProdutoURL]);

    const handleProdutoEdit = useCallback(async () => {
        await verDetalheProdutoURL(id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);

    useEffect(() => {
        handleProdutoEdit();
    }, [handleProdutoEdit]);



    async function handleEditProduto(event: FormEvent){
        event.preventDefault();
        let imagem_atual = detalheProdutoURL[0].imagem;
        let verificaImagem = imagem_atual !== imagem_upload ? imagem_upload : imagem_atual;

        let valor_atual = detalheProdutoURL[0].valor;
        let verificaValor = valor_atual !== valor ? valor : valor_atual;
    
        var inputs, index, indexItem, len, inputsItem;

        inputs =  document.querySelectorAll(".containerAdicional");
        len = inputs.length;
        var arrayAdicional : any[] = [];
   
        var AdicionalItem : any[] = [];
        for (index = 0; index < len; ++index) {
            if(inputs){

               inputsItem =  inputs[index].querySelectorAll(".containerAdicionalItem");
                AdicionalItem = [];
                if(inputsItem){
                    for (indexItem = 0; indexItem < inputsItem.length; ++indexItem) {
                        AdicionalItem[indexItem] = {
                            uuid: inputsItem[indexItem].getAttribute('data-uuiditem'),
                            adicional: (inputsItem[indexItem]?.parentNode?.parentNode as HTMLInputElement)?.getAttribute('data-uuid') || "",
                            nome: (inputsItem[indexItem].querySelector('input[name="nomeItem"]') as HTMLInputElement).value || "",
                            valor: (inputsItem[indexItem].querySelector('input[name="valorItem"]') as HTMLInputElement).value || "",
                            quantidade: (inputsItem[indexItem].querySelector('input[name="quantidadeItem"]') as HTMLInputElement).value || "",
                            tipo: Number(1),
                            status: (inputsItem[indexItem].querySelector('input[name="status_adicional_item"]:checked') as HTMLInputElement) === null ? Number(1) : Number(2),
                        }
                    }
                }

                 arrayAdicional[index] = {
                    uuid: inputs[index].getAttribute('data-uuid'),
                    titulo: (inputs[index].querySelector('input[name="tituloAdicional"]') as HTMLInputElement).value || "",
                    quantidade: (inputs[index].querySelector('input[name="quantidade"]') as HTMLInputElement).value || "",
                    obrigatorio: (inputs[index].querySelector('input[name="obrigatorio"]:checked') as HTMLInputElement) === null ? Number(2) : Number(1),
                    status: (inputs[index].querySelector('input[name="statusAdicional"]:checked') as HTMLInputElement) === null ? Number(1) : Number(2),
                    tipo: Number(1),
                    itens: AdicionalItem || ""
                }
            }

        };

        const data = {
            imagem: verificaImagem,
            nome:nomeProduto,
            descricao,
            valor: verificaValor,
            loja:detalheProdutoURL[0]?.loja,
            status,    
            adicionais: arrayAdicional
        };

        
        let result = await editProduto(data, id, activePage);
      
         if(result.error === false) {
            window.location.reload();
         }
    }
    
    const onImageChange = (event: any) => {
        const { files } = event.target;
        setImagem(URL.createObjectURL(files[0]));
        setImagemUpload(files[0]);
        
    };

    const handlClearFoto = async () => {
        setImagem('');
        setImagemUpload('');
    };
    

  

    const Cards = () => {
        return (
            <div>
                <S.CardAdicional className="containerAdicional" data-novo="sim" data-uuid={uuidv4()}>
                <span className="excluirElemento" onClick={(e) => handleExcluir((e.target as Element)?.parentNode)}>x</span>
                    <FormGroup>
                        <label>Título: </label>
                        <input 
                            type="text" 
                            placeholder="Título"
                            name="tituloAdicional"
                        /> 
                    </FormGroup>
                    <div className="quantidade">
                        <FormGroup>
                            <label>Quantidade</label>
                            <input type="text" 
                            placeholder="Quantidade"
                            name="quantidade" />
                        </FormGroup>
                        <FormGroup className="obrigatorio">
                            <input type="checkbox" name="obrigatorio" onClick={(e) => clickObrigatorio(e)}  />
                            <label>Obrigatório?</label>
                        </FormGroup>
                        <FormGroup className="obrigatorio">
                            <input type="checkbox" name="statusAdicional" />
                            <label>Pausar?</label>
                        </FormGroup>
                    </div>
                    <div className="container_item" onClick={(e) => clickAdditem(e)} onKeyUp={(e) => onkeyUp(e)}>
                        <CardItem />
                    </div>
                    
                    <div className="adicionar_item"  onClick={(e) => addJItemsx((e.target as Element)?.parentNode?.parentNode)}>
                        <p>+ Adicionar item</p>
                    </div>
                </S.CardAdicional>
            </div>
        )
    } 
    
    const addJsx = () => {
        let item = <Cards key={uuidv4()} />;
        let newItems = [...elemento, item];
        setElemento(newItems);
    };   
    var n;
    function cleanUpInputs(obj:any) {
        for (var i = 0; n = obj.childNodes[i]; ++i) {

            if (n.childNodes && n.tagName !== 'INPUT') {
                cleanUpInputs(n);
            } else if (n.tagName === 'INPUT' && n.type === 'text') {
                n.value = '';
            } else if (n.tagName === 'INPUT' && n.type === 'number') {
                n.value = '';
            }
        }
    }
    const addJItemsx = (e:any) => {
        let adicional = e.querySelector('.container_item').querySelector('.containerAdicionalItem');

        let clone = adicional.cloneNode(true);

        clone.dataset.uuiditem = uuidv4();
        clone.dataset.novo = 'sim';
        cleanUpInputs(clone);
        e.querySelector('.container_item').appendChild(clone);
    }; 

    const handleExcluir = (e:any) => {
        let adicional = e.getAttribute('data-uuid');
        let novo = e.getAttribute('data-novo');
        if(novo === 'sim'){
            e.remove();
        }else if(novo === 'nao'){
            setElementoDeleteItem(e);
            handleModalshowDeleteAdicional(adicional);
        }
    };     

    function clickObrigatorio(e:any) {
       if(e.target.checked === true){
           e.target.parentNode.parentNode.querySelector('input[name="quantidade"]').disabled = true;
           e.target.parentNode.parentNode.querySelector('input[name="quantidade"]').value = '';
       }else if(e.target.checked === false){
           e.target.parentNode.parentNode.querySelector('input[name="quantidade"]').disabled = false;
     

       }
    }

    function clickAdditem(e:any) {
        if (e.target.className === 'excluirElementoItem') {
            let item = e.target.parentNode.getAttribute('data-uuiditem');
            let novo = e.target.parentNode.getAttribute('data-novo');
            if(novo === 'sim'){
                e.target.parentNode.remove();
            }else if(novo === 'nao'){
                setElementoDeleteItem(e.target);
                handleModalshowDelete(item);
            }
        }
    }
    function onkeyUp(e:any) {
        if (e.target.name === 'valorItem') {
            e.target.value = formatReal(e.target.value);
        }
    }
    //remover item
    const [modalDelete, setModalDelete] = useState(false);

    function handleModalDeleteClose (){
        setModalDelete(false);
    }
    
    const handleModalshowDelete = async (uuid: any) => {
        setModalDelete(true);
        setDeletar(uuid);
    }

    const handleModalDeletar = async (uuid: any, modal: any) => {
        let result = await itemDelete(uuid, modal);
        if(result.error === false) {
            if(elementoDeleteItem){
                handleExcluirDepoisDelete(elementoDeleteItem);
                Alert.success(result.message);
            }else{

                Alert.error(result.message);

            }
        }
   
    }

    const handleExcluirDepoisDelete = (e:any) => {
            e.parentNode.remove();
            setElementoDeleteItem(() => [<p></p>]);
    };     

    const handleExcluirDepoisDeleteAdicional = (e:any) => {
            e.remove();
            setElementoDeleteItem(() => [<p></p>]);
    };     

    //remover adicional
    const [modalDeleteAdicional, setModalDeleteAdicional] = useState(false);

    function handleModalDeleteCloseAdicional (){
        setModalDeleteAdicional(false);
    }
    
    const handleModalshowDeleteAdicional = async (uuid: any) => {
        setModalDeleteAdicional(true);
        setDeletarAdicional(uuid);
    }

    const handleModalDeletarAdicional = async (uuid: any, modal: any) => {

        let result = await itemDeleteAdicional(uuid, modal);
         if(result.error === false) {
            if(elementoDeleteItem){
                handleExcluirDepoisDeleteAdicional(elementoDeleteItem);
                Alert.success(result.message);
            }else{

                Alert.error(result.message);

            }
        } 
       
    }

    return (
        <S.Container onSubmit={handleEditProduto} id="form">
            <div>
                {isLoading ? <Loading /> : ''}
            </div>
            <S.ContainerCardForm>
                <div className="card-form">
                    <S.Row className="produto">
                        <S.FotoContainer>
                            {imagem ? (
                            <>
                                <InputGroup className="close_search" label="Excluir foto">
                                    <InputGroup.Button onClick={()=> handlClearFoto()}>
                                        <Icon icon="close" />
                                    </InputGroup.Button>
                                </InputGroup>
                                <img src={imagem} alt="imagem" width="300" height="300" onChange={onImageChange} />
                            </>
                            ) : (
                            <div className="crop">
                                <Icon icon="file-image-o" size="4x" />
                                <input type="file" id="upload-button" onChange={onImageChange} />
                            </div>
                            )}
                        </S.FotoContainer>
                        <S.Column>
                            <S.Row>
                                <FormGroup>
                                    <label>Nome: </label>
                                    <input 
                                        type="text" 
                                        placeholder="Nome"
                                        value={nomeProduto}
                                        onChange={event => setNome(event.target.value)}
                                        
                                    /> 
                                </FormGroup>
                                <FormGroup>
                                    <label>Valor:</label>
                                    <input 
                                        type="text" 
                                        placeholder="Valor" 
                                        value={valor}
                                        onChange={event => setValor(formatReal(event.target.value))}
                                    />
                                </FormGroup>
                            </S.Row>
                            <FormGroup>
                                <label>Descrição: </label>
                                <textarea 
                                    placeholder="Descrição" 
                                    value={descricao}
                                    className="descricao"
                                    onChange={event => setDescricao(event.target.value)}
                                    
                                ></textarea>
                            </FormGroup>
                            <FormGroup>
                                <label>Status:</label>
                                <S.StatusFilialContainer>
                                    <S.RadioBox 
                                        type="button"
                                        onClick={() => setStatus(String(1))}
                                        isActive={status === '1'}
                                        activeColor="green"
                                        activeColorFonte="white"
                                    >   
                                        <span>Ativo</span>
                                    </S.RadioBox>
                                    <S.RadioBox 
                                        type="button"
                                        onClick={() => setStatus(String(2))}
                                        isActive={status === '2'}
                                        activeColor="red"
                                        activeColorFonte="white"
                                    >
                                        <span>Inativo</span>
                                    </S.RadioBox>
                                </S.StatusFilialContainer>
                            </FormGroup>
                        </S.Column>
                    </S.Row>
                </div>
                <div className="card-form adicional">
                    <h2>Adicionais</h2>
                    <div id="adicionalContainer">
                        {adicionais && adicionais.map((item:any) => (
                          <S.CardAdicional className="containerAdicional" data-novo="nao" key={item.uuid} data-uuid={item.uuid}>
                            <span className="excluirElemento" onClick={(e) => handleExcluir((e.target as Element)?.parentNode)}>x</span>
                                <FormGroup>
                                    <label>Título: </label>
                                    <input 
                                        type="text" 
                                        placeholder="Título"
                                        name="tituloAdicional"
                                        defaultValue={item.titulo || ''}
                                    /> 
                                </FormGroup>
                                <div className="quantidade">
                                    <FormGroup>
                                        <label>Quantidade</label>
                                        <input type="text" 
                                        placeholder="Quantidade"
                                        name="quantidade" 
                                        defaultValue={item.quantidade || ''}
                                        />
                                    </FormGroup>
                                    <FormGroup className="obrigatorio">
                                        <input type="checkbox" defaultChecked={item.obrigatorio === 1 ? true : false} name="obrigatorio" onClick={(e) => clickObrigatorio(e)} />
                                        <label>Obrigatório?</label>
                                    </FormGroup>
                                    <FormGroup className="obrigatorio">
                                        <input type="checkbox" defaultChecked={item.status === 1 ? false : true}  name="statusAdicional" onChange={()=>{}} />
                                        <label>Pausar?</label>
                                    </FormGroup>
                                </div>
                                <div className="container_item" onClick={(e) => clickAdditem(e)} onKeyUp={(e) => onkeyUp(e)}>
                                {item.itens && item.itens.map((item2:any) => (
                                <S.CardAdicionalItem className="containerAdicionalItem" data-novo="nao" data-adicional={item.uuid} key={item2.uuid}  data-uuiditem={item2.uuid}>
                                <div className="excluirElementoItem">x</div>
                                    <S.Row>
                                        <FormGroup  className="item">
                                            <label>Nome: </label>
                                            <input 
                                                type="text" 
                                                placeholder="Nome"
                                                name="nomeItem"
                                                defaultValue={item2.nome || ''}
                                                
                                            /> 
                                        </FormGroup>
                                        <FormGroup className="item">
                                            <label>Valor: </label>
                                            <input 
                                                type="text" 
                                                placeholder="Valor"
                                                name="valorItem"
                                                defaultValue={item2.valor.br || ''}
                                                onKeyUp={(e) => formatReal((e.target as HTMLInputElement).value)}
                                                
                                            /> 
                                        </FormGroup>
                                    </S.Row>
                                    <S.Row >
                                        <div className="quantidade">
                                            <FormGroup>
                                                <label>Quantidade</label>
                                                <input 
                                                    type="text" 
                                                    name="quantidadeItem" 
                                                    placeholder="Quantidade" 
                                                    defaultValue={item2.quantidade|| ''} />
                                            </FormGroup>
                                            <FormGroup className="obrigatorio">
                                                <input type="checkbox" defaultChecked={item2.status === 1 ? false : true} name="status_adicional_item"  />
                                                <label>Pausar?</label>
                                            </FormGroup>
                                        </div>
                                    </S.Row>
                                </S.CardAdicionalItem>
                                ))}
                                <div className="maisAdicional"></div>
                                </div>
                                
                                <div className="adicionar_item" onClick={(e) => addJItemsx((e.target as Element)?.parentNode?.parentNode)}>
                                    <p>+ Adicionar item</p>
                                </div>
                            </S.CardAdicional>

                        ))}
                        {elemento}
                        <div className="adicionar_categoria" onClick={() => addJsx()}>
                            <p>+ Adicionar</p>
                        </div>
                    </div>

                </div>
            </S.ContainerCardForm>
            
            <button type="submit">{isLoading ? 'Carregando...' : 'Atualizar'}</button>

            <Modal backdrop="static" show={modalDelete} onHide={handleModalDeleteClose} size="xs">
                <Modal.Body>
                    <Icon
                    icon="remind"
                    style={{
                        color: '#990606',
                        fontSize: 24
                    }}
                    />
                    {'  '}
                    Tem certeza que deseja deletar esse item? Esta ação não poderá ser desfeita.
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleModalDeletar(itemUuuidDelete, handleModalDeleteClose)} color="red">
                    Quero deletar
                    </Button>
                    <Button onClick={handleModalDeleteClose} appearance="subtle">
                    Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop="static" show={modalDeleteAdicional} onHide={handleModalDeleteCloseAdicional} size="xs">
                <Modal.Body>
                    <Icon
                    icon="remind"
                    style={{
                        color: '#990606',
                        fontSize: 24
                    }}
                    />
                    {'  '}
                    Tem certeza que deseja deletar esse adicional? Esta ação não poderá ser desfeita.
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleModalDeletarAdicional(adicionalUuuidDelete, handleModalDeleteCloseAdicional)} color="red">
                    Quero deletar
                    </Button>
                    <Button onClick={handleModalDeleteCloseAdicional} appearance="subtle">
                    Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </S.Container>
    )
}
