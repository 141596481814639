import { UsuarioFilialTable } from "../../components/UsuarioFilialTable";
import {useState} from 'react'
import * as S from "./styles";
import { NewUsuarioFilialModal } from '../../components/NewUsuarioFilialModal';
import { EditUsuarioFilialModal } from '../../components/EditUsuarioFilialModal';
import { UsuarioFilialProvider } from "../../hooks/useUsuarioFilial"
import { FilialProvider } from '../../hooks/useFilial'
import { PerfilProvider } from '../../hooks/usePerfil'
import { LojaProvider } from '../../hooks/useLoja'
import Admin from '../../components/Layouts/admin';


export function UsuarioFilial() {
    const [isNewUsuarioFilialModalOpen, setIsNewUsuarioFilialModalOpen] = useState(false);   
    const [isEditUsuarioFilialModalOpen, setIsEditUsuarioFilialModalOpen] = useState(false);   

    function handleOpenNewUsuarioFilialModal(){
        setIsNewUsuarioFilialModalOpen(true);
    }

    function handleCloseNewUsuarioFilialModal(){
        setIsNewUsuarioFilialModalOpen(false);
    }

    function handleOpenEditUsuarioFilialModal(){
        setIsEditUsuarioFilialModalOpen(true);
    }

    function handleCloseEditFilialModal(){
        setIsEditUsuarioFilialModalOpen(false);
    }
    const tipoUsuario = Number(localStorage.getItem('TCHAMA'));
    const tituloPagina = tipoUsuario === 1 ? 'Funcionários' : 'Usuários';
    
    return (
        <UsuarioFilialProvider>
            <FilialProvider>
                <LojaProvider>
                    <Admin pageTitle={tituloPagina} > 
                        <S.Container>
                            <UsuarioFilialTable onOpenNewUsuarioFilialModal={handleOpenNewUsuarioFilialModal} onOpenEditUsuarioFilialModal={handleOpenEditUsuarioFilialModal}   />
                        
                        
                            { isNewUsuarioFilialModalOpen || isEditUsuarioFilialModalOpen  ?
                                <PerfilProvider>
                                    { isNewUsuarioFilialModalOpen ?
                                        <NewUsuarioFilialModal 
                                            isOpen={isNewUsuarioFilialModalOpen}
                                            onRequestClose={handleCloseNewUsuarioFilialModal}
                                        />
                                        : null }
                                    { isEditUsuarioFilialModalOpen ?
                                        <EditUsuarioFilialModal 
                                            isOpen={isEditUsuarioFilialModalOpen}
                                            onRequestClose={handleCloseEditFilialModal}
                                        />  : null }
                                </PerfilProvider>
                            : null }
                            
                        </S.Container>
                    </Admin>    
                 </LojaProvider>
            </FilialProvider>
        </UsuarioFilialProvider>
    );
}
