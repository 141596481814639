import Admin from "../../components/Layouts/admin";
import {useState} from 'react'
import * as S from "./styles";
import { CategoriaTable } from "../../components/CategoriaTable";
import { CategoriaProvider } from '../../hooks/useCategoria'
import { NewCategoriaModal } from '../../components/NewCategoriaModal';
import { EditCategoriaModal } from '../../components/EditCategoriaModal';


export function CategoriaLoja() {
    const [isNewCategoriaModalOpen, setIsNewCategoriaModalOpen] = useState(false);  
    const [isEditCategoriaModalOpen, setIsEditCategoriaModalOpen] = useState(false);  

   function handleOpenNewCategoriaModal(){
        setIsNewCategoriaModalOpen(true);
    }

    function handleCloseNewCategoriaModal(){
        setIsNewCategoriaModalOpen(false);
    }

    function handleOpenEditCategoriaModal(){
        setIsEditCategoriaModalOpen(true);
    }

    function handleCloseEditCategoriaModal(){
        setIsEditCategoriaModalOpen(false);
    }

    return (
        <CategoriaProvider>
            <Admin pageTitle={"Categoria"}>
                <S.Container>
                    <CategoriaTable onOpenNewCategoriaModal={handleOpenNewCategoriaModal}  onOpenEditCategoriaModal={handleOpenEditCategoriaModal} />

                            {isNewCategoriaModalOpen ? 
                                
                                <NewCategoriaModal 
                                    isOpen={isNewCategoriaModalOpen}
                                    onRequestClose={handleCloseNewCategoriaModal}
                                />
                            : null
                            }
                            {isEditCategoriaModalOpen ? 
                                
                                <EditCategoriaModal 
                                    isOpen={isEditCategoriaModalOpen}
                                    onRequestClose={handleCloseEditCategoriaModal}
                                />
                            : null
                            }
                </S.Container>
            </Admin>
        </CategoriaProvider>
    );
}
