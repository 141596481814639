import { FormEvent, useState } from 'react'
import Modal from 'react-modal'
import { useUsuarioFilial } from "../../hooks/useUsuarioFilial"
import InputMask from "react-input-mask";

import { MdClose } from "react-icons/md"
import * as S from "./styles"

import {FormGroup, InputGroup, Icon,Alert} from 'rsuite';


interface NewUsuarioFilialModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
}

export function NewUsuarioFilialModal({isOpen, onRequestClose}: NewUsuarioFilialModalProps) {
    const { createUsuarioFilial, isLoading } = useUsuarioFilial();
    const tipoUsuario = Number(localStorage.getItem('TCHAMA'));
    const filialUsuario = String(localStorage.getItem('FCHAMA'));
    const LCHAMA = String(localStorage.getItem('LCHAMA'));

    const [avatar, setAvatar] = useState('')
    const [avatar_upload, setAvatarUpload] = useState('')
    const [nome, setNome] = useState('');
    const [cpf, setCPF] = useState('');
    const [nascimento, setNascimento] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [celular, setCelular] = useState('');
    const [password, setSenha] = useState('');
    const [password_confirm, setConfirmeSenha] = useState('');
    const [status, setStatus] = useState('');

     async function handleCreateNewUsuarioFilial(event: FormEvent){
        event.preventDefault();

        let data: any;

        if(password !== password_confirm){
            Alert.error('As senhas não estão iguais!');
            return false;
        }

        if(tipoUsuario === 2){
       
            data = {
                 nome,
                 password,
                 avatar:avatar_upload,
                 cpf,
                 email,
                 telefone,
                 celular,
                 nascimento,
                 filial: filialUsuario,
                 loja: '',
                 tipo: 4,
                 status
             }
 
      
             await createUsuarioFilial(data, onRequestClose);
         }else if(tipoUsuario === 3){

            data = {
                 nome,
                 password,
                 avatar:avatar_upload,
                 cpf,
                 email,
                 telefone,
                 celular,
                 nascimento,
                 filial: filialUsuario,
                 loja: LCHAMA,
                 tipo: 5,
                 status
             }

             await createUsuarioFilial(data, onRequestClose);
         }
        

    }

  
    const onImageChange = (event: any ) => {
        const { files } = event.target;
        setAvatar(URL.createObjectURL(files[0]));
        setAvatarUpload(files[0]);
        
    };

    const handlClearFoto = async () => {
        setAvatar('');
        setAvatarUpload('');
    };

    return (
        
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <button type="button" onClick={onRequestClose} className="react-modal-close">
                <MdClose />
            </button>
            <S.Container onSubmit={handleCreateNewUsuarioFilial}>
                <h2>Cadastrar Usuário</h2>
                <S.ContainerCardForm>
                    <div className="card-form">
                        <FormGroup>
                            <S.FotoContainer>
                            {avatar ? (
                            <>
                            <InputGroup className="close_search" label="Excluir avatar">
                                <InputGroup.Button onClick={() => handlClearFoto()}>
                                    <Icon icon="close" />
                                </InputGroup.Button>
                            </InputGroup>
                            <img src={avatar} alt="imagem"  width="300" height="300" 
                            onChange={onImageChange} />
                            </>
                            ) : (
                            <>
                            <Icon icon="file-image-o" size="4x" />
                            <input
                                type="file"
                                id="upload-button"
                                onChange={onImageChange}
                            />
                            <label>Clique aqui para inserir uma imagem</label>
                            </>
                            )}
                            </S.FotoContainer>
                        </FormGroup>
                        <FormGroup>
                            <label>Nome</label>
                            <input
                                type="text"
                                placeholder="Nome"
                                value={nome} 
                                onChange={event => setNome(event.target.value)}    
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Data de nascimento: </label>
                            <InputMask 
                                type="text" 
                                mask="99/99/9999"
                                placeholder="Data de nascimento"  
                                value={nascimento} 
                                onChange={event => setNascimento(event.target.value)} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>CPF: </label>
                            <InputMask 
                                type="text" 
                                mask="999.999.999-99"
                                placeholder="CPF" 
                                onChange={event => setCPF(event.target.value)}    
                                value={cpf} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Telefone: </label>
                            <InputMask 
                                type="text" 
                                mask="(99) 9999-9999"
                                placeholder="Telefone" 
                                onChange={event => setTelefone(event.target.value)}    
                                value={telefone} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Celular: </label>
                            <InputMask 
                                type="text" 
                                mask="(99) 99999-9999"
                                placeholder="Celular" 
                                onChange={event => setCelular(event.target.value)}    
                                value={celular} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>E-mail: </label>
                            <input
                                type="text"
                                placeholder="E-mail"
                                value={email} 
                                onChange={event => setEmail(event.target.value)}   
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Senha: </label>
                            <input
                                type="password" 
                                placeholder="Senha"  
                                value={password} 
                                onChange={event => setSenha(event.target.value)} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Confirme a senha: </label>
                            <input
                                type="password" 
                                placeholder="Senha"  
                                value={password_confirm} 
                                onChange={event => setConfirmeSenha(event.target.value)} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Status:</label>
                            <S.StatusFilialContainer>
                                <S.RadioBox 
                                    type="button"
                                    onClick={() => setStatus(String(1))}
                                    isActive={status === '1'}
                                    activeColor="green"
                                    activeColorFonte="white"
                                >   
                                    <span>Ativo</span>
                                </S.RadioBox>
                                <S.RadioBox 
                                    type="button"
                                    onClick={() => setStatus(String(2))}
                                    isActive={status === '2'}
                                    activeColor="red"
                                    activeColorFonte="white"
                                >
                                    <span>Inativo</span>
                                </S.RadioBox>
                            </S.StatusFilialContainer>
                        </FormGroup>
                    </div>
                </S.ContainerCardForm>
                
                <button type="submit">{isLoading ? 'Carregando...' : 'Cadastrar'}</button>
            </S.Container>
        </Modal>
    )
}
