import { VerProdutoTable } from "../../components/VerProdutoTable";
import * as S from "./styles";
import { ProdutoProvider } from '../../hooks/useProduto'
import { CategoriaProvider } from '../../hooks/useCategoria'
import Admin from '../../components/Layouts/admin';


export function Produto() {
    
    return (
        <ProdutoProvider>
            <CategoriaProvider>
                <Admin pageTitle={'Produtos'} > 
                    <S.Container>
                        <VerProdutoTable  />
                    </S.Container>
                </Admin>    
            </CategoriaProvider>
        </ProdutoProvider>
    );
}
