import {createContext, useState, useEffect, ReactNode, useContext, useCallback } from 'react'
import api from "../services/api";
import { Alert } from 'rsuite';
import { v4 as uuidv4 } from 'uuid';

interface Loja {
    id: number;
    uuid: string;
    url: string;
    logo: any;
    nome: any;
    razao_social: string;
    responsavel: string;
    cnpj: any;
    email: string;
    telefone: any;
    celular: any;
    cep: any;
    logradouro: string;
    numero: string;
    complemento: string;
    bairro: string;
    cidade: string;
    estado: string;
    categoria: any;
    latitude: any;
    longitude: any;
    raio: any;
    password: any;
    data_criacao: any;
    data_atualizacao: any;
    status: any;
}


interface Produto {
    id: number;
    uuid: string;
    loja: any;
    imagem: any;
    nome: string;
    descricao: string;
    valor: any;
    data_atualizacao: any;
    data_criacao: any;
    url: any;
    status: any;
    adicionais: any;
    obrigatorio: any;
}

interface ProdutoEditType {
    imagem: any;
    nome: any;
    descricao: any;
    valor: any;
    loja: any;
    status: any;
    adicionais: any;
}


interface ProdutoPagination {
    pagina_atual: any,
    proxima_pagina: any,
    pagina_anterior: any,
    ultima_pagina: any,
    total_itens: any
}

interface ProdutoProviderProps {
    children: ReactNode; //pode retornar conteudo html, por exemplo
}

interface ProdutoContextData {
    produto: Loja[]; 
    produtoPage: (produto:any) => Promise<void>;
    produtoVerPage: (uuid: any, produto:any) => Promise<void>;
    produtoSearch: (search:any, modal: any) => Promise<void>;
    getprodutoPorUuid: (uuid: any) => Promise<void>; 
    verprodutoPorUuid: (uuid: any) => Promise<void>; 
    verDetalheProdutoURL: (uuid: any) => Promise<void>; 
    editProduto: (produto: ProdutoEditType, uuid:any, page:any) => Promise<any>;
    createProduto: (produto: ProdutoEditType) => Promise<void>;
    detalheProduto: (url: any, page: any) => Promise<void>;    
    itemDelete: (uuid:any, modal: any) => Promise<any>;
    itemDeleteAdicional: (uuid:any, modal: any) => Promise<any>;
    activePage: string;
    isLoading: boolean; 
    isLoadingEdit: boolean; 
    produtoPagination: ProdutoPagination[]; 
    verprodutoPagination: ProdutoPagination[]; 
    produtoPorUUID: ProdutoInputEdit[]; 
    detalheProdutoPorURL: ProdutoInputEdit[]; 
    detalheProdutoURL: ProdutoInputEdit[]; 
    isLoadingSearch: boolean;
    isLoadingAdicional: boolean;
    limpaSearchProduto: any;
}

// type ProdutoInput = Omit<Produto, 'id' | 'uuid' | 'data_criacao' | 'data_atualizacao'  > 

type ProdutoInputEdit = Omit<Produto, 'id'  | 'data_atualizacao' | 'pagina_atual' | 'password' >

const ProdutoContext = createContext<ProdutoContextData>(
    {} as ProdutoContextData //criando um Contexto, começa com um objeto vazio como default
);


export function ProdutoProvider({children} : ProdutoProviderProps) {
    const [produto, setProduto] = useState<Loja[]>([]);
    const [produtoPorUUID, setProdutoPorUUID] = useState<Produto[]>([]);
    const [detalheProdutoPorURL, setDetalheProduto] = useState<Produto[]>([]);
    const [detalheProdutoURL, setDetalheProdutoURL] = useState<Produto[]>([]);
    const [produtoPagination, setProdutoPagination] = useState<ProdutoPagination[]>([]);
    const [verprodutoPagination, setVerProdutoPagination] = useState<ProdutoPagination[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingEdit, setIsLoadingEdit] = useState(true);
    const [activePage, setPageActive] = useState('');
    const [isLoadingSearch, setIsLoadingSearch] = useState(true);
    const [isLoadingAdicional, setIsLoadingAdicional] = useState(true);

    const handleProduto = useCallback(async () => {
        const buscaAtiva = localStorage.getItem('salvarBuscaProduto');

        if(buscaAtiva && buscaAtiva !== ''){
            setIsLoading(true);
            let search = JSON.parse(buscaAtiva);
            let loja = localStorage.getItem('LCHAMA');
         
            if(loja !== undefined){
                try {
                    const response = await api.get('/produto?loja='+loja+'&status='+search.status); 
        
                    setProdutoPorUUID(response.data.itens);
                    const data = [{
                            'pagina_atual' : response.data.pagina_atual,
                            'proxima_pagina' : response.data.proxima_pagina,
                            'pagina_anterior' : response.data.pagina_anterior,
                            'ultima_pagina' : response.data.ultima_pagina,
                            'total_itens' : response.data.total_itens
                        }
                    ];
            
                    setProdutoPagination(data);
                    setIsLoading(false);
        
                } catch (err: any) {
                    setIsLoading(false);
                }
            };
        }else{
            setIsLoading(true);
            let loja = localStorage.getItem('LCHAMA');

            if(loja !== undefined){
                try {
                    const response = await api.get('/produto?loja='+loja);
        
                    setProdutoPorUUID(response.data.itens);
                    const data = [{
                            'pagina_atual' : response.data.pagina_atual,
                            'proxima_pagina' : response.data.proxima_pagina,
                            'pagina_anterior' : response.data.pagina_anterior,
                            'ultima_pagina' : response.data.ultima_pagina,
                            'total_itens' : response.data.total_itens
                        }
                    ];
            
                    setProdutoPagination(data);
                    setIsLoading(false);
        
                } catch (err: any) {
                    setIsLoading(false);
                }
            };
            setIsLoading(false);
        }
  

      }, []);

    useEffect(() => {
        handleProduto();
      }, [handleProduto]);

    // PEGA uuid e razao social

    async function produtoPage(page : any) {
        try {

            const buscaAtiva = localStorage.getItem('salvarBuscaProduto');
            if(buscaAtiva && buscaAtiva !== ''){
                setIsLoading(true);

                let search = JSON.parse(buscaAtiva);
                let loja = localStorage.getItem('LCHAMA');
                const response = await api.get('/produto?loja='+loja+'&status='+search.status+'?page='+page); 

                setProduto(response.data.itens);
                const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                setIsLoading(false);
                setProdutoPagination(data);
            }else{

                 setIsLoading(true);
            
                let loja = localStorage.getItem('LCHAMA');
                 const response = await api.get('/produto?loja='+loja+'&page='+page);             

                setProduto(response.data.itens);
                const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                setIsLoading(false);
                setProdutoPagination(data);
            }

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }

    async function produtoSearch(search:any, Modal: any) {
        try {
            setIsLoading(true);
            
            if(search.nome === '' && search.status === ''){
                handleProduto();
            } else {
                let loja = localStorage.getItem('LCHAMA');
                const response = await api.get('/produto?loja='+loja+'&nome='+search.nome+'&status='+search.status); 

                if(response.data.error === true ){
                    Alert.error(response.data.message);

                }else {
                    localStorage.setItem('buscaAtivaProduto', 'ativa');
                    localStorage.setItem('salvarBuscaProduto', JSON.stringify(search));
                    
                    setProdutoPorUUID(response.data.itens);
                    const data = [{
                        'pagina_atual' : response.data.pagina_atual,
                        'proxima_pagina' : response.data.proxima_pagina,
                        'pagina_anterior' : response.data.pagina_anterior,
                        'ultima_pagina' : response.data.ultima_pagina,
                        'total_itens' : response.data.total_itens
                    }];

                    setProdutoPagination(data);
                }
            }
            
            setIsLoading(false);
            Modal();
            

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }
      

    // FILIAL POR UUID

    async function getprodutoPorUuid(uuid : any) {
        try {
            setIsLoading(false);
                
            let loja = localStorage.getItem('LCHAMA');
            const responseedit = await api.get('/produto?loja='+loja);  

            setProdutoPorUUID(responseedit.data.itens);
            const data = [{
                    'pagina_atual' : responseedit.data.pagina_atual,
                    'proxima_pagina' : responseedit.data.proxima_pagina,
                    'pagina_anterior' : responseedit.data.pagina_anterior,
                    'ultima_pagina' : responseedit.data.ultima_pagina,
                    'total_itens' : responseedit.data.total_itens
                }
            ];
            setVerProdutoPagination(data);
            setIsLoadingEdit(false);

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }



    async function verprodutoPorUuid(uuid: any) {
        try {
            setIsLoadingEdit(true);
                
            const responseedit = await api.get('/produto?loja='+uuid+'&page=1');  
            // produtoPorUUID.push(responseedit.data);
            setProdutoPorUUID(responseedit.data.itens);
            const data = [{
                    'pagina_atual' : responseedit.data.pagina_atual,
                    'proxima_pagina' : responseedit.data.proxima_pagina,
                    'pagina_anterior' : responseedit.data.pagina_anterior,
                    'ultima_pagina' : responseedit.data.ultima_pagina,
                    'total_itens' : responseedit.data.total_itens
                }
            ];
            setVerProdutoPagination(data);
            setIsLoadingEdit(false);

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoadingEdit(false); 
        }
    }

    async function detalheProduto(url: any, page: any) {
        try {
            setIsLoadingEdit(true);
            setIsLoadingAdicional(true);
                
            const responseedit = await api.get('/produto/'+url);  
            // produtoPorUUID.push(responseedit.data);
            setDetalheProduto([responseedit.data]);
         
            setPageActive(page);

            setTimeout(() => {
                window.location.pathname = '/produto/'+url;
            }, 1000);
          
            
            setIsLoadingAdicional(false);
            setIsLoadingEdit(false);


        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoadingEdit(false); 
        }
    }
    
    async function verDetalheProdutoURL(url: any) {
        try {
            setIsLoadingEdit(true);
                
            const responseedit = await api.get('/produto/'+url);  

            setDetalheProdutoURL([responseedit.data]);
          
            setIsLoadingEdit(false);


        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoadingEdit(false); 
        }
    }
    
    async function produtoVerPage(uuid: any, page : any) {
        try {
            setIsLoadingEdit(false);
                
            const response = await api.get('/produto?loja='+uuid+'&page='+page);  // ProdutoInput são todos os inputs do form

            setProduto(response.data.itens);
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                }
            ];
            setVerProdutoPagination(data);

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoadingEdit(false); //pegando o response da requisição da api
        }
    }
    
    // EDITANDO AS Produto
    async function createProduto(ProdutoInputEdit: ProdutoEditType) {
        try {
            setIsLoading(true);

            const data = new FormData();
            let uuid = uuidv4();
            data.append("uuid", uuid);
            data.append("imagem", ProdutoInputEdit.imagem);
            data.append("nome", ProdutoInputEdit.nome);
            data.append("descricao", ProdutoInputEdit.descricao);
            data.append("valor", ProdutoInputEdit.valor);
            data.append("loja", ProdutoInputEdit.loja);
            data.append("tipo", "1");
            data.append("status", ProdutoInputEdit.status as any);

            const response = await api.post('/produto', data);  // ProdutoInput são todos os inputs do form
         
            if(response.data.message  === "Produto criado com sucesso!"){

                const responseItem = await api.post('/adicionais/'+uuid, ProdutoInputEdit.adicionais);
                let keysToRemove = ["buscaAtivaProduto", "salvarBuscaProduto"];

                keysToRemove.forEach(k =>
                    localStorage.removeItem(k));

                setTimeout(() => {
                    window.location.pathname = '/produto';
                }, 1000);
                handleProduto();
                Alert.success(responseItem.data.message);
                setIsLoading(false);
            }

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    } 


    // EDITANDO AS Produto
    async function editProduto(ProdutoInputEdit: ProdutoEditType, uuid: any, page:any) {
        try {
            setIsLoading(true);

            const data = new FormData();
            data.append("imagem", ProdutoInputEdit.imagem);
            data.append("nome", ProdutoInputEdit.nome);
            data.append("descricao", ProdutoInputEdit.descricao);
            data.append("valor", ProdutoInputEdit.valor);
            data.append("loja", ProdutoInputEdit.loja);
            data.append("tipo", "1");
            data.append("status", ProdutoInputEdit.status as any);

            const response = await api.post('/produto/atualizar/'+uuid, data);  // ProdutoInput são todos os inputs do form
            const buscaAtiva = localStorage.getItem('salvarBuscaProduto');


            if(response.data.error  === false){
                const responseItem = await api.post('/adicionais/'+uuid, ProdutoInputEdit.adicionais);

                Alert.success(responseItem.data.message);
                
                return response.data;

            }

            if(buscaAtiva && buscaAtiva !== ''){
                setIsLoading(true);
                let search = JSON.parse(buscaAtiva);
                let loja = localStorage.getItem('LCHAMA');
             
                if(loja !== undefined || loja !== null){
                    try {
                        const responsepage = await api.get('/produto?loja='+loja+'&status='+search.status); 
            
                        setProdutoPorUUID(responsepage.data.itens);
                        const data = [{
                                'pagina_atual' : response.data.pagina_atual,
                                'proxima_pagina' : response.data.proxima_pagina,
                                'pagina_anterior' : response.data.pagina_anterior,
                                'ultima_pagina' : response.data.ultima_pagina,
                                'total_itens' : response.data.total_itens
                            }
                        ];
                
                        setProdutoPagination(data);
                        setIsLoading(false);
            
                    } catch (err: any) {
                        setIsLoading(false);
                    }
                };
            }else{
                let keysToRemove = ["buscaAtivaProduto", "salvarBuscaProduto"];
    
                keysToRemove.forEach(k =>
                    localStorage.removeItem(k));
                setIsLoading(true);
                let loja = localStorage.getItem('LCHAMA');

                if(loja !== undefined){
                    try {
                        const response = await api.get('/produto?loja='+loja);
            
                        setProdutoPorUUID(response.data.itens);
                        const data = [{
                                'pagina_atual' : response.data.pagina_atual,
                                'proxima_pagina' : response.data.proxima_pagina,
                                'pagina_anterior' : response.data.pagina_anterior,
                                'ultima_pagina' : response.data.ultima_pagina,
                                'total_itens' : response.data.total_itens
                            }
                        ];
                
                        setProdutoPagination(data);
                        setIsLoading(false);
            
                    } catch (err: any) {
                        setIsLoading(false);
                    }
                };
                setIsLoading(false);
            }

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    } 

    async function limpaSearchProduto() {
        try {
            setIsLoadingSearch(true);
            
            handleProduto();
            let keysToRemove = ["buscaAtivaProduto", "salvarBuscaProduto"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));
                window.location.reload();

            setIsLoadingSearch(false);

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoadingSearch(false);
        }
    }



    async function itemDelete(uuid : any, Modal: any) {
        try {
            setIsLoading(false);
        
            const response = await api.delete('/adicionais/itens/'+uuid);  
                
            if(response.data.error === true ){
                Alert.error(response.data.message);

            }
            Modal();
            return response.data;

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }

    async function itemDeleteAdicional(uuid : any, Modal: any) {
        try {
            setIsLoading(false);
        
            const response = await api.delete('/adicionais/'+uuid);  
                
            if(response.data.error === true ){
                Alert.error(response.data.message);

            }
            Modal();

            return response.data;

          
        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }

    return (
        <ProdutoContext.Provider value={{produto,  isLoading, getprodutoPorUuid, produtoPorUUID, editProduto, produtoPagination, produtoPage,  produtoSearch,  verprodutoPorUuid, isLoadingEdit, verprodutoPagination, produtoVerPage, detalheProdutoPorURL, detalheProduto, createProduto, activePage, limpaSearchProduto, isLoadingSearch, detalheProdutoURL, verDetalheProdutoURL, isLoadingAdicional, itemDelete, itemDeleteAdicional}}> {/* retornando as filiais como valor do contexto e a função que cria uma nova loja */}
            {children} {/* recebe um conteudo do componente filho que são o Header, Dashboard e NewProdutoModal */} 
        </ProdutoContext.Provider>
    )

}


export function useProduto() {
    const context = useContext(ProdutoContext);

    return context;
}
