import { FormEvent, useState   } from 'react'
import Modal from 'react-modal'
import { useBanner } from "../../hooks/useBanner"

import { MdClose } from "react-icons/md"
import * as S from "./styles"

import {FormGroup} from 'rsuite';


interface SearchBannerModalProps {
    isOpenSearchBanner: boolean;
    onRequestCloseSearchBanner: () => void;
}



export function SearchBannerModal({isOpenSearchBanner, onRequestCloseSearchBanner}: SearchBannerModalProps) {
    const { isLoading, bannerSearch } = useBanner();
    
    const [status, setStatus] = useState('');


    
    async function handleClickOpenSearchBanner(event: FormEvent){
           event.preventDefault();
        const data = {
            status,
        };

        await bannerSearch(data, onRequestCloseSearchBanner);
    };

    
    const status_lista = {
        1: "Ativo",
        2: "Inativo"
    };

    function optionsStatus() {
        var es = Object.entries(status_lista);
        return es.map((item) => (
            <option key={`status${item[0]}`} value={item[0]}>
                {item[1]}
            </option>
        ));
    }


    return (
        
        <Modal
            isOpen={isOpenSearchBanner}
            onRequestClose={onRequestCloseSearchBanner}
            overlayClassName="react-modal-overlay"
            className="react-modal-content-search"
        >
            <button type="button" onClick={onRequestCloseSearchBanner} className="react-modal-close">
                <MdClose />
            </button>
            <S.Container onSubmit={handleClickOpenSearchBanner}>
                <h2>Pesquisar</h2>
                <S.ContainerCardForm>
                    <div className="card-form">
                        <FormGroup>
                        <label>Status:</label>
                            <select
                                placeholder="Status"
                                onChange={(e) => {
                                    setStatus(e.target.value);
                                }}
                                value={status}
                            >
                                <option key="" value="">
                                    Selecione o status
                                </option>
                                {optionsStatus()}
                            </select>
                        </FormGroup>
                     
                    </div>
                        
                </S.ContainerCardForm>
                
                <button type="submit">{isLoading ? 'Carregando...' : 'Buscar'}</button>
            </S.Container>
        </Modal>
    )
}
