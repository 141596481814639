import {FC, useState} from "react";
import 'rsuite/dist/styles/rsuite-default.css';
import {Container, Header, Content, InputGroup, Icon} from "rsuite";
import Aside from './aside';
import { SearchFilialModal } from '../../components/SearchFilialModal';
import { SearchCategoriaModal } from '../../components/SearchCategoriaModal';
import { SearchLojaModal } from '../../components/SearchLojaModal';
import { SearchProdutoModal } from '../../components/SearchProdutoModal';
import { SearchUsuarioModal } from '../../components/SearchUsuarioModal';
import { SearchBannerModal } from '../../components/SearchBannerModal';
import { useLoja } from "../../hooks/useLoja";
import { useCategoria } from "../../hooks/useCategoria";
import { useFilial } from "../../hooks/useFilial";
import { useUsuarioFilial } from "../../hooks/useUsuarioFilial";
import { useProduto } from "../../hooks/useProduto";
import { useBanner } from "../../hooks/useBanner";
import * as S from "./styles";


interface IProps {
    pageTitle: string
}

const headerStyle = {
    padding: 18,
    fontSize: 16,
    height: 68,
    background: '#F6F6F6',
    color: ' #333',
    overflow: 'hidden',
    borderLeft: '1px solid #eee',
    display: 'flex',
    alignItens: 'center',
    justifyContent: 'space-between',
};


const styles = {
    width: 40
};

const Admin: FC<IProps> = (props) => {
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);   
    const [isSearchCategoriaModalOpen, setIsSearchCategoriaModalOpen] = useState(false);   
    const [isSearchLojaModalOpen, setIsSearchLojaModalOpen] = useState(false);  
    const [isSearchProdutoModalOpen, setIsSearchProdutoModalOpen] = useState(false);  
    const [isSearchUsuarioModalOpen, setIsSearchUsuarioModalOpen] = useState(false);  
    const [isSearchBannerModalOpen, setIsSearchBannerModalOpen] = useState(false); 
    const { limpaSearchLoja } = useLoja();
    const { limpaSearchCategoria } = useCategoria();
    const { limpaSearchFilial } = useFilial();
    const { limpaSearchBanner } = useBanner();
    const { limpaUser } = useUsuarioFilial();
    const { limpaSearchProduto } = useProduto();

    async function handleSearchLoja(e: any) {
        e.preventDefault();
        await limpaSearchLoja();
    }

    function handleOpenSearchFilialModal(){
        setIsSearchModalOpen(true);
    }

    function handleCloseSearchFilialModal(){
        setIsSearchModalOpen(false);
    }

    function handleOpenSearchCategoriaModal(){
        setIsSearchCategoriaModalOpen(true);
    }
    function handleCloseSearchCategoriaModal(){
        setIsSearchCategoriaModalOpen(false);
    }

    function handleOpenSearchLojaModal(){
        setIsSearchLojaModalOpen(true);
    }
    function handleCloseSearchLojaModal(){
        setIsSearchLojaModalOpen(false);
    }

    function handleOpenSearchProdutoModal(){
        setIsSearchProdutoModalOpen(true);
    }
    function handleCloseSearchProdutoModal(){
        setIsSearchProdutoModalOpen(false);
    }

    function handleOpenSearchUsuarioModal(){
        setIsSearchUsuarioModalOpen(true);
    }
    function handleCloseSearchUsuarioModal(){
        setIsSearchUsuarioModalOpen(false);
    }

    function handleOpenSearchBannerModal(){
        setIsSearchBannerModalOpen(true);
    }
    function handleCloseSearchBannerModal(){
        setIsSearchBannerModalOpen(false);
    }
    // @ts-ignore
    var buscaAtivaLoja = null;
    if ( typeof localStorage.getItem('buscaAtivaLoja') === 'string' ) {
        buscaAtivaLoja = localStorage.getItem('buscaAtivaLoja');
    }else{
        buscaAtivaLoja = 'inativa';
    }
    var buscaAtivaCategoria = null;
    if ( typeof localStorage.getItem('buscaAtivaCategoria') === 'string' ) {
        buscaAtivaCategoria = localStorage.getItem('buscaAtivaCategoria');
    }else{
        buscaAtivaCategoria = 'inativa';
    }
    var buscaAtivaFilial = null;
    if ( typeof localStorage.getItem('buscaAtivaFilial') === 'string' ) {
        buscaAtivaFilial = localStorage.getItem('buscaAtivaFilial');
    }else{
        buscaAtivaFilial = 'inativa';
    }
    var buscaAtivaUsuario = null;
    if ( typeof localStorage.getItem('buscaAtivaUsuario') === 'string' ) {
        buscaAtivaUsuario = localStorage.getItem('buscaAtivaUsuario');
    }else{
        buscaAtivaUsuario = 'inativa';
    }
    var buscaAtivaProduto = null;
    if ( typeof localStorage.getItem('buscaAtivaProduto') === 'string' ) {
        buscaAtivaProduto = localStorage.getItem('buscaAtivaProduto');
    }else{
        buscaAtivaProduto = 'inativa';
    }
    var buscaAtivaBanner = null;
    if ( typeof localStorage.getItem('buscaAtivaBanner') === 'string' ) {
        buscaAtivaBanner = localStorage.getItem('buscaAtivaBanner');
    }else{
        buscaAtivaBanner = 'inativa';
    }

    return (
        <Container>

            <Aside />

            <Container>
                <Header style={headerStyle}>
                    {props.pageTitle === 'Visualizar Loja' || props.pageTitle === 'Cadastrar Loja' ? 
                        <S.VoltarPagina  >
                            <a href="/loja">
                                <Icon icon="long-arrow-left" className="voltar_page" />  
                            </a>
                            <h2>{props.pageTitle}</h2>
                        </S.VoltarPagina>
                    : null}
                 
                    {props.pageTitle === 'Visualizar Produto' || props.pageTitle === 'Cadastrar Produto' ? 
                        <S.VoltarPagina  >
                            <a href="/produto">
                                <Icon icon="long-arrow-left" className="voltar_page" />  
                            </a>
                            <h2>{props.pageTitle}</h2>
                        </S.VoltarPagina>
                    : null}
                    
                    {props.pageTitle === 'Produtos' 
                    || props.pageTitle === 'Loja'  
                    || props.pageTitle === 'Dashboard' 
                    || props.pageTitle === 'Categoria' 
                    || props.pageTitle === 'Atualize seu Perfil' 
                    || props.pageTitle === 'Filial' 
                    || props.pageTitle === 'Banner'  
                    || props.pageTitle === 'Pedido'  
                    || props.pageTitle === 'Caixa' 
                    || props.pageTitle === 'Funcionários' 
                    || props.pageTitle === 'Usuários'  
                    || props.pageTitle ===  'Funcionários - Loja'   ? 
                        <S.VoltarPagina  >
                            <h2>{props.pageTitle}</h2>
                        </S.VoltarPagina>
                    : null}


                    <S.End>

                    {buscaAtivaProduto === 'ativa' && props.pageTitle === 'Produtos' ? 
                    <InputGroup className="close_search">
                        <InputGroup.Button onClick={(e) => limpaSearchProduto(e)}>
                            <Icon icon="close" />
                        </InputGroup.Button>
                    </InputGroup> : null}
                    {props.pageTitle === 'Produtos' ?
                        <InputGroup style={styles}>
                            <InputGroup.Button onClick={() => handleOpenSearchProdutoModal()}>
                                <Icon icon="search" />
                            </InputGroup.Button>
                        </InputGroup>
                    : null}

                    {(buscaAtivaUsuario === 'ativa' && props.pageTitle === 'Usuários' ) ||  (buscaAtivaUsuario === 'ativa' &&  props.pageTitle === 'Funcionários')  ? 
                    <InputGroup className="close_search">
                        <InputGroup.Button onClick={(e) => limpaUser(e)}>
                            <Icon icon="close" />
                        </InputGroup.Button>
                    </InputGroup> : null}
                    {props.pageTitle === 'Usuários'  || props.pageTitle === 'Funcionários' ?
                        <InputGroup style={styles}>
                            <InputGroup.Button onClick={() => handleOpenSearchUsuarioModal()}>
                                <Icon icon="search" />
                            </InputGroup.Button>
                        </InputGroup>
                    : null
                    }
                    
                    {buscaAtivaFilial === 'ativa' && props.pageTitle === 'Filial' ? 
                    <InputGroup className="close_search">
                        <InputGroup.Button onClick={(e) => limpaSearchFilial(e)}>
                            <Icon icon="close" />
                        </InputGroup.Button>
                    </InputGroup> : null}
                    {props.pageTitle === 'Filial' ?
                        <InputGroup style={styles}>
                            <InputGroup.Button onClick={() => handleOpenSearchFilialModal()}>
                                <Icon icon="search" />
                            </InputGroup.Button>
                        </InputGroup>

                    : null
                    }
                    
                    {buscaAtivaCategoria === 'ativa' && props.pageTitle === 'Categoria' ? 
                    <InputGroup className="close_search">
                        <InputGroup.Button onClick={(e) => limpaSearchCategoria(e)}>
                            <Icon icon="close" />
                        </InputGroup.Button>
                    </InputGroup> : null}
                    {props.pageTitle === 'Categoria' ?
                        <InputGroup style={styles}>
                            <InputGroup.Button onClick={() => handleOpenSearchCategoriaModal()}>
                                <Icon icon="search" />
                            </InputGroup.Button>
                        </InputGroup>
                    : null}


                    {(buscaAtivaLoja === 'ativa' && props.pageTitle === 'Loja')  ? 
                    <InputGroup className="close_search">
                        <InputGroup.Button onClick={(e) => handleSearchLoja(e)}>
                            <Icon icon="close" />
                        </InputGroup.Button>
                    </InputGroup> : null}
                    {props.pageTitle === 'Loja' ?
                        <InputGroup style={styles}>
                            <InputGroup.Button onClick={() => handleOpenSearchLojaModal()}>
                                <Icon icon="search" />
                            </InputGroup.Button>
                        </InputGroup>

                    : null
                    }
                    
                    
                    {buscaAtivaBanner === 'ativa' && props.pageTitle === 'Banner' ? 
                    <InputGroup className="close_search">
                        <InputGroup.Button onClick={(e) => limpaSearchBanner(e)}>
                            <Icon icon="close" />
                        </InputGroup.Button>
                    </InputGroup> : null}
                    {props.pageTitle === 'Banner' ?
                        <InputGroup style={styles}>
                            <InputGroup.Button onClick={() => handleOpenSearchBannerModal()}>
                                <Icon icon="search" />
                            </InputGroup.Button>
                        </InputGroup>

                    : null
                    }
                    
                    </S.End>
                    
                </Header>
                <Content  className="container_admin">
                    {props.children}
                </Content>
            </Container>

                        
            <SearchFilialModal 
                isOpenSearch={isSearchModalOpen}
                onRequestCloseSearch={handleCloseSearchFilialModal}
            />   
            <SearchCategoriaModal 
                isOpenSearchCategoria={isSearchCategoriaModalOpen}
                onRequestCloseSearchCategoria={handleCloseSearchCategoriaModal}
            />
            <SearchLojaModal 
                isOpenSearchLoja={isSearchLojaModalOpen}
                onRequestCloseSearchLoja={handleCloseSearchLojaModal}
            />
            <SearchProdutoModal 
                isOpenSearchProduto={isSearchProdutoModalOpen}
                onRequestCloseSearchProduto={handleCloseSearchProdutoModal}
            />
            <SearchUsuarioModal 
                isOpenSearchUsuario={isSearchUsuarioModalOpen}
                onRequestCloseSearchUsuario={handleCloseSearchUsuarioModal}
            />
            <SearchBannerModal 
                isOpenSearchBanner={isSearchBannerModalOpen}
                onRequestCloseSearchBanner={handleCloseSearchBannerModal}
            />
        </Container>
    );
}


export default Admin;
