import { FormEvent, useState, useEffect } from 'react'
import { useLoja } from "../../hooks/useLoja"
import { useCategoria } from "../../hooks/useCategoria"
import { useFilial } from "../../hooks/useFilial"
import InputMask from "react-input-mask";
import {FormGroup, InputGroup, Icon,Alert } from 'rsuite';
import * as S from "./styles"


import api from "../../services/api";
import { MapContainer, Marker, TileLayer, useMapEvents, Circle } from 'react-leaflet'; 
import L from 'leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {Loading} from '../Loading';


export function NewLojaForm() {
    const { createLoja, isLoading } = useLoja();
    const { filial } = useFilial();
    const { categoria } = useCategoria();

    const [nome, setNome] = useState('');
    const [raio, setRaio] = useState('');
    const [longitude, setLongitude] = useState(0);
    const [latitude, setLatitude] = useState(0);
    const [logo, setLogo] =  useState('');
    const [logo_upload, setLogoUpload] =  useState('');
    const [razao_social, setRazaoSocial] = useState('');
    const [email, setEmail] = useState('');
    const [cnpj, setCNPJ] = useState('');
    const [telefone, setTelefone] = useState('');
    const [celular, setCelular] = useState('');
    const [cep, setCEP] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [complemento, setComplemento] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [status, setStatus] = useState('');
    const [categorias, setCategoria] = useState('');
    const [nomeUser, setNomeUser] = useState('');
    const [cpf, setCPF] = useState('');
    const [password, setSenha] = useState('');
    const [password_confirm, setConfirmeSenha] = useState('');
    const [nascimento, setNascimento] = useState('');
    const [login, setLogin] = useState('');
    const [filiais, setFilial] = useState('');
    
    const [selectedPosition, setSelectedPosition] = useState<[number, number]>([latitude,longitude]);

    let DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow
    });
    
    L.Marker.prototype.options.icon = DefaultIcon;

    const Markers = () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const map = useMapEvents({
            click(e) {          
                setLatitude(e.latlng.lat);
                setLongitude(e.latlng.lng); 

                setSelectedPosition([
                    e.latlng.lat,
                    e.latlng.lng
                ]);          
            },            
        })
        map.setView(selectedPosition, map.getZoom());
        let raioEmKm : number = Number(raio) * 1000;
        return (
            selectedPosition ? 
                <Marker           
                key={selectedPosition[0]}
                position={selectedPosition}
                interactive={false} 
                > 
                    <Circle 
                    center={selectedPosition}
                    fillColor="blue" 
                    radius={raioEmKm}/>
                </Marker>
            : null
        )   
        
    }


     useEffect(() => {
        navigator.geolocation.getCurrentPosition(async (position) => {
            let { latitude, longitude } = position.coords;
            setSelectedPosition([
                latitude,
                longitude
            ]);

        });
     }, []);
   
    
     async function handleCreateNewLoja(event: FormEvent){
        event.preventDefault();
        let filialFinal = Number(localStorage.getItem('TCHAMA')) === 1 ? filiais : localStorage.getItem('FCHAMA');
        if(logo_upload === ''){
            Alert.error('A imagem é obrigatória!');
            return false;
        }
        else if(filialFinal === ''){
            Alert.error('O campo Filial é obrigatório!');
            return false;
        }else if(nome === ''){
            Alert.error('O campo nome fantasia é obrigatório!');
            return false;
        }else if(razao_social === ''){
            Alert.error('O campo razão social é obrigatório!');
            return false;
        }else if(email === ''){
            Alert.error('O campo email é obrigatório!');
            return false;
        }else if(cnpj === ''){
            Alert.error('O campo cnpj é obrigatório!');
            return false;
        }else if(celular === ''){
            Alert.error('O campo celular é obrigatório!');
            return false;
        }else if(cep === ''){
            Alert.error('O campo cep é obrigatório!');
            return false;
        }else if(logradouro === ''){
            Alert.error('O campo logradouro é obrigatório!');
            return false;
        }else if(numero === ''){
            Alert.error('O campo número é obrigatório!');
            return false;
        }else if(bairro === ''){
            Alert.error('O campo bairro é obrigatório!');
            return false;
        }else if(cidade === ''){
            Alert.error('O campo cidade é obrigatório!');
            return false;
        }else if(estado === ''){
            Alert.error('O campo estado é obrigatório!');
            return false;
        }else if(status === ''){
            Alert.error('O campo status é obrigatório!');
            return false;
        } 
        else if(categorias === ''){
            Alert.error('O campo categoria é obrigatório!');
            return false;
        }else if(nomeUser === ''){
            Alert.error('O campo nome é obrigatório!');
            return false;
        }
        else if(cpf === ''){
            Alert.error('O campo cpf é obrigatório!');
            return false;
        }
        else if(nascimento === ''){
            Alert.error('O campo Data de nascimento é obrigatório!');
            return false;
        }
        else if(login === ''){
            Alert.error('O campo email do responsável é obrigatório!');
            return false;
        }else if(password === ''){
            Alert.error('O campo senha é obrigatório!');
            return false;
        }else if(password !== password_confirm){
            Alert.error('As senhas não estão iguais!');
            return false;
        }
        else if(latitude === 0){
            Alert.error('O campo latitude é obrigatório!');
            return false;
        }
        else if(longitude === 0){
            Alert.error('O campo longitude é obrigatório!');
            return false;
        }
        else if(raio === ''){
            Alert.error('O campo raio é obrigatório!');
            return false;
        }


        const data = {
            nome,
            logo:logo_upload,
            razao_social,
            email,
            cnpj,
            telefone: telefone.replace(/\D/g, ""),
            celular: celular.replace(/\D/g, ""),
            responsavel:nomeUser,
            cep,
            logradouro,
            complemento,
            numero,
            bairro,
            cidade,
            estado,
            categoria: categorias,
            status,
            raio,
            longitude,
            latitude,
            login,
            cpf,
            nascimento,
            filial: filialFinal,
            password
        };

        await createLoja(data);

    }

	/**
	 * Pesquisar cep
	 */
     async function pesquisacep(valor:any) {
		var cep = valor;

		if (cep !== "") {
            api.get('/endereco/geolocalizacao/'+cep+'/cep')
                .then((res: any) => {
                    setLogradouro(res.data.endereco.logradouro);
                    setBairro(res.data.endereco.bairro);
                    setCidade(res.data.endereco.localidade);
                    setEstado(res.data.endereco.uf);
                    setLatitude(res.data.latitude);
                    setLongitude(res.data.longitude);
                    setSelectedPosition([
                        res.data.latitude,
                        res.data.longitude
                    ]);

                 
            }).catch((error: any) => console.log(error.response));
                 
		}
	}
    
	const estados = {
		Estado: "Estado",
		AC: "Acre",
		AL: "Alagoas",
		AP: "Amapá",
		AM: "Amazonas",
		BA: "Bahia",
		CE: "Ceará",
		DF: "Distrito Federal",
		ES: "Espírito Santo",
		GO: "Goiás",
		MA: "Maranhão",
		MT: "Mato Grosso",
		MS: "Mato Grosso do Sul",
		MG: "Minas Gerais",
		PA: "Pará",
		PB: "Paraíba",
		PR: "Paraná",
		PE: "Pernambuco",
		PI: "Piauí",
		RJ: "Rio de Janeiro",
		RN: "Rio Grande do Norte",
		RS: "Rio Grande do Sul",
		RO: "Rondônia",
		RR: "Roraima",
		SC: "Santa Catarina",
		SP: "São Paulo",
		SE: "Sergipe",
		TO: "Tocantins",
	};

	function options() {
		var es = Object.entries(estados);
		return es.map((item) => (
			<option key={`estado${item[0]}`} value={item[0]}>
				{item[1]}
			</option>
		));
	}

  
    const onImageChange = (event: any) => {
        const { files } = event.target;
        setLogo(URL.createObjectURL(files[0]));
        setLogoUpload(files[0]);
        
    };

    const handlClearFoto = async () => {
        setLogo('');
        setLogoUpload('');
    };

    

    return (
        <div>
        { isLoading ? 
            <Loading /> 
        :
        <S.Container onSubmit={handleCreateNewLoja}>
            <S.ContainerCardForm>
                <S.Column>
                    <S.Row>
                        <div className="card-form">
                            <h3>Dados da empresa</h3>
                            <S.FotoContainer>
                                {logo ? (
                                    <>
                                    <InputGroup className="close_search" label="Excluir foto">
                                        <InputGroup.Button onClick={() => handlClearFoto()}>
                                            <Icon icon="close" />
                                        </InputGroup.Button>
                                    </InputGroup>
                                    <img src={logo} alt="imagem"  width="300" height="300" 
                                    onChange={onImageChange} />
                                    </>
                                    ) : (
                                    <>
                                    <Icon icon="file-image-o" size="4x" />
                                    <input
                                        type="file"
                                        id="upload-button"
                                        onChange={onImageChange}
                                    />
                                    <label>Clique aqui para inserir uma imagem</label>
                                    </>
                                    )}
                                </S.FotoContainer>

                            {Number(localStorage.getItem('TCHAMA')) === 1 ?
                            <FormGroup>
                                <label>Filial: </label>
                                <select
                                    value={filiais}
                                    onChange={event => setFilial(event.target.value)}
                                    name="filial">
                                    <option value="">Selecione a Filial do usuário</option>
                                    {filial && filial.map(filial => (
                                    <option value={filial.uuid} key={filial.uuid}>
                                        {filial.razao_social}
                                    </option>
                                    ))}
                                </select>
                            </FormGroup>
                            : null }
                            <FormGroup>
                                <label>Nome fantasia: </label>
                                <input 
                                    type="text" 
                                    placeholder="Nome fantasia"
                                    value={nome}
                                    onChange={event => setNome(event.target.value)}
                                    
                                /> 
                                </FormGroup>
                            <FormGroup>
                                <label>Razão Social: </label>
                            <input 
                                type="text" 
                                placeholder="Razão social"
                                value={razao_social}
                                onChange={event => setRazaoSocial(event.target.value)}
                                
                            />
                            </FormGroup>
                            <FormGroup>
                                <label>E-mail: </label>
                                <input 
                                    type="email" 
                                    placeholder="E-mail" 
                                    value={email}
                                    onChange={event => setEmail(event.target.value)}
                                    
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>CNPJ:</label>
                                <InputMask 
                                    type="text" 
                                    mask="99.999.999/9999-99"
                                    placeholder="CNPJ" 
                                    value={cnpj}
                                    onChange={event => setCNPJ(event.target.value)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Telefone:</label>
                                <InputMask 
                                    type="text" 
                                    mask="(99) 9999-9999"
                                    placeholder="Telefone" 
                                    onChange={event => setTelefone(event.target.value)}    
                                    value={telefone} 
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Celular:</label>
                                <InputMask 
                                    type="text" 
                                    mask="(99) 99999-9999"
                                    placeholder="Celular"  
                                    value={celular}
                                    onChange={event => setCelular(event.target.value)} 
                                />
                            </FormGroup>
                        </div>
                        <div className="card-form">
                            <h3>Endereço</h3>
                            <FormGroup>
                                <label>CEP:</label>
                                <InputMask
                                    mask="99999-999"
                                    value={cep}
                                    placeholder="CEP"
                                    type="text" 
                                    onChange={(event) => {
                                        setCEP(event.target.value);
                                    }}
                                    onBlur={(event) =>
                                        pesquisacep(event.target.value)
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Logradouro:</label>
                                <input 
                                    type="text" 
                                    placeholder="Logradouro"    
                                    value={logradouro}
                                    onChange={event => setLogradouro(event.target.value)}
                                    
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Complemento:</label>
                                <input 
                                    type="text" 
                                    placeholder="Complemento"    
                                    value={complemento}
                                    onChange={event => setComplemento(event.target.value)}
                                    
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Número:</label>
                                <input 
                                    type="text" 
                                    placeholder="Número" 
                                    value={numero}
                                    onChange={event => setNumero(event.target.value)}
                                    
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Bairro:</label>
                                <input 
                                    type="text" 
                                    placeholder="Bairro" 
                                    value={bairro}
                                    onChange={event => setBairro(event.target.value)}
                                    
                            />
                            </FormGroup>
                            <FormGroup>
                                <label>Cidade:</label>
                                <input 
                                    type="text" 
                                    placeholder="Cidade" 
                                    value={cidade}
                                    onChange={event => setCidade(event.target.value)}
                                    
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Estado:</label>
                                <select
                                    placeholder="Estado"
                                    onChange={(e) => {
                                        setEstado(e.target.value);
                                    }}
                                    value={estado}
                                >
                                    {options()}
                            </select>
                            </FormGroup>
                        </div>
                        <div className="card-form">
                            <h3>Controle</h3>
                            <FormGroup>
                                <label>Status:</label>
                                <S.StatusLojaContainer>
                                    <S.RadioBox 
                                        type="button"
                                        onClick={() => setStatus(String(1))}
                                        isActive={status === '1'}
                                        activeColor="green"
                                        activeColorFonte="white"
                                    >   
                                        <span>Ativo</span>
                                    </S.RadioBox>
                                    <S.RadioBox 
                                        type="button"
                                        onClick={() => setStatus(String(2))}
                                        isActive={status === '2'}
                                        activeColor="red"
                                        activeColorFonte="white"
                                    >
                                        <span>Inativo</span>
                                    </S.RadioBox>
                                </S.StatusLojaContainer>
                                
                            </FormGroup>
                            <FormGroup>
                                <label>Categoria: </label>
                                <select
                                    value={categorias}
                                    onChange={event => setCategoria(event.target.value)}
                                    name="filial">
                                    <option value="">Selecione a categoria da loja</option>
                                    {categoria && categoria.map(categoria => (
                                    <option value={categoria.nome} key={categoria.uuid}>
                                        {categoria.nome}
                                    </option>
                                    ))}
                                </select>
                            </FormGroup>
                        </div>   
                    </S.Row> 
                    <div className="card-form">
                        <h3>Dados do responsável</h3>
                        <FormGroup>
                            <label>Nome: </label>
                            <input 
                                type="text" 
                                placeholder="Nome" 
                                value={nomeUser}
                                onChange={event => setNomeUser(event.target.value)}
                                
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>CPF: </label>
                            <InputMask 
                                type="text" 
                                mask="999.999.999-99"
                                placeholder="CPF" 
                                onChange={event => setCPF(event.target.value)}    
                                value={cpf} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Data de nascimento: </label>
                            <InputMask 
                                type="text" 
                                mask="99/99/9999"
                                placeholder="Data de nascimento"  
                                value={nascimento} 
                                onChange={event => setNascimento(event.target.value)} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>E-mail(usado para o login): </label>
                            <input 
                                type="email" 
                                placeholder="E-mail" 
                                value={login}
                                onChange={event => setLogin(event.target.value)}
                                
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Senha: </label>
                            <input
                                type="password" 
                                placeholder="Senha"  
                                value={password} 
                                onChange={event => setSenha(event.target.value)} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Confirme a senha: </label>
                            <input
                                type="password" 
                                placeholder="Senha"  
                                value={password_confirm} 
                                onChange={event => setConfirmeSenha(event.target.value)} 
                            />
                        </FormGroup>
                    </div> 
                </S.Column>
            </S.ContainerCardForm>
            <S.ContainerMapa>
                <h2>Digite nos inputs ou selecione no mapa a latitude e longitude</h2>
                <div className="input">
                    <FormGroup>
                        <label>Latitude: </label>
                        <input 
                            type="text" 
                            placeholder="Latitude"
                            value={latitude}
                            onChange={event => setLatitude(Number(event.target.value))}
                            
                        /> 
                    </FormGroup>
                    <FormGroup>
                        <label>Longitude: </label>
                        <input 
                            type="text" 
                            placeholder="Longitude"
                            value={longitude}
                            onChange={event => setLongitude(Number(event.target.value))}
                            
                        /> 
                    </FormGroup>
                    <FormGroup>
                        <label>Raio(km): </label>
                        <input 
                            type="text" 
                            placeholder="Raio"
                            value={raio}
                            onChange={event => setRaio(event.target.value)}
                            
                        /> 
                    </FormGroup>
                </div>
                <MapContainer 
                    center={selectedPosition} 
                    zoom={12}    
                    className="leaflet-map"                       
                >
                    <Markers />
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />                        
                </MapContainer>
            </S.ContainerMapa>
            <button type="submit">{isLoading ? 'Carregando...' : 'Cadastrar'}</button>
        </S.Container>
            }
        </div>

    )
}
