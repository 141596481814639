import * as S from "./styles";
import { LojaProvider } from '../../hooks/useLoja'
import { FilialProvider } from '../../hooks/useFilial'
import { CategoriaProvider } from '../../hooks/useCategoria'
import Admin from '../../components/Layouts/admin';
import { NewLojaForm } from "../../components/NewLojaForm";
import { PerfilProvider } from '../../hooks/usePerfil'

export function NovaLoja() {
    return (
        <LojaProvider>
            <FilialProvider>
                <PerfilProvider>
                    <CategoriaProvider>
                        <Admin pageTitle={'Cadastrar Loja'} > 
                            <S.Container>
                                <NewLojaForm />
                            </S.Container>
                        </Admin>    
                    </CategoriaProvider>
                </PerfilProvider>
            </FilialProvider>
        </LojaProvider>
    );
}
