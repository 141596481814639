import {createContext, useState, useEffect, ReactNode, useContext, useCallback } from 'react'
import api from "../services/api";
import { Alert } from 'rsuite';
import { v4 as uuidv4 } from 'uuid';


interface Filial {
    id: number;
    uuid: string;
    url: string;
    logo: any;
    nome: string;
    nome_fantasia: string;
    razao_social: string;
    comissao: string;
    cnpj: any;
    email: string;
    telefone: any;
    celular: any;
    cep: any;
    logradouro: string;
    numero: string;
    complemento: string;
    bairro: string;
    cidade: string;
    estado: string;
    password: string;
    data_criacao: any;
    data_atualizacao: any;
    login: any;
    cpf: any;
    nascimento: any;
    usuario: any;
    data_nascimento: any;
    responsavel: any;
    comissao_loja: any;
    status: any;
}


interface FilialPagination {
    pagina_atual: any,
    proxima_pagina: any,
    pagina_anterior: any,
    ultima_pagina: any,
    total_itens: any
}

interface FilialNomeUUID {
    uuid:  any,
    razao_social: any, 
}

interface FilialProviderProps {
    children: ReactNode; //pode retornar conteudo html, por exemplo
}

interface FilialContextData {
    filial: Filial[]; //um array de objetos do tipo Filial
    filialPage: (filial:any) => Promise<void>;
    filialUuidNome: () => void;
    filialSearch: (filial:any, modal: any) => Promise<void>;
    createFilial: (filial: FilialInput, modal: any) => Promise<void>; 
    getfilialPorUuid: (url: any, page:any) => Promise<void>; 
    editFilial: (filial: FilialInputEdit, modal: any, uuid:any, page:any) => Promise<void>;
    filialDelete: (uuid:any, usuario:any, modal: any) => Promise<void>;
    activePage: string;
    isLoading: boolean; 
    filialPagination: FilialPagination[]; //um array de objetos do tipo Filial
    filialPorUUID: FilialInputEdit[]; //um array de objetos do tipo Filial
    filialNomeUUID: FilialNomeUUID[]; //um array de objetos do tipo Filial
    isLoadingSearch: boolean;
    limpaSearchFilial: any;
}

type FilialInput = Omit<Filial, 'id' | 'url' | 'uuid' | 'data_criacao' | 'data_atualizacao' | 'data_nascimento' | 'usuario' | 'responsavel'  > 

type FilialInputEdit = Omit<Filial, 'id' | 'url' | 'data_criacao' | 'data_atualizacao' | 'pagina_atual' | 'nascimento'  | 'cpf' | 'nome' |'email' | 'login' | 'data_nascimento' | 'password' >

const FilialContext = createContext<FilialContextData>(
    {} as FilialContextData //criando um Contexto, começa com um objeto vazio como default
);


export function FilialProvider({children} : FilialProviderProps) {
    const [filial, setFilial] = useState<Filial[]>([]);
    const [filialPorUUID, setFilialPorUUID] = useState<Filial[]>([]);
    const [filialPagination, setFilialPagination] = useState<FilialPagination[]>([]);
    const [filialNomeUUID, setFilialNomeUUID] = useState<FilialNomeUUID[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [activePage, setPageActive] = useState('');
    const [isLoadingSearch, setIsLoadingSearch] = useState(true);

    const handleFilial = useCallback(async () => {
        
        const buscaAtiva = localStorage.getItem('salvarBuscaFilial');

        if(buscaAtiva && buscaAtiva !== ''){
            setIsLoading(true);
            let search = JSON.parse(buscaAtiva);
            const response = await api.get('/filial?razao_social='+search.razao_social+'&status='+search.status+'&nome_fantasia='+search.nome_fantasia); 
            setFilial(response.data.itens);
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                }
            ];
            setFilialPagination(data);
            setIsLoading(false);
        }else{
           setIsLoading(true);
            const response = await api.get('/filial?page=1');
            setFilial(response.data.itens);
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                }
            ];
            setFilialPagination(data);
            setIsLoading(false);
        }
       
      }, []);

    useEffect(() => {
        handleFilial();
      }, [handleFilial]);

    // FILIAL POR UUID

    async function filialUuidNome() {
        try {
            setIsLoading(false);
                
            const response = await api.get('/filial');  // FilialInput são todos os inputs do form

            const data = [{
                    'uuid' : response.data.uuid,
                    'razao_social' : response.data.razao_social,
                }
            ];
            setFilialNomeUUID(data);

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }

    // PEGA uuid e razao social

    async function filialPage(page : any) {
        try {
            
            const buscaAtiva = localStorage.getItem('salvarBuscaFilial');
            if(buscaAtiva && buscaAtiva !== ''){
                setIsLoading(true);

                let search = JSON.parse(buscaAtiva);
                const response = await api.get('/filial?razao_social='+search.razao_social+'&status='+search.status+'&nome_fantasia='+search.nome_fantasia+'&page='+page);

                setFilial(response.data.itens);
                const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                setIsLoading(false);
                setFilialPagination(data);
            }else{

                 setIsLoading(true);
            
                const response = await api.get('/filial?page='+page);             

                setFilial(response.data.itens);
                const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                setIsLoading(false);
                setFilialPagination(data);
            }

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }

    async function filialSearch(search : any, Modal: any) {
        try {
            setIsLoading(false);

            if(search.razao_social === undefined  && search.status === ''   && search.nome_fantasia === ''){
                handleFilial();
            } else {
                 const response = await api.get('/filial?razao_social='+search.razao_social+'&status='+search.status+'&nome_fantasia='+search.nome_fantasia);  // FilialInput são todos os inputs do form
                 if(response.data.error === true ){
                    Alert.error(response.data.message);

                 }else {
                    localStorage.setItem('buscaAtivaFilial', 'ativa');
                    localStorage.setItem('salvarBuscaFilial', JSON.stringify(search));

                    setFilial(response.data.itens);
                    const data = [{
                            'pagina_atual' : response.data.pagina_atual,
                            'proxima_pagina' : response.data.proxima_pagina,
                            'pagina_anterior' : response.data.pagina_anterior,
                            'ultima_pagina' : response.data.ultima_pagina,
                            'total_itens' : response.data.total_itens
                        }
                    ];
                    setFilialPagination(data);
                 }
            }
            
            Modal();
            

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }

      

    // FILIAL POR UUID

    async function getfilialPorUuid(url : any, page:any) {
        try {
            setIsLoading(true);
                
            const responseedit = await api.get('/filial/'+url);  // FilialInput são todos os inputs do form

            // filialPorUUID.push(responseedit.data);
            setFilialPorUUID([responseedit.data]);
            setPageActive(page);

            setIsLoading(false);
        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }


    //   CRIA FILIAL
    
    async function createFilial(FilialInput: FilialInput, Modal: any) {
        let uuid = uuidv4();
        try {
                
            const data = new FormData();
            const dataUsuario = new FormData();
     
            data.append("uuid", uuid);
            data.append("cnpj", FilialInput.cnpj);
            data.append("razao_social", FilialInput.razao_social);
            data.append("nome_fantasia", FilialInput.nome_fantasia);
            data.append("logo", FilialInput.logo);
            data.append("cep", FilialInput.cep);
            data.append("logradouro", FilialInput.logradouro);
            data.append("complemento", FilialInput.complemento);
            data.append("numero", FilialInput.numero);
            data.append("bairro", FilialInput.bairro);
            data.append("cidade", FilialInput.cidade);
            data.append("estado", FilialInput.estado);
            data.append("responsavel", FilialInput.nome);
            data.append("email", FilialInput.email);
            data.append("comissao", FilialInput.comissao);
            data.append("comissao_loja", FilialInput.comissao_loja);
            data.append("telefone", FilialInput.telefone);
            data.append("celular", FilialInput.celular);
            data.append("status", FilialInput.status as any);

            dataUsuario.append("filial", uuid);
            dataUsuario.append("email", FilialInput.login);
            dataUsuario.append("cpf", FilialInput.cpf);
            dataUsuario.append("nascimento",  FilialInput.nascimento);
            dataUsuario.append("telefone", FilialInput.telefone);
            dataUsuario.append("celular", FilialInput.celular);
            dataUsuario.append("nome", FilialInput.nome);
            dataUsuario.append("password", FilialInput.password);
            dataUsuario.append("tipo", Number(2) as any);
            dataUsuario.append("status", FilialInput.status as any);
  
            setIsLoading(true);
            const response = await api.post('/filial', data);  
            if(response.data.message === "Filial criada com sucesso!"){
                 const responseUsuario = await api.post('/filial/usuario', dataUsuario);
                 if(responseUsuario.data.error === false){

                        Alert.success("Filial cadastrada com sucesso");
            
                        let keysToRemove = ["buscaAtivaFilial", "salvarBuscaFilial"];

                        keysToRemove.forEach(k =>
                            localStorage.removeItem(k));
                            
                    setIsLoading(false);
                    handleFilial();
                    Modal();
                 }
            }else if(!response || !response.data.message){
                    setIsLoading(false);
                    await api.delete('/usuario/'+uuid);
                    Alert.error('Você está tentando cadastrar uma filial com um CNPJ já existente, por favor tente com outro CNPJ');
            }
            setIsLoading(false);

        } catch (err: any) {
            setIsLoading(false); 
            if(err.response.status !== 200){
                Alert.error('Você está tentando cadastrar filial ou usuário com dados já existentes no banco de dados, por favor verifique o CPF, CNPJ ou email.');
                await api.delete('/filial/'+uuid);
                await api.delete('/usuario/'+uuid);
            }
        }
    } //criando uma nova filial com os dados passado por parâmetro 

    // EDITANDO AS FILIAIS
    async function editFilial(FilialInputEdit: FilialInputEdit, Modal: any, uuid: any, page:any) {
        try {
            setIsLoading(true);

            const data = new FormData();


            data.append("cnpj", FilialInputEdit.cnpj);
            data.append("razao_social", FilialInputEdit.razao_social);
            data.append("nome_fantasia", FilialInputEdit.nome_fantasia);
            data.append("logo", FilialInputEdit.logo);
            data.append("cep", FilialInputEdit.cep);
            data.append("logradouro", FilialInputEdit.logradouro);
            data.append("complemento", FilialInputEdit.complemento);
            data.append("numero", FilialInputEdit.numero);
            data.append("bairro", FilialInputEdit.bairro);
            data.append("cidade", FilialInputEdit.cidade);
            data.append("estado", FilialInputEdit.estado);
            data.append("celular", FilialInputEdit.celular);
            data.append("telefone", FilialInputEdit.telefone);
            data.append("responsavel", FilialInputEdit.responsavel);
            data.append("comissao", FilialInputEdit.comissao);
            data.append("comissao_loja", FilialInputEdit.comissao_loja);
            data.append("status", FilialInputEdit.status as any);

            const response = await api.post('/filial/atualizar/'+uuid, data); 
            const buscaAtiva = localStorage.getItem('salvarBuscaFilial');

            if(buscaAtiva && buscaAtiva !== ''){

                let search = JSON.parse(buscaAtiva);
                const responsepage = await api.get('/filial?razao_social='+search.razao_social+'&status='+search.status+'&nome_fantasia='+search.nome_fantasia+'&page='+page);
                
                setFilial(responsepage.data.itens);
                const datapage = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                
                setFilialPagination(datapage);
    
                Alert.success("Dados atualizados com sucesso!");
                Modal();
                setIsLoading(false);
    
            }else{
                let keysToRemove = ["buscaAtivaFilial", "salvarBuscaFilial"];
    
                keysToRemove.forEach(k =>
                    localStorage.removeItem(k));
                    
                const responsepage = await api.get('/filial?page='+page);    
                setFilial(responsepage.data.itens);
                const datapage = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                setFilialPagination(datapage);
        
                Alert.success("Dados atualizados com sucesso!");
                Modal();
                setIsLoading(false);
            }
          
            setIsLoading(false);
     

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    } 

    async function limpaSearchFilial() {
        try {
            setIsLoadingSearch(true);
            
            handleFilial();
            let keysToRemove = ["buscaAtivaFilial", "salvarBuscaFilial"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));
                window.location.reload();

            setIsLoadingSearch(false);

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoadingSearch(false);
        }
    }

    async function filialDelete(uuid : any, usuario: any, Modal: any) {
        try {
            setIsLoading(false);
        
            if(uuid === undefined){
                handleFilial();
            } else {
                await api.delete('/filial/usuario/'+usuario);
                 const response = await api.delete('/filial/'+uuid);  
                    
                 if(response.data.error === true ){
                    Alert.error(response.data.message);

                 }else {
                    setFilial(response.data.itens);
                    const datapage = [{
                        'pagina_atual' : response.data.pagina_atual,
                        'proxima_pagina' : response.data.proxima_pagina,
                        'pagina_anterior' : response.data.pagina_anterior,
                        'ultima_pagina' : response.data.ultima_pagina,
                        'total_itens' : response.data.total_itens
                        }
                    ];
                    setFilialPagination(datapage);
                 }
            }
            
            handleFilial();
            Modal();
            

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }
    
    return (
        <FilialContext.Provider value={{filial, createFilial, isLoading, getfilialPorUuid, filialPorUUID, editFilial, filialPagination, filialPage, filialUuidNome, filialSearch, filialNomeUUID, limpaSearchFilial, isLoadingSearch, activePage, filialDelete }}> {/* retornando as filiais como valor do contexto e a função que cria uma nova filial */}
            {children} {/* recebe um conteudo do componente filho que são o Header, Dashboard e NewFilialModal */} 
        </FilialContext.Provider>
    )

}


export function useFilial() {
    const context = useContext(FilialContext);

    return context;
}
