import Modal from 'react-modal'
import { useUsuarioFilial } from "../../hooks/useUsuarioFilial"
import { FormEvent, useState } from 'react'
import InputMask from "react-input-mask";


import { MdClose } from "react-icons/md"
import * as S from "./styles"

import {FormGroup} from 'rsuite';


interface EditUsuarioFilialModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
}

export function EditUsuarioFilialModal({isOpen, onRequestClose}: EditUsuarioFilialModalProps) {
    const { usuarioPorUUID, isLoadingEdit, editUsuario, activePage } = useUsuarioFilial();

    const tipoUsuario = Number(localStorage.getItem('TCHAMA'));
    const [nome, setNome] = useState(usuarioPorUUID[0].nome || '');
    const [cpf, setCPF] = useState(usuarioPorUUID[0].cpf.br || '');
    const [email, setEmail] = useState(usuarioPorUUID[0].email || '');
    const [telefone, setTelefone] = useState(usuarioPorUUID[0].telefone.br || '');
    const [celular, setCelular] = useState(usuarioPorUUID[0].celular.br || '');
    const [nascimento, setNascimento] = useState(usuarioPorUUID[0].data_nascimento.br || '');
    const [status, setStatus] = useState(usuarioPorUUID[0].status || '');

    async function handleEditUsuarioFilial(event: FormEvent){
        event.preventDefault();

           let data = {
                nome,
                cpf,
                email,
                telefone,
                celular,
                avatar: usuarioPorUUID[0].avatar,
                filial: usuarioPorUUID[0].filial,
                loja: usuarioPorUUID[0].loja,
                tipo: usuarioPorUUID[0].tipo,
                nascimento,
                status
            }


            await editUsuario(data, onRequestClose, usuarioPorUUID[0].uuid, activePage);

    }

    return (
        
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <button type="button" onClick={onRequestClose} className="react-modal-close">
                <MdClose />
            </button>
            <S.Container onSubmit={handleEditUsuarioFilial}>
                <h2>Editar Usuário</h2>
                <S.ContainerCardForm>
                    <div className="card-form">
                        <FormGroup>
                            <label>CPF: </label>
                            <InputMask 
                                type="text" 
                                mask="999.999.999-99"
                                placeholder="CPF" 
                                onChange={event => setCPF(event.target.value)}    
                                value={cpf} 
                                disabled
                            />
                        </FormGroup>
                        {tipoUsuario === 1 ? 
                        <FormGroup>
                            <label>Filial: </label>
                            <input 
                                type="text" 
                                value={usuarioPorUUID[0].filial_nome} 
                                disabled
                            />
                        </FormGroup>
                        : null
                        }
                        <FormGroup>
                            <label>Nome</label>
                            <input
                                type="text"
                                placeholder="Nome"
                                value={nome} 
                                onChange={event => setNome(event.target.value)}    
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Data de nascimento: </label>
                            <InputMask 
                                type="text" 
                                mask="99/99/9999"
                                placeholder="Data de nascimento"  
                                value={nascimento} 
                                onChange={event => setNascimento(event.target.value)} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>E-mail: </label>
                            <input
                                type="text"
                                placeholder="E-mail"
                                value={email} 
                                onChange={event => setEmail(event.target.value)}   
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Telefone: </label>
                            <InputMask 
                                type="text" 
                                mask="(99) 9999-9999"
                                placeholder="Telefone" 
                                onChange={event => setTelefone(event.target.value)}    
                                value={telefone} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Celular: </label>
                            <InputMask 
                                type="text" 
                                mask="(99) 99999-9999"
                                placeholder="Celular" 
                                onChange={event => setCelular(event.target.value)}    
                                value={celular} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Status:</label>
                            <S.StatusFilialContainer>
                                <S.RadioBox 
                                    type="button"
                                    onClick={() => setStatus(1)}
                                    isActive={status === 1}
                                    activeColor="green"
                                    activeColorFonte="white"
                                >   
                                    <span>Ativo</span>
                                </S.RadioBox>
                                <S.RadioBox 
                                    type="button"
                                    onClick={() => setStatus(2)}
                                    isActive={status === 2}
                                    activeColor="red"
                                    activeColorFonte="white"
                                >
                                    <span>Inativo</span>
                                </S.RadioBox>
                            </S.StatusFilialContainer>
                        </FormGroup>
                    </div>
                </S.ContainerCardForm>
                
                <button type="submit">{isLoadingEdit ? 'Carregando...' : 'Atualizar'}</button>
            </S.Container>
        </Modal>
    )
}
