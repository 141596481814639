import styled from 'styled-components'

export const Container = styled.div`
    margin-top: 2rem;

    button {
        height: 3rem;
        padding: 0 2rem;
        font-size: 1rem;
        color: #FFF;
        background-color: var(--info-color);
        border-color: var(--info-color);
        border-radius: 0.25rem;
        border: none;
        margin-bottom: 1.25rem;

        transition: filter 0.2s;

        &:hover{
            filter: brightness(0.9)
        }
        &:focus{
            outline: none
        }
    }

    table {
        width: 100%;
        border-spacing: 0 0.5rem;

        th {
            color: var(--text-body);
            font-weight: 400;
            text-align: left;
            line-height: 1.5em;
            background: var(--shape);
            padding: 1rem 2rem;
            border-bottom: 2px solid var(--red);
            &.status {
                text-align: center;
            }
            @media screen and (max-width: 600px) {
                &:nth-child(2) {
                    display: none;
                }
            }
        }

        tr {
            cursor: pointer;
            border-bottom: 2px solid #eee;
            &:hover{
                filter: brightness(0.9);
            }
        }
        
        td {
            padding: 1rem 2rem;
            border: 0;
            background: var(--shape);
            font-weight: 400;
            color: var(--text-body);
            border-radius: 0.25rem;

            @media screen and (max-width: 600px) {
                &:nth-child(2) {
                    display: none;
                }
            }

            &:first-child(1){
                color: var(--text-title);
            }


        }

        .ativo  {
            vertical-align: middle;
            div{
                margin: 0 auto;
                content: ' ';
                height: 25px;
                width: 8px;
                background: var(--green);
                border-radius: 2px;
            }
        }

        .inativo {
            vertical-align: middle;
            div{
                margin: 0 auto;
                content: ' ';
                height: 25px;
                width: 8px;
                background: var(--red);
                border-radius: 2px;
            }
        }
    }
`

export const ContainerPagination = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .rs-pagination > li.rs-pagination-btn-active > a{
        color: var(--red);
    }
`
export const ContainerButtonBusca = styled.div`
    display: flex;
    align-items: center;
    height: 3rem;
    border-radius: 0.25rem;
    margin-bottom: 1.70rem;
    border: none;
    .filial{
        margin-left: 20px;
        background-color: var(--text-title);
        border-color: var(--text-title);
        border-radius: 0.25rem;
        border: none;
        margin-bottom: 1.25rem;

        transition: filter 0.2s;

        &:hover{
            filter: brightness(0.9);
            color: #FFF;
        }
        &:focus{
            outline: none;
            color: #FFF;
        }
    }
    .admin{
        margin-left: 20px;
        background-color: var(--text-body);
        border-color: var(--text-body);
        border-radius: 0.25rem;
        border: none;
        margin-bottom: 1.25rem;

        transition: filter 0.2s;

        &:hover{
            filter: brightness(0.9);
            color: #FFF;
        }
        &:focus{
            outline: none;
            color: #FFF;
        }
    }
`
