import Modal from 'react-modal'
import { useCaixaloja } from "../../hooks/useCaixaloja"

import { MdClose } from "react-icons/md"
import * as S from "./styles"



interface VerCaixaLojaModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
}

export function VerCaixaLojaModal({isOpen, onRequestClose}: VerCaixaLojaModalProps) {
    const { caixaPorUUID } = useCaixaloja();

    return (
        
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <button type="button" onClick={onRequestClose} className="react-modal-close">
                <MdClose />
            </button>
            <S.Container >
                <h2>Ver Caixa</h2>
                <S.ContainerCardForm>
                    <div className="item">
                        <h1>Vendas</h1>
                        <div className="subtitulo">
                            <p><span>Período</span> <br />  R$ {caixaPorUUID[0]?.periodo}</p>
                            <p><span>Total</span> <br /> R$ {caixaPorUUID[0]?.total?.br}</p>
                        </div>
                    </div>

                    <div className="caixa">
                        <h1>Visão geral de vendas</h1>
                        <h2><span>R$</span>{caixaPorUUID[0]?.total?.br}</h2>

                        <h2><span>R$</span>{caixaPorUUID[0]?.total?.br} <br />
                        <span>Dinheiro em conta</span></h2>
                    </div>
                </S.ContainerCardForm>
                
                <S.ContainerCardForm>
                    <div className="item">
                        <h1>Repasses</h1>
                        <div className="subtitulo">
                            <p><span>Conta</span> <br />  R$  {caixaPorUUID[0]?.total_conta?.br}</p>
                            <p><span>Taxas</span> <br /> R$  {caixaPorUUID[0]?.taxas?.br}</p>
                        </div>
                    </div>
                    <div className="caixa">
                        <h1>Visão geral de repasses</h1>
                        <h2><span>R$</span>{caixaPorUUID[0]?.total_conta?.br}</h2>
                        <h2><span>R$</span>{caixaPorUUID[0]?.total_conta?.br} <br />
                        <span>Taxas</span></h2>
                    </div>
                </S.ContainerCardForm>
                
            </S.Container>
        </Modal>
    )
}
