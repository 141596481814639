import * as S from "./styles";
import {Loading} from '../Loading'
import {useState} from 'react';
import { Pagination, Modal, Button, Icon, IconButton } from 'rsuite';
import { useCategoria } from "../../hooks/useCategoria";

import Skeleton from "react-loading-skeleton";


interface CategoriaTableProps {
    onOpenNewCategoriaModal: () => void;
    onOpenEditCategoriaModal: () => void;
}
export function CategoriaTable({
    onOpenNewCategoriaModal,
    onOpenEditCategoriaModal
}: CategoriaTableProps) {

    const { categoria, isLoading, getcategoriaUuid, categoriaPagination, categoriaPage, categoriaDelete } = useCategoria();

    const [deletar, setDeletar] = useState('');
  
    const handleClickOpenEdit = async (uuid: any, page:any) => {
        await getcategoriaUuid(uuid, page);
        onOpenEditCategoriaModal();
    };

    const handleSelect = async (eventKey: any) => {
        await categoriaPage(eventKey);
    }
    const [modalDelete, setModalDelete] = useState(false);

    function handleModalDeleteClose (){
        setModalDelete(false);
    }
    
    const handleModalshowDelete = async (uuid: any) => {
        setModalDelete(true);
        setDeletar(uuid);
    }

    const handleModalDeletar = async (uuid: any, modal: any) => {

        await categoriaDelete(uuid, modal);
    }

    return (
        <S.Container>
            <div>
                {isLoading ? <Loading /> : ''}
            </div>
            <button type="button" onClick={onOpenNewCategoriaModal}>
                Nova Categoria
            </button>
      
            <table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Data de criação</th>
                        <th className="status">Status</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody>
                    {categoria && categoria.map(categoria => (
                        
                        <tr 
                            key={categoria.uuid}  
                        >
                            <td 
                            onClick={() => handleClickOpenEdit(categoria.uuid, categoriaPagination[0].pagina_atual)}
                            >{isLoading ? <Skeleton /> : categoria.nome}</td>
                            <td 
                            onClick={() => handleClickOpenEdit(categoria.uuid, categoriaPagination[0].pagina_atual)}
                            >
                                {isLoading ? <Skeleton /> : categoria.data_criacao.data}
                            </td>
                            <td 
                            onClick={() => handleClickOpenEdit(categoria.uuid, categoriaPagination[0].pagina_atual)}
                             className={categoria.status === 1 ? 'ativo' : 'inativo'}> 
                                {isLoading ? <Skeleton /> : <div></div>}
                            </td>
                            <td>
                                <IconButton size="lg" style={{padding: "0px", width: "42px", marginBottom: "0px"}} color="red" onClick={() => handleModalshowDelete(categoria.uuid)} icon={<Icon icon="close" />} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <S.ContainerPagination>
            {categoriaPagination.length ?
                <Pagination
                prev
                last
                next
                first
                size="lg"
                pages={categoriaPagination[0].ultima_pagina}
                activePage={categoriaPagination[0].pagina_atual}
                onSelect={handleSelect}
                />
                : null
            }
            </S.ContainerPagination>

            <Modal backdrop="static" show={modalDelete} onHide={handleModalDeleteClose} size="xs">
                <Modal.Body>
                    <Icon
                    icon="remind"
                    style={{
                        color: '#990606',
                        fontSize: 24
                    }}
                    />
                    {'  '}
                    Tem certeza que deseja deletar essa categoria?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleModalDeletar(deletar, handleModalDeleteClose)} color="red">
                    Quero deletar
                    </Button>
                    <Button onClick={handleModalDeleteClose} appearance="subtle">
                    Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </S.Container>
    );
}
