import {createContext, useState, useEffect, ReactNode, useContext, useCallback } from 'react'
import api from "../services/api";
import { Alert } from 'rsuite';

interface Perfil {
    uuid: string;
    avatar: any,
    cpf: any,
    email: string,
    nome: string,
    status: any,
    celular: any,
    telefone: any,
    tipo: any,
    data_acesso: string,
    data_atualizacao: string,
    data_criacao: string,
    data_nascimento: any,
    data_senha: string
}

interface PerfilAvatar {
    avatar: any
}

interface PerfilSenha {
    senha_atual: any,
    nova_senha: any,
    confirmar_senha: any,
}

interface PerfilProviderProps {
    children: ReactNode; //pode retornar conteudo html, por exemplo
}

interface PerfilContextData {
    perfil: PerfilInput[]; 
    isLoading: boolean; 
    updateAvatar: (perfil: PerfilInputAvatar, uuid: string) => Promise<void>;
    editPerfil: (perfil: PerfilInputEdit, uuid: string) => Promise<void>;
    updateSenha: (perfil: PerfilSenha) => Promise<void>;
}

type PerfilInput = Omit<Perfil, 'id' | 'data_criacao' | 'data_atualizacao' | 'data_senha'  | 'data_acesso' | 'imagem' | 'status' | 'tipo' >

type PerfilInputEdit = Omit<Perfil, 'id' | 'uuid' | 'data_criacao' | 'data_atualizacao' | 'data_senha'  | 'data_acesso' | 'imagem' | 'status' | 'tipo' > 

type PerfilInputAvatar = Omit<PerfilAvatar, 'id'  | 'uuid' | 'data_criacao' | 'data_atualizacao' | 'data_senha'  | 'data_acesso' | 'imagem' | 'status' | 'tipo' | 'cpf' | 'email' | 'nome' | 'status' | 'celular' | 'telefone' | 'tipo' >

const PerfilContext = createContext<PerfilContextData>(
    {} as PerfilContextData //criando um Contexto, começa com um objeto vazio como default
);


export function PerfilProvider({children} : PerfilProviderProps) {
    const [perfil, setPerfil] = useState<Perfil[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    // pega o perfil
    const handlePerfil = useCallback(async () => {
        setIsLoading(true);
        const response = await api.get('/filial/auth/usuario');

        setPerfil([response.data]);
        setIsLoading(false);
      }, []);

    useEffect(() => {
        handlePerfil();
    }, [handlePerfil]);


    // EDITANDO AS PERFIL
    async function editPerfil(PerfilInputEdit: PerfilInputEdit, uuid: string) {
        try {
            setIsLoading(false);

            let data = {  
                nome: PerfilInputEdit.nome,
                cpf: PerfilInputEdit.cpf,
                email: PerfilInputEdit.email,
                telefone: PerfilInputEdit.telefone,
                celular: PerfilInputEdit.celular,
                nascimento: PerfilInputEdit.data_nascimento,
                status: '1'
            };
            
            const response = await api.put('/filial/usuario/'+uuid, data); 
           
            handlePerfil();
            Alert.success(response.data.message);

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false);
        }
    } 

    
    async function updateAvatar(PerfilInputAvatar: PerfilInputAvatar, uuid: string) {
        try {
            setIsLoading(false);
            
            const data = new FormData();
            data.append("avatar", PerfilInputAvatar.avatar);
    
            const response = await api.post('/filial/usuario/avatar/'+uuid, data); 

            handlePerfil();
            Alert.success(response.data.message);

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false);
        }
    }
    

    
    async function updateSenha(PerfilSenha: PerfilSenha) {
        try {
            setIsLoading(false);
    
            const response = await api.put('/filial/usuario/senha', PerfilSenha); 

            Alert.success(response.data.message);

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false);
        }
    }


    return (
       <PerfilContext.Provider value={{ perfil, isLoading, editPerfil,  updateAvatar, updateSenha }}>
            {children} 
        </PerfilContext.Provider>
    )

}


export function usePerfil() {
    const context = useContext(PerfilContext);

    return context;
}
