import {Dashboard} from "../components/Dashboard";
import {Filial} from "../pages/Filial";
import {CaixaLoja} from "../pages/CaixaLoja";
import {Produto} from "../pages/Produto";
import {Perfil} from "../pages/Perfil";
import {CategoriaLoja} from "../pages/CategoriaLoja";
import {Banner} from "../pages/Banner";
import {Loja} from "../pages/Loja";
import {Pedido} from "../pages/Pedido";
import {UsuarioFilial} from "../pages/UsuarioFilial";
import {VisualizarLoja} from "../pages/VisualizarLoja";
import {NovaLoja} from "../pages/NovaLoja";
import {NovoProduto} from "../pages/NovoProduto";
import {VisualizarProduto} from "../pages/VisualizarProduto";
import {SVGIcon} from "rsuite/lib/@types/common";
import {IconNames} from "rsuite/lib/Icon/Icon";

interface IRoute {
    path: string;
    title: string;
    show?: boolean,
    icon: IconNames | SVGIcon;
    component: any,
    permissao: any,
    active: any,
    subRoutes?: IRoute[],
}

export const routes: IRoute[] = [
    {
        path: "/",
        title: "Dashboard",
        show: true,
        icon: "dashboard",
        permissao: [1,2,3,5,4], 
        active: '1',
        component: Dashboard
    },
    {
        path: "/filial",
        title: "Filial",
        show: true,
        icon: "building2",
        permissao: [1], 
        active: '2',
        component: Filial,
    },
/*     {
        path: "/usuario",
        title: "Usuários",
        show: true,
        icon: "user-plus",
        permissao: [2,3],
        active: '3',
        component: UsuarioFilial,
    }, */
    {
        path: "/filial/usuarios",
        title: "Funcionários",
        show: true,
        icon: "cart-plus",
        permissao: [1,2,3], 
        active: '7',
        component: UsuarioFilial,
    },
    {
        path: "/categoria",
        title: "Categoria",
        show: true,
        icon: "tags",
        permissao: [1], 
        active: '4',
        component: CategoriaLoja,
    },
    {
        path: "/banner",
        title: "Banner",
        show: true,
        icon: "image",
        permissao: [1,2], 
        active: '4',
        component: Banner,
    },
    {
        path: "/loja",
        title: "Loja",
        show: true,
        icon: "cart-plus",
        permissao: [2,1,4], 
        active: '5',
        component: Loja,
    },
    {
        path: "/produto",
        title: "Produto",
        show: true,
        icon: "building2",
        permissao: [3,5], 
        active: '6',
        component: Produto,
    },
    {
        path: "/novo-produto",
        title: "Produto",
        show: false,
        icon: "building2",
        permissao: [3,5], 
        active: '6',
        component: NovoProduto,
    },
    {
        path: "/produto/:id",
        title: "Produto",
        show: false,
        icon: "building2",
        permissao: [3,5], 
        active: '6',
        component: VisualizarProduto,
    },
    {
        path: "/caixa",
        title: "Caixa",
        show: true,
        icon: "money",
        permissao: [3], 
        active: '6',
        component: CaixaLoja,
    },
    {
        path: "/pedido",
        title: "Pedido",
        show: true,
        icon: "bell",
        permissao: [3,5], 
        active: '6',
        component: Pedido,
    },
    {
        path: "/loja/:id",
        title: "Loja",
        show: false,
        icon: "cart-plus",
        permissao: [1,2,4], 
        active: '5',
        component: VisualizarLoja,
    },
    {
        path: "/nova-loja",
        title: "Loja",
        show: false,
        icon: "cart-plus",
        permissao: [1,2,4], 
        active: '5',
        component: NovaLoja,
    },
    {
        path: "/perfil",
        title: "Perfil",
        show: true,
        icon: "user",
        permissao: [1,2,3,4,5], 
        active: '6',
        component: Perfil,
    },
];
