import * as S from "./styles";
import { ProdutoProvider } from '../../hooks/useProduto'
import { CategoriaProvider } from '../../hooks/useCategoria'
import Admin from '../../components/Layouts/admin';
import { VerProduto } from "../../components/VerProduto";

export function VisualizarProduto() {   

    return (
        <ProdutoProvider>
            <CategoriaProvider>
                <Admin pageTitle={'Visualizar Produto'} > 
                    <S.Container>
                        <VerProduto />
                    </S.Container>
                </Admin>    
            </CategoriaProvider>
        </ProdutoProvider>
    );
}
