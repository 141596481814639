import { FormEvent, useState   } from 'react'
import Modal from 'react-modal'
import { useFilial } from "../../hooks/useFilial"
import InputMask from "react-input-mask";

import { MdClose } from "react-icons/md"
import * as S from "./styles"
import apiCep from "../../services/apiCep"

import {FormGroup, InputGroup, Icon} from 'rsuite';
import {Loading} from '../Loading';


interface EditFilialModalProps {
    isOpenEdit: boolean;
    onRequestCloseEdit: () => void;
}

export function EditFilialModal({isOpenEdit, onRequestCloseEdit}: EditFilialModalProps) {
    const { filialPorUUID, isLoading, editFilial, activePage } = useFilial();
    
    let logoAtual = filialPorUUID[0].logo;
    const [logo, setLogo] = useState(filialPorUUID[0].logo || '');
    const [logo_upload, setLogoUpload] = useState(filialPorUUID[0].logo || '');
    const [razao_social, setRazaoSocial] = useState(filialPorUUID[0].razao_social || '');
    const [cnpj, setCNPJ] = useState(filialPorUUID[0].cnpj.br || '');
    const [nome_fantasia, setNomeFantasia] = useState(filialPorUUID[0].nome_fantasia || '');
    const [cep, setCEP] = useState(filialPorUUID[0].cep.br || '');
    const [logradouro, setLogradouro] = useState(filialPorUUID[0].logradouro || '');
    const [complemento, setComplemento] = useState(filialPorUUID[0].complemento || '');
    const [celular, setCelular] = useState(filialPorUUID[0].celular?.br || '');
    const [telefone, setTelefone] = useState(filialPorUUID[0].telefone?.br|| '');
    const [numero, setNumero] = useState(filialPorUUID[0].numero || '');
    const [bairro, setBairro] = useState(filialPorUUID[0].bairro || '');
    const [cidade, setCidade] = useState(filialPorUUID[0].cidade || '');
    const [estado, setEstado] = useState(filialPorUUID[0].estado || '');
    const [comissao, setComissao] = useState(filialPorUUID[0].comissao || '');
    const [comissao_loja, setComissaoLoja] = useState(filialPorUUID[0].comissao_loja || '');
    const [status, setStatus] = useState(String(filialPorUUID[0].status ) || '');

     async function handleEditFilial(event: FormEvent){
        event.preventDefault();
        let uuid = filialPorUUID[0].uuid ? filialPorUUID[0].uuid : '';
        let verificaLogo = logoAtual !== logo_upload ? logo_upload : '';

        const data = {
            logo: verificaLogo,
            uuid: uuid as any,
            razao_social,
            nome_fantasia,
            cnpj,
            cep,
            logradouro,
            complemento,
            numero,
            bairro,
            cidade,
            estado,
            comissao,
            comissao_loja,
            celular,
            telefone,
            status,
            responsavel: filialPorUUID[0]?.usuario?.nome,
            usuario: ''
        };

     

        await editFilial(data, onRequestCloseEdit, filialPorUUID[0].uuid, activePage );
      
    }
    
	/**
	 * Pesquisar cep
	 */

     async function pesquisacep(valor:any) {
		var cep = valor.replace(/\D/g, "");

		if (cep !== "") {
			var validacep = /^[0-9]{8}$/;
			if (validacep.test(cep)) {
				await apiCep
					.get(`/${cep}/json`)
					.then((response) => {
						const {
							bairro,
							complemento,
							localidade,
							logradouro,
							uf,
						} = response.data;
						setLogradouro(logradouro);
						setBairro(bairro);
						setComplemento(complemento);
						setCidade(localidade);
						setEstado(uf);
					})
					.catch((error) => console.log(error.response));
			}
		}
	}
    
	const estados = {
		Estado: "Estado",
		AC: "Acre",
		AL: "Alagoas",
		AP: "Amapá",
		AM: "Amazonas",
		BA: "Bahia",
		CE: "Ceará",
		DF: "Distrito Federal",
		ES: "Espírito Santo",
		GO: "Goiás",
		MA: "Maranhão",
		MT: "Mato Grosso",
		MS: "Mato Grosso do Sul",
		MG: "Minas Gerais",
		PA: "Pará",
		PB: "Paraíba",
		PR: "Paraná",
		PE: "Pernambuco",
		PI: "Piauí",
		RJ: "Rio de Janeiro",
		RN: "Rio Grande do Norte",
		RS: "Rio Grande do Sul",
		RO: "Rondônia",
		RR: "Roraima",
		SC: "Santa Catarina",
		SP: "São Paulo",
		SE: "Sergipe",
		TO: "Tocantins",
	};

	function options() {
		var es = Object.entries(estados);
		return es.map((item) => (
			<option key={`estado${item[0]}`} value={item[0]}>
				{item[1]}
			</option>
		));
	}


    const onImageChange = (event: any ) => {
        const { files } = event.target;
        setLogo(URL.createObjectURL(files[0]));
        setLogoUpload(files[0]);
        
    };

    const handlClearFoto = async () => {
        setLogo('');
        setLogoUpload('');
    };
    
    return (
        
        <Modal
            isOpen={isOpenEdit}
            onRequestClose={onRequestCloseEdit}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <button type="button" onClick={onRequestCloseEdit} className="react-modal-close">
                <MdClose />
            </button>
            <S.Container onSubmit={handleEditFilial}>
                <div>
                    {isLoading ? <Loading /> : ''}
                </div>
                <h2>Editar</h2>
                <S.ContainerCardForm>
                    <div className="card-form">
                        <FormGroup>
                            <S.FotoContainer>
                                {logo ? (
                                <>
                                <InputGroup className="close_search" label="Excluir logo">
                                    <InputGroup.Button onClick={() => handlClearFoto()}>
                                        <Icon icon="close" />
                                    </InputGroup.Button>
                                </InputGroup>
                                <img src={logo} alt="imagem"  width="300" height="300" 
                                onChange={onImageChange} />
                                </>
                                ) : (
                                <>
                                <Icon icon="file-image-o" size="4x" />
                                <input
                                    type="file"
                                    id="upload-button"
                                    onChange={onImageChange}
                                />
                                <label>Clique aqui para inserir uma imagem</label>
                                </>
                                )}
                            </S.FotoContainer>
                        </FormGroup>
                    </div>
                    <S.Row>
                    <div className="card-form">
                        <h3>Dados da empresa</h3>
                        <FormGroup>
                            <label>CNPJ:</label>
                            <InputMask 
                                type="text" 
                                mask="99.999.999/9999-99"
                                placeholder="CNPJ" 
                                value={cnpj}
                                onChange={event => setCNPJ(event.target.value)}
                            />
                        </FormGroup>
                         <FormGroup>
                             <label>Razão Social: </label>
                            <input 
                                type="text" 
                                placeholder="Razão social"
                                value={razao_social}
                                onChange={event => setRazaoSocial(event.target.value)}
                                
                            />
                         </FormGroup>
                         <FormGroup>
                             <label>Nome fantasia: </label>
                            <input 
                                type="text" 
                                placeholder="Nome fantasia"
                                value={nome_fantasia}
                                onChange={event => setNomeFantasia(event.target.value)}
                                
                            />
                         </FormGroup>
                        <FormGroup>
                            <label>Telefone:</label>
                            <InputMask 
                                type="text" 
                                mask="(99) 9999-9999"
                                placeholder="Telefone" 
                                onChange={event => setTelefone(event.target.value)}    
                                value={telefone} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Celular:</label>
                            <InputMask 
                                type="text" 
                                mask="(99) 99999-9999"
                                placeholder="Celular"  
                                value={celular}
                                onChange={event => setCelular(event.target.value)} 
                            />
                        </FormGroup>
                    </div>
                    <div className="card-form">
                        <h3>Endereço</h3>                        <FormGroup>
                            <label>CEP:</label>
                            <InputMask
                                mask="99.999-999"
                                value={cep}
                                placeholder="CEP"
                                type="text" 
                                onChange={(event) => {
                                    setCEP(event.target.value);
                                }}
                                onBlur={(event) =>
                                    pesquisacep(event.target.value)
                                }
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Logradouro:</label>
                            <input 
                                type="text" 
                                placeholder="Logradouro"    
                                value={logradouro}
                                onChange={event => setLogradouro(event.target.value)}
                                
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Complemento:</label>
                            <input 
                                type="text" 
                                placeholder="Complemento"    
                                value={complemento}
                                onChange={event => setComplemento(event.target.value)}
                                
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Número:</label>
                            <input 
                                type="text" 
                                placeholder="Número" 
                                value={numero}
                                onChange={event => setNumero(event.target.value)}
                                
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Bairro:</label>
                            <input 
                                type="text" 
                                placeholder="Bairro" 
                                value={bairro}
                                onChange={event => setBairro(event.target.value)}
                                
                        />
                        </FormGroup>
                        <FormGroup>
                            <label>Cidade:</label>
                            <input 
                                type="text" 
                                placeholder="Cidade" 
                                value={cidade}
                                onChange={event => setCidade(event.target.value)}
                                
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Estado:</label>
                            <select
                                placeholder="Estado"
                                onChange={(e) => {
                                    setEstado(e.target.value);
                                }}
                                value={estado}
                            >
                                {options()}
                        </select>
                        </FormGroup>
                    </div>
                    <div className="card-form">
                        <h3>Controle</h3>
                        <FormGroup>
                            <label>Comissão:</label>
                            <input 
                                type="number" 
                                min="1" 
                                max="100" 
                                placeholder="Comissão, Ex: 00.00" 
                                value={comissao}
                                onChange={event => setComissao(event.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Comissão da loja:</label>
                            <input  
                                type="number" 
                                min="1" 
                                max="100" 
                                placeholder="Comissão da loja, Ex: 00.00" 
                                value={comissao_loja}
                                onChange={event => setComissaoLoja(event.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Status:</label>
                            <S.StatusFilialContainer>
                                <S.RadioBox 
                                    type="button"
                                    onClick={() => setStatus(String(1))}
                                    isActive={status === '1'}
                                    activeColor="green"
                                    activeColorFonte="white"
                                >   
                                    <span>Ativo</span>
                                </S.RadioBox>
                                <S.RadioBox 
                                    type="button"
                                    onClick={() => setStatus(String(2))}
                                    isActive={status === '2'}
                                    activeColor="red"
                                    activeColorFonte="white"
                                >
                                    <span>Inativo</span>
                                </S.RadioBox>
                            </S.StatusFilialContainer>
                        </FormGroup>
                    </div>
                    </S.Row>
                </S.ContainerCardForm>
                
                <button type="submit">{isLoading ? 'Carregando...' : 'Atualizar'}</button>
            </S.Container>
        </Modal>
    )
}
