import { FormEvent, useState   } from 'react'
import Modal from 'react-modal'
import { useCategoria } from "../../hooks/useCategoria"

import { MdClose } from "react-icons/md"
import * as S from "./styles"

import {FormGroup} from 'rsuite';


interface SearchCategoriaModalProps {
    isOpenSearchCategoria: boolean;
    onRequestCloseSearchCategoria: () => void;
}



export function SearchCategoriaModal({isOpenSearchCategoria, onRequestCloseSearchCategoria}: SearchCategoriaModalProps) {
    const { isLoading, categoriaSearch } = useCategoria();
    
    const [searchCategoria, setSearchCategoria] = useState(''); 
    const [status, setStatus] = useState(''); 


    
    async function handleClickOpenSearchCategoria(event: FormEvent){
           event.preventDefault();
        const data = {
            nome: searchCategoria,
            status
        };

        await categoriaSearch(data, onRequestCloseSearchCategoria);
    };

    

    return (
        
        <Modal
            isOpen={isOpenSearchCategoria}
            onRequestClose={onRequestCloseSearchCategoria}
            overlayClassName="react-modal-overlay"
            className="react-modal-content-search"
        >
            <button type="button" onClick={onRequestCloseSearchCategoria} className="react-modal-close">
                <MdClose />
            </button>
            <S.Container onSubmit={handleClickOpenSearchCategoria}>
                <h2>Pesquisar</h2>
                <S.ContainerCardForm>
                    <div className="card-form">

                        <FormGroup>
                            <label>Digite um nome: </label>
                            <input 
                                type="text" 
                                placeholder="Nome"
                                value={searchCategoria}
                                onChange={event => setSearchCategoria(event.target.value)}
                                
                            /> 
                        </FormGroup>
                        <FormGroup>
                            <label>Status:</label>
                            <S.StatusFilialContainer>
                                <S.RadioBox 
                                    type="button"
                                    onClick={() => setStatus(String(1))}
                                    isActive={status === '1'}
                                    activeColor="green"
                                    activeColorFonte="white"
                                >   
                                    <span>Ativo</span>
                                </S.RadioBox>
                                <S.RadioBox 
                                    type="button"
                                    onClick={() => setStatus(String(2))}
                                    isActive={status === '2'}
                                    activeColor="red"
                                    activeColorFonte="white"
                                >
                                    <span>Inativo</span>
                                </S.RadioBox>
                            </S.StatusFilialContainer>
                        </FormGroup>
                     
                    </div>
                        
                </S.ContainerCardForm>
                
                <button type="submit">{isLoading ? 'Carregando...' : 'Buscar'}</button>
            </S.Container>
        </Modal>
    )
}
