import {createContext, useState, useEffect, ReactNode, useContext, useCallback } from 'react'
import api from "../services/api";
import { Alert } from 'rsuite';

interface Loja {
    id: number;
    uuid: string;
    url: string;
    usuario: any;
    produtos: any;
    observacao: string;
    valor_total: string;
    endereco: string;
    latitude: string;
    longitude: string;
    metodo_pagamento: string;
    metodo_entrega: string;
    data_criacao: any;
    data_atualizacao: any;
    status: any;
}


interface Pedido {
    id: number;
    uuid: string;
    url: string;
    usuario: any;
    observacao: string;
    valor_total: string;
    endereco: string;
    latitude: string;
    longitude: string;
    metodo_pagamento: string;
    metodo_entrega: string;
    data_criacao: any;
    data_atualizacao: any;
    status: any;
    loja: {
        uuid: string
    };
    produtos: any;
}

interface PedidoPagination {
    pagina_atual: any,
    proxima_pagina: any,
    pagina_anterior: any,
    ultima_pagina: any,
    total_itens: any
}

interface PedidoProviderProps {
    children: ReactNode; //pode retornar conteudo html, por exemplo
}

interface PedidoContextData {
    pedido: Loja[]; 
    pedidoPage: (pedido:any) => Promise<void>;
    pedidoVerPage: (uuid: any, pedido:any) => Promise<void>;
    pedidoSearch: (search:any, modal: any) => Promise<void>;
    detalhePedido: (uuid: any) => Promise<void>;   
    alteraPedido: (uuid:string, status: number, loja: string) => Promise<void>;
    isLoading: boolean; 
    isLoadingEdit: boolean; 
    isLoadingDetalhe: boolean; 
    pedidoPagination: PedidoPagination[]; 
    verpedidoPagination: PedidoPagination[]; 
    pedidoPorUUID: PedidoInputEdit[]; 
    detalhePedidoPorUUID: PedidoInputEdit[]; 
    isLoadingSearch: boolean;
    limpaSearchPedido: any;
}

// type PedidoInput = Omit<Pedido, 'id' | 'uuid' | 'data_criacao' | 'data_atualizacao'  > 

type PedidoInputEdit = Omit<Pedido, 'id' | 'data_atualizacao' | 'pagina_atual' | 'password' >

const PedidoContext = createContext<PedidoContextData>(
    {} as PedidoContextData //criando um Contexto, começa com um objeto vazio como default
);


export function PedidoProvider({children} : PedidoProviderProps) {
    const [pedido, setPedido] = useState<Loja[]>([]);
    const [pedidoPorUUID, setPedidoPorUUID] = useState<Pedido[]>([]);
    const [detalhePedidoPorUUID, setDetalhePedido] = useState<Pedido[]>([]);
    const [pedidoPagination, setPedidoPagination] = useState<PedidoPagination[]>([]);
    const [verpedidoPagination, setVerPedidoPagination] = useState<PedidoPagination[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingEdit, setIsLoadingEdit] = useState(true);
    const [isLoadingDetalhe, setIsLoadingDetalhe] = useState(true);
    const [isLoadingSearch, setIsLoadingSearch] = useState(true);
    var LCHAMA = localStorage.getItem('LCHAMA');

    const handlePedido = useCallback(async () => {
        const buscaAtiva = localStorage.getItem('salvarBuscaPedido');

        if(buscaAtiva && buscaAtiva !== ''){
            setIsLoading(true);
            let search = JSON.parse(buscaAtiva);
         
            if(LCHAMA !== undefined){
                try {
                    const response = await api.get('/pedido?loja='+LCHAMA+'&nome='+search.tipo+'&status='+search.status); 
        
                    setPedido(response.data.itens);
                    const data = [{
                            'pagina_atual' : response.data.pagina_atual,
                            'proxima_pagina' : response.data.proxima_pagina,
                            'pagina_anterior' : response.data.pagina_anterior,
                            'ultima_pagina' : response.data.ultima_pagina,
                            'total_itens' : response.data.total_itens
                        }
                    ];
            
                    setPedidoPagination(data);
                    setIsLoading(false);
        
                } catch (err: any) {
                    setIsLoading(false);
                }
            };
        }else{
            setIsLoading(true);

            if(LCHAMA !== undefined){
                try {
                    const response = await api.get('/pedido?loja='+LCHAMA);
                    
                    setPedido(response.data.itens);
                    const data = [{
                            'pagina_atual' : response.data.pagina_atual,
                            'proxima_pagina' : response.data.proxima_pagina,
                            'pagina_anterior' : response.data.pagina_anterior,
                            'ultima_pagina' : response.data.ultima_pagina,
                            'total_itens' : response.data.total_itens
                        }
                    ];
            
                    setPedidoPagination(data);
                    setIsLoading(false);
        
                } catch (err: any) {
                    setIsLoading(false);
                }
            };
            setIsLoading(false);
        }
  

      }, [LCHAMA]);

    useEffect(() => {
        handlePedido();
      }, [handlePedido]);

    // PEGA uuid e razao social

    async function pedidoPage(page : any) {
        try {

            const buscaAtiva = localStorage.getItem('salvarBuscaPedido');
            if(buscaAtiva && buscaAtiva !== ''){
                setIsLoading(true);

                let search = JSON.parse(buscaAtiva);
                const response = await api.get('/pedido?loja='+LCHAMA+'&nome='+search.tipo+'&status='+search.status+'?page='+page); 

                setPedido(response.data.itens);
                const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                setIsLoading(false);
                setPedidoPagination(data);
            }else{

                 setIsLoading(true);
            
                 const response = await api.get('/pedido?loja='+LCHAMA+'&page='+page);             

                setPedido(response.data.itens);
                const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                setIsLoading(false);
                setPedidoPagination(data);
            }

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }

    async function pedidoSearch(search:any, Modal: any) {
        try {
            setIsLoading(true);
            
            if(search.nome === '' && search.status === ''){
                handlePedido();
            } else {
                const response = await api.get('/pedido?loja='+LCHAMA+'&nome='+search.nome+'&status='+search.status); 

                if(response.data.error === true ){
                    Alert.error(response.data.message);

                }else {
                    localStorage.setItem('buscaAtivaPedido', 'ativa');
                    localStorage.setItem('salvarBuscaPedido', JSON.stringify(search));
                    
                    setPedidoPorUUID(response.data.itens);
                    const data = [{
                        'pagina_atual' : response.data.pagina_atual,
                        'proxima_pagina' : response.data.proxima_pagina,
                        'pagina_anterior' : response.data.pagina_anterior,
                        'ultima_pagina' : response.data.ultima_pagina,
                        'total_itens' : response.data.total_itens
                    }];

                    setPedidoPagination(data);
                }
            }
            
            setIsLoading(false);
            Modal();
            

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }
      



    async function detalhePedido(uuid: any) {
        try {
            setIsLoadingDetalhe(true);
                
            const responseedit = await api.get('/pedido/'+uuid);  
   
            setDetalhePedido([responseedit.data]);        
            setIsLoadingDetalhe(false);

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoadingDetalhe(false); 
        }
    }
    

    async function alteraPedido(uuid: string, status: number, loja: string) {
        try {
            setIsLoadingDetalhe(true);
            setIsLoading(true);
                
            const responseedit = await api.post('/pedido/atualizar/'+uuid, {"status": status});  
            
            Alert.success(responseedit.data.message);

            const response = await api.get('/pedido/'+uuid);  
            setDetalhePedido([response.data]);

            const responsePedido = await api.get('/pedido?loja='+loja);
            
            setPedido(responsePedido.data.itens);
         
            setIsLoadingDetalhe(false);
            setIsLoading(false);

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoadingDetalhe(false); 
        }
    }
    
    
    async function pedidoVerPage(uuid: any, page : any) {
        try {
            setIsLoadingEdit(false);
                
            const response = await api.get('/pedido?loja='+uuid+'&page='+page);  // PedidoInput são todos os inputs do form

            setPedido(response.data.itens);
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                }
            ];
            setVerPedidoPagination(data);

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoadingEdit(false); //pegando o response da requisição da api
        }
    }

    async function limpaSearchPedido() {
        try {
            setIsLoadingSearch(true);
            
            handlePedido();
            let keysToRemove = ["buscaAtivaPedido", "salvarBuscaPedido"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));
                window.location.reload();

            setIsLoadingSearch(false);

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoadingSearch(false);
        }
    }

    return (
        <PedidoContext.Provider value={{pedido,  isLoading, pedidoPorUUID,pedidoPagination, pedidoPage,  pedidoSearch,  isLoadingEdit, verpedidoPagination, pedidoVerPage, detalhePedidoPorUUID, detalhePedido, limpaSearchPedido, isLoadingSearch, isLoadingDetalhe, alteraPedido }}> {/* retornando as filiais como valor do contexto e a função que cria uma nova loja */}
            {children} {/* recebe um conteudo do componente filho que são o Header, Dashboard e NewPedidoModal */} 
        </PedidoContext.Provider>
    )

}


export function usePedido() {
    const context = useContext(PedidoContext);

    return context;
}
