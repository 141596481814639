import { LojaTable } from "../../components/LojaTable";
import * as S from "./styles";
import { LojaProvider } from '../../hooks/useLoja'
import { CategoriaProvider } from '../../hooks/useCategoria'
import { PerfilProvider } from '../../hooks/usePerfil'
import Admin from '../../components/Layouts/admin';


export function Loja() {
    
    const tipoUsuario = Number(localStorage.getItem('TCHAMA'));
  
    const tituloPagina = tipoUsuario === 1 ? 'Funcionários - Loja' : 'Loja';

    return (
        <LojaProvider>
            <PerfilProvider>
            <CategoriaProvider>
                <Admin pageTitle={tituloPagina} > 
                    <S.Container>
                        <LojaTable />
                    </S.Container>
                </Admin>    
            </CategoriaProvider>
            </PerfilProvider>
        </LojaProvider>
    );
}
