import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
        width: 100%;
        color: var(--text-title);
        font-size: 1.5rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid #d7d7d7;
    }
`

