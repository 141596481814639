import { useCaixaloja } from "../../hooks/useCaixaloja";
import * as S from "./styles";
import { Pagination } from 'rsuite';
import {Loading} from '../Loading'

import Skeleton from "react-loading-skeleton";


interface CaixaLojaTableProps {
    onOpenEditCaixaLojaModal: () => void;
}

export function CaixaLojaTable({
    onOpenEditCaixaLojaModal
}: CaixaLojaTableProps) {

    const { caixa, vercaixaPorUuid,  caixaPage, isLoading, caixaPagination } = useCaixaloja();


    const handleClickOpenEdit = async (url: any, page:any) => {
        await vercaixaPorUuid(url);
        onOpenEditCaixaLojaModal();
    };
  
    const handleSelect = async (eventKey: any) => {
        await caixaPage(eventKey);
    }


    return (
        <S.ContainerDuplo>
            {isLoading ?
            <div>
                 <Loading /> 
            </div>
            : ''}
            <div className="container">
                <div className="tabela">
                    <h1>Vendas</h1>
                    <table>
                        <thead>
                            <tr>
                                <th>Período</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {caixa ? caixa.map(caixa => (
                                <tr 
                                key={caixa.uuid}
                                >
                                    <td data-label="Período" onClick={() => handleClickOpenEdit(caixa.uuid, caixaPagination[0].pagina_atual)}>{isLoading ? <Skeleton /> : caixa.periodo}</td>
                                    <td data-label="Total" onClick={() => handleClickOpenEdit(caixa.uuid, caixaPagination[0].pagina_atual)}>{isLoading ? <Skeleton /> : 'R$ '+ caixa.total.br}</td>
                                </tr>
                            )) : null}
                        </tbody>
                    </table>
                </div>
                <div className="tabela">
                    <h1>Repasses</h1>
                    <table>
                        <thead>
                            <tr>
                                <th>Conta</th>
                                <th>Taxas</th>
                                <th className="status">Saques</th>
                            </tr>
                        </thead>
                        <tbody>
                            {caixa ? caixa.map(caixa => (
                                <tr 
                                key={caixa.uuid}
                                >
                                    <td data-label="Conta" onClick={() => handleClickOpenEdit(caixa.uuid, caixaPagination[0].pagina_atual)}>{isLoading ? <Skeleton /> : 'R$ '+ caixa.total_conta.br}</td>
                                    <td data-label="Taxas" onClick={() => handleClickOpenEdit(caixa.uuid, caixaPagination[0].pagina_atual)}>{isLoading ? <Skeleton /> : 'R$ '+ caixa.taxas.br}</td>
                                    <td data-label="Saques" onClick={() => handleClickOpenEdit(caixa.uuid, caixaPagination[0].pagina_atual)} className={caixa.status === 1 ? 'cinza' : 'verde'}>
                                     <p>{isLoading ? <Skeleton /> : 'R$ '+ caixa.total_conta.br}</p>
                                    </td>
                                </tr>
                            )) : null}
                        </tbody>
                    </table>
                </div>

                <div className="tabela mobile">
                    <h1>Repasses</h1>
                    <table>
                        <thead>
                            <tr>
                                <th>Período</th>
                                <th>Total</th>
                                <th>Conta</th>
                                <th>Taxas</th>
                                <th className="status">Saques</th>
                            </tr>
                        </thead>
                        <tbody>
                            {caixa && caixa.map(caixa => (
                                <tr 
                                key={caixa.uuid}
                                >
                                    <td data-label="Período" onClick={() => handleClickOpenEdit(caixa.uuid, caixaPagination[0].pagina_atual)}>{isLoading ? <Skeleton /> : caixa.periodo}</td>
                                    <td data-label="Total" onClick={() => handleClickOpenEdit(caixa.uuid, caixaPagination[0].pagina_atual)}>{isLoading ? <Skeleton /> : 'R$ '+ caixa.total.br}</td>
                                    <td data-label="Conta" onClick={() => handleClickOpenEdit(caixa.uuid, caixaPagination[0].pagina_atual)}>{isLoading ? <Skeleton /> : 'R$ '+ caixa.total_conta.br}</td>
                                    <td data-label="Taxas" onClick={() => handleClickOpenEdit(caixa.uuid, caixaPagination[0].pagina_atual)}>{isLoading ? <Skeleton /> : 'R$ '+ caixa.taxas.br}</td>
                                    <td data-label="Saques" onClick={() => handleClickOpenEdit(caixa.uuid, caixaPagination[0].pagina_atual)} className={caixa.status === 1 ? 'cinza' : 'verde'}>
                                     <p>{isLoading ? <Skeleton /> : 'R$ '+ caixa.total_conta.br}</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <S.ContainerPagination>
            {caixaPagination.length ?
                <Pagination
                prev
                last
                next
                first
                size="lg"
                pages={caixaPagination[0].ultima_pagina}
                activePage={caixaPagination[0].pagina_atual}
                onSelect={handleSelect}
                />
                : null
            }
            </S.ContainerPagination>
        </S.ContainerDuplo>
    );
}
