import * as S from "./styles"
import { useProduto } from "../../hooks/useProduto"
import {Loading} from '../Loading'

import Skeleton from "react-loading-skeleton";
import { Pagination } from 'rsuite';


export function VerProdutoTable() {
    
    const { produtoPorUUID, detalheProduto, isLoading, verprodutoPagination, produtoVerPage } = useProduto();

    const handleClickOpenEdit = async (url: any, page: any) => {
        await detalheProduto(url, page);
    };
  
    const handleSelect = async (uuid: any, eventKey: any) => {
        await produtoVerPage(uuid, eventKey);
    }
    
    const handleClickNewProduto = async () => {
        setTimeout(() => {
            window.location.pathname = '/novo-produto';
        }, 1000);
    };

    return (
        <S.Container>
            {isLoading ?
            <div>
                 <Loading /> 
            </div>
            : 
            <>
                <button type="button" onClick={handleClickNewProduto}>
                    Novo Produto
                </button>
                <table>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Data de criação</th>
                            <th className="status">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? 
                            <tr>
                                <td>{isLoading ? <Skeleton /> : ''}</td>
                                <td>
                                    {isLoading ? <Skeleton /> : ''}
                                </td>
                                <td > 
                                    {isLoading ? <Skeleton /> : <div></div>}
                                </td>
                            </tr>
                         : 
                         <>
                         { produtoPorUUID && produtoPorUUID.map((lista, index) => (
                            <tr 
                                key={index}  
                                onClick={() => handleClickOpenEdit(lista.uuid, verprodutoPagination[0]?.pagina_atual)}
                            >
                                <td>{isLoading ? <Skeleton /> : lista.nome}</td>
                                <td>
                                    {isLoading ? <Skeleton /> : lista.data_criacao?.data}
                                </td>
                                <td className={lista.status === 1 ? 'ativo' : 'inativo'}> 
                                    {isLoading ? <Skeleton /> : <div></div>}
                                </td>
                            </tr>
                        ))}
                        </>
                        }
                        
                    </tbody>
                </table>
            </>
            }
            <S.ContainerPagination>
                {verprodutoPagination.length ?
                    <Pagination
                    prev
                    last
                    next
                    first
                    size="lg"
                    pages={verprodutoPagination[0].ultima_pagina}
                    activePage={verprodutoPagination[0].pagina_atual}
                    onSelect={handleSelect}
                    />
                    : null
                }
            </S.ContainerPagination>
        </S.Container>
    )   
}
