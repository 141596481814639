import {createContext, useState, useEffect, ReactNode, useContext, useCallback } from 'react'
import api from "../services/api";
import { Alert } from 'rsuite';

interface Caixa {
    id: number;
    uuid: string;
    periodo: string;
    total: {
        valor: string,
        br: string
    };
    taxas: {
        valor: string,
        br: string
    };
    total_conta: {
        valor: string,
        br: string
    };
    status: any;
}


interface Caixaloja {
    id: number;
    uuid: string;
    periodo: string;
    total: {
        valor: string,
        br: string
    };
    taxas: {
        valor: string,
        br: string
    };
    total_conta: {
        valor: string,
        br: string
    };
    status: any;
}


interface CaixalojaPagination {
	quantidade_pagina: number,
	pagina_atual: number,
	proxima_pagina: any,
	pagina_anterior: any,
	ultima_pagina: number,
	total: number

}

interface CaixalojaProviderProps {
    children: ReactNode; //pode retornar conteudo html, por exemplo
}

interface CaixalojaContextData {
    caixa: Caixa[]; 
    caixaPage: (produto:any) => Promise<void>;
    caixaSearch: (search:any, modal: any) => Promise<void>;
    vercaixaPorUuid: (uuid: any) => Promise<void>;
    isLoading: boolean; 
    isLoadingEdit: boolean; 
    caixaPagination: CaixalojaPagination[]; 
    caixaPorUUID: CaixalojaInputEdit[]; 
    isLoadingSearch: boolean;
    limpaSearchCaixaloja: any;
}

type CaixalojaInputEdit = Omit<Caixa, 'id' | 'data_criacao' | 'data_atualizacao' >

const CaixalojaContext = createContext<CaixalojaContextData>(
    {} as CaixalojaContextData //criando um Contexto, começa com um objeto vazio como default
);


export function CaixalojaProvider({children} : CaixalojaProviderProps) {
    const [caixa, setCaixaloja] = useState<Caixa[]>([]);
    const [caixaPorUUID, setCaixalojaPorUUID] = useState<Caixaloja[]>([]);
    const [caixaPagination, setCaixalojaPagination] = useState<CaixalojaPagination[]>([]);;
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingEdit, setIsLoadingEdit] = useState(true);
    const [isLoadingSearch, setIsLoadingSearch] = useState(true);
    var LCHAMA = localStorage.getItem('LCHAMA');

    const handleCaixaloja = useCallback(async () => {
        const buscaAtiva = localStorage.getItem('salvarBuscaCaixaloja');

        if(buscaAtiva && buscaAtiva !== ''){
            setIsLoading(true);
            let search = JSON.parse(buscaAtiva);
         
            if(LCHAMA !== undefined){
                try {
                    const response = await api.get('/caixa?loja='+LCHAMA+'&nome='+search.tipo+'&status='+search.status); 
        
                    setCaixaloja(response.data.itens);
                    const data = [{ 
                        'quantidade_pagina': response.data.quantidade_pagina,
                        'pagina_atual': response.data.pagina_atual,
                        'proxima_pagina': response.data.proxima_pagina,
                        'pagina_anterior': response.data.pagina_anterior,
                        'ultima_pagina': response.data.ultima_pagina,
                        'total': response.data.total
                        }
                    ];
            
                    setCaixalojaPagination(data);
                    setIsLoading(false);
        
                } catch (err: any) {

                    Alert.error(err.response.data.message);
                    setIsLoading(false);
                }
            };
        }else{
            setIsLoading(true);

            if(LCHAMA !== undefined){
                try {
                    const response = await api.get('/caixa?loja='+LCHAMA);
        
                    setCaixaloja(response.data.itens);
                    const data = [{
                        'quantidade_pagina': response.data.quantidade_pagina,
                        'pagina_atual': response.data.pagina_atual,
                        'proxima_pagina': response.data.proxima_pagina,
                        'pagina_anterior': response.data.pagina_anterior,
                        'ultima_pagina': response.data.ultima_pagina,
                        'total': response.data.total
                        }
                    ];
            
                    setCaixalojaPagination(data);
                    setIsLoading(false);
        
                } catch (err: any) {
                    Alert.error(err.response.data.message);
                    setIsLoading(false);
                }
            };
            setIsLoading(false);
        }
  

      }, [LCHAMA]);

    useEffect(() => {
        handleCaixaloja();
      }, [handleCaixaloja]);

    // PEGA uuid e razao social

    async function caixaPage(page : any) {
        try {

            const buscaAtiva = localStorage.getItem('salvarBuscaCaixaloja');
            if(buscaAtiva && buscaAtiva !== ''){
                setIsLoading(true);

                let search = JSON.parse(buscaAtiva);
                const response = await api.get('/caixa?loja='+LCHAMA+'&nome='+search.tipo+'&status='+search.status+'?page='+page); 

                setCaixaloja(response.data.itens);
                const data = [{
                        'quantidade_pagina': response.data.quantidade_pagina,
                        'pagina_atual': response.data.pagina_atual,
                        'proxima_pagina': response.data.proxima_pagina,
                        'pagina_anterior': response.data.pagina_anterior,
                        'ultima_pagina': response.data.ultima_pagina,
                        'total': response.data.total
                    }
                ];
                setIsLoading(false);
                setCaixalojaPagination(data);
            }else{

                 setIsLoading(true);
            
                 const response = await api.get('/caixa?loja='+LCHAMA+'&page='+page);             

                setCaixaloja(response.data.itens);
                const data = [{
                        'quantidade_pagina': response.data.quantidade_pagina,
                        'pagina_atual': response.data.pagina_atual,
                        'proxima_pagina': response.data.proxima_pagina,
                        'pagina_anterior': response.data.pagina_anterior,
                        'ultima_pagina': response.data.ultima_pagina,
                        'total': response.data.total
                    }
                ];
                setIsLoading(false);
                setCaixalojaPagination(data);
            }

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }

    async function caixaSearch(search:any, Modal: any) {
        try {
            setIsLoading(true);
            
            if(search.nome === '' && search.status === ''){
                handleCaixaloja();
            } else {
                const response = await api.get('/caixa?loja='+LCHAMA); 

                if(response.data.error === true ){
                    Alert.error(response.data.message);

                }else {
                    localStorage.setItem('buscaAtivaCaixaloja', 'ativa');
                    localStorage.setItem('salvarBuscaCaixaloja', JSON.stringify(search));
                    
                    setCaixalojaPorUUID(response.data);
                    const data = [{
                        'quantidade_pagina': response.data.quantidade_pagina,
                        'pagina_atual': response.data.pagina_atual,
                        'proxima_pagina': response.data.proxima_pagina,
                        'pagina_anterior': response.data.pagina_anterior,
                        'ultima_pagina': response.data.ultima_pagina,
                        'total': response.data.total
                    }];

                    setCaixalojaPagination(data);
                }
            }
            
            setIsLoading(false);
            Modal();
            

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }
      

    // POR UUID

    async function vercaixaPorUuid(uuid: any) {
        try {
            setIsLoadingEdit(true);
                
            const responseedit = await api.get('/caixa/'+uuid); 
            setCaixalojaPorUUID([responseedit.data]);
            const data = [{
                    'quantidade_pagina': responseedit.data.quantidade_pagina,
                    'pagina_atual': responseedit.data.pagina_atual,
                    'proxima_pagina': responseedit.data.proxima_pagina,
                    'pagina_anterior': responseedit.data.pagina_anterior,
                    'ultima_pagina': responseedit.data.ultima_pagina,
                    'total': responseedit.data.total
                }
            ];
            setCaixalojaPagination(data);
            setIsLoadingEdit(false);

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoadingEdit(false); 
        }
    }

    async function limpaSearchCaixaloja() {
        try {
            setIsLoadingSearch(true);
            
            handleCaixaloja();
            let keysToRemove = ["buscaAtivaCaixaloja", "salvarBuscaCaixaloja"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));
                window.location.reload();

            setIsLoadingSearch(false);

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoadingSearch(false);
        }
    }

    return (
        <CaixalojaContext.Provider value={{caixa, caixaPagination, caixaSearch, caixaPage, vercaixaPorUuid, caixaPorUUID, isLoadingEdit, isLoading, limpaSearchCaixaloja, isLoadingSearch  }}> 
            {children} 
        </CaixalojaContext.Provider>
    )

}


export function useCaixaloja() {
    const context = useContext(CaixalojaContext);

    return context;
}
