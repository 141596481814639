import React from "react";
import { isAuthenticated } from "../services/auth";
import { BrowserRouter, Route, Switch, Redirect, RouteProps, RouteComponentProps } from "react-router-dom";

// Páginas
import Login from "../pages/Login";
import NovaSenha from "../pages/NovaSenha";


import {Menu} from "./menu";

// Rotas privadas - Lógica 
export const PrivateRoute = ({ component, ...rest }: RouteProps) => {
    if (!component) {
        throw Error("Componente indefinido");
    }

    // Component em uppercase
    const Component = component;

    // Render - Se autenticado 
    const render = (props: RouteComponentProps<any>): React.ReactNode => {
        if (isAuthenticated()) {
            return (
                <div>
                    <Component {...props} />
                </div>
            )
        }

        // Se não estivar autenticado redireciona para o Login
        return (<Redirect to={{ pathname: "/login", state: { from: props.location } }} />)
    };

    // Retorno
    return (<Route {...rest} render={render} />);
}

// Rotas
const Routes = () => (
    <BrowserRouter>
        <Switch>
            {/* Login */}
            <Route path="/login" component={Login} />
            <Route path="/nova-senha/:token" component={NovaSenha} />

                <PrivateRoute  component={Menu} />
            <Route path="*" component={() => <h1>Page not found</h1>} />
        </Switch>
    </BrowserRouter>
);

export default Routes;
