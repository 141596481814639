import {createContext, useState, useEffect, ReactNode, useContext, useCallback } from 'react'
import api from "../services/api";
import { Alert } from 'rsuite';
import { v4 as uuidv4 } from 'uuid';


interface Loja {
    id: number;
    uuid: string;
    url: string;
    logo: any;
    nome: any;
    razao_social: string;
    responsavel: string;
    filial: any;
    cnpj: any;
    email: string;
    telefone: any;
    celular: any;
    cep: any;
    logradouro: string;
    numero: string;
    complemento: string;
    bairro: string;
    cidade: string;
    estado: string;
    categoria: any;
    latitude: any;
    longitude: any;
    raio: any;
    password: any;
    data_criacao: any;
    data_atualizacao: any;
    login: any;
    cpf: any;
    nascimento: any;
    usuario: any;
    status: any;
}


interface LojaPagination {
    pagina_atual: any,
    proxima_pagina: any,
    pagina_anterior: any,
    ultima_pagina: any,
    total_itens: any
}

interface LojaNomeUUID {
    uuid:  any,
    razao_social: any, 
}

interface LojaProviderProps {
    children: ReactNode; //pode retornar conteudo html, por exemplo
}

interface LojaContextData {
    loja: Loja[]; //um array de objetos do tipo Loja
    lojaPage: (loja:any) => Promise<void>;
    lojaUuidNome: () => void;
    lojaSearch: (loja:any, modal: any) => Promise<void>;
    createLoja: (loja: LojaInput) => Promise<void>; 
    getlojaPorUuid: (url: any, page:any) => Promise<void>; 
    verlojaPorUuid: (url: any) => Promise<void>; 
    editLoja: (loja: LojaInputEdit, uuid:any, page:any) => Promise<void>;
    lojaDelete: (uuid:any, usuario:any, modal: any) => Promise<void>;
    activePage: string;
    isLoading: boolean; 
    isLoadingEdit: boolean; 
    isLoadingSearch: boolean;
    lojaPagination: LojaPagination[];
    lojaPorUUID: LojaInputEdit[];
    lojaNomeUUID: LojaNomeUUID[];
    limpaSearchLoja: any;
}

type LojaInput = Omit<Loja, 'id' | 'url' | 'uuid' | 'data_criacao' | 'data_atualizacao' | 'usuario'  > 

type LojaInputEdit = Omit<Loja, 'id' | 'url' | 'data_criacao' | 'data_atualizacao' | 'pagina_atual' | 'nascimento'  | 'cpf'  | 'login' | 'data_nascimento' | 'password'  >

const LojaContext = createContext<LojaContextData>(
    {} as LojaContextData //criando um Contexto, começa com um objeto vazio como default
);


export function LojaProvider({children} : LojaProviderProps) {
    const [loja, setLoja] = useState<Loja[]>([]);
    const [lojaPorUUID, setLojaPorUUID] = useState<Loja[]>([]);
    const [lojaPagination, setLojaPagination] = useState<LojaPagination[]>([]);
    const [lojaNomeUUID, setLojaNomeUUID] = useState<LojaNomeUUID[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingEdit, setIsLoadingEdit] = useState(true);
    const [isLoadingSearch, setIsLoadingSearch] = useState(true);
    const [activePage, setPageActive] = useState('');
    
    const handleLoja = useCallback(async () => {
        
        const buscaAtiva = localStorage.getItem('salvarBuscaLoja');

        if(buscaAtiva && buscaAtiva !== ''){
            setIsLoading(true);
            let search = JSON.parse(buscaAtiva);
            const response = await api.get('/loja?nome='+search); 
            setLoja(response.data.itens);
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                }
            ];
            setLojaPagination(data);
            setIsLoading(false);
        }else{
            setIsLoading(true);
            const response = await api.get('/loja');
            setLoja(response.data.itens);
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                }
            ];
            setLojaPagination(data);
            setIsLoading(false);
        }
      
      }, []);

    useEffect(() => {
        handleLoja();
      }, [handleLoja]);

    // FILIAL POR UUID

    async function lojaUuidNome() {
        try {
            setIsLoading(false);
                
            const response = await api.get('/loja'); 

            const data = [{
                    'uuid' : response.data.uuid,
                    'razao_social' : response.data.razao_social,
                }
            ];
            setLojaNomeUUID(data);

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); 
        }
    }

    // PEGA uuid e razao social

    async function lojaPage(page : any) {
        try {
            const buscaAtiva = localStorage.getItem('salvarBuscaLoja');
            if(buscaAtiva && buscaAtiva !== ''){
                setIsLoading(true);

                let search = JSON.parse(buscaAtiva);
                const response = await api.get('/loja?nome='+search.nome+'&page='+page);

                setLoja(response.data.itens);
                const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                setIsLoading(false);
                setLojaPagination(data);
            }else{

                 setIsLoading(true);
            
                const response = await api.get('/loja?page='+page);             

                setLoja(response.data.itens);
                const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                setIsLoading(false);
                setLojaPagination(data);
            }

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); 
        }
    }

    async function lojaSearch(search : any, Modal: any) {
        try {  
            
            setIsLoadingSearch(true);
            
            if(search.nome === ''){
                handleLoja();
            } else {
                 const response = await api.get('/loja?nome='+search.nome); 

                 if(response.data.error === true ){
                    Alert.error(response.data.message);

                 }else {
                    localStorage.setItem('buscaAtivaLoja', 'ativa');
                    localStorage.setItem('salvarBuscaLoja', JSON.stringify(search));
                    
                    setLoja(response.data.itens);
                    const data = [{
                            'pagina_atual' : response.data.pagina_atual,
                            'proxima_pagina' : response.data.proxima_pagina,
                            'pagina_anterior' : response.data.pagina_anterior,
                            'ultima_pagina' : response.data.ultima_pagina,
                            'total_itens' : response.data.total_itens
                        }
                    ];
                    setLojaPagination(data);
                 }
            }
            
            setIsLoadingSearch(false);
            Modal();
            

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoading(false); 
        }
    }

      

    // FILIAL POR UUID

    async function getlojaPorUuid(url : any, page:any) {
        try {
            setIsLoading(false);
                
            const responseedit = await api.get('/loja/'+url);  

            // lojaPorUUID.push(responseedit.data);
            setLojaPorUUID([responseedit.data]);
            setPageActive(page);
            setTimeout(() => {
                window.location.pathname = '/loja/'+url;
            }, 1000);
          

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); 
        }
    }



    async function verlojaPorUuid(url: any) {
        try {
            setIsLoadingEdit(true);
                
            const responseedit = await api.get('/loja/'+url);  
            // lojaPorUUID.push(responseedit.data);
            setLojaPorUUID([responseedit.data]);
           
            setIsLoadingEdit(false);

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoadingEdit(false); 
        }
    }
    //   CRIA FILIAL
    
    async function createLoja(LojaInput: LojaInput) {
        let uuid = uuidv4();
        try {
            setIsLoading(false);
            const dataUsuario = new FormData();

            const data = new FormData();
            data.append("uuid", uuid);
            data.append("logo", LojaInput.logo);
            data.append("nome", LojaInput.nome);
            data.append("razao_social", LojaInput.razao_social);
            data.append("email", LojaInput.email);
            data.append("cnpj", LojaInput.cnpj);
            data.append("telefone", LojaInput.telefone);
            data.append("celular", LojaInput.celular);
            data.append("responsavel", LojaInput.responsavel);
            data.append("cep", LojaInput.cep);
            data.append("logradouro", LojaInput.logradouro);
            data.append("complemento", LojaInput.complemento);
            data.append("numero", LojaInput.numero);
            data.append("bairro", LojaInput.bairro);
            data.append("cidade", LojaInput.cidade);
            data.append("estado", LojaInput.estado);
            data.append("categoria", LojaInput.categoria);
            data.append("raio", LojaInput.raio);
            data.append("latitude", LojaInput.latitude);
            data.append("longitude", LojaInput.longitude);
            data.append("password", LojaInput.password);
            data.append("filial", LojaInput.filial);
            data.append("status", LojaInput.status as any);

            dataUsuario.append("filial", LojaInput.filial);
            dataUsuario.append("loja", uuid);
            dataUsuario.append("email", LojaInput.login);
            dataUsuario.append("cpf", LojaInput.cpf);
            dataUsuario.append("nascimento",  LojaInput.nascimento);
            dataUsuario.append("telefone", LojaInput.telefone);
            dataUsuario.append("celular", LojaInput.celular);
            dataUsuario.append("nome", LojaInput.nome);
            dataUsuario.append("password", LojaInput.password);
            dataUsuario.append("tipo", Number(3) as any);
            dataUsuario.append("status", LojaInput.status as any);

            const response = await api.post('/loja', data); 
            
            if(response.data.message === 'Loja criada com sucesso!'){
                 const responseUsuario = await api.post('/filial/usuario', dataUsuario);
                 if(responseUsuario.data.error === false){
            
                        let keysToRemove = ["buscaAtivaLoja", "salvarBuscaLoja"];

                        keysToRemove.forEach(k =>
                            localStorage.removeItem(k));
                            Alert.success("Loja cadastrada com sucesso");;
                        
                        setTimeout(() => {
                            window.location.pathname = '/loja';
                        }, 1000);
                        handleLoja();
                 }else{
                    Alert.error('Você está tentando cadastrar um usuário com um CPF existente, por favor tente com outro CPF');
                    setIsLoadingEdit(false);
                 }
            }else if(!response || !response.data.message){
                    await api.delete('/usuario/'+uuid);
                    Alert.error('Você está tentando cadastrar uma loja com um CNPJ já existente, por favor tente com outro CNPJ');
            }

        } catch (err: any) {
            if(err.response.status !== 200){
                Alert.error('Você está tentando cadastrar a loja ou usuário com dados já existentes no banco de dados, por favor verifique o CPF, CNPJ ou email.');
                await api.delete('/loja/'+uuid);
                await api.delete('/usuario/'+uuid);
            }
            setIsLoading(false); 
        }
    } 

    // EDITANDO AS Loja
    async function editLoja(LojaInputEdit: LojaInputEdit, uuid: any, page:any) {
        try {
            setIsLoadingEdit(true);

            const data = new FormData();
            if(LojaInputEdit.logo !== ''){
                data.append("logo", LojaInputEdit.logo);
            }
            data.append("nome", LojaInputEdit.nome);
            data.append("razao_social", LojaInputEdit.razao_social);
            data.append("email", LojaInputEdit.email);
            data.append("cnpj", LojaInputEdit.cnpj);
            data.append("responsavel", LojaInputEdit.responsavel);
            data.append("cep", LojaInputEdit.cep);
            data.append("logradouro", LojaInputEdit.logradouro);
            data.append("complemento", LojaInputEdit.complemento);
            data.append("numero", LojaInputEdit.numero);
            data.append("bairro", LojaInputEdit.bairro);
            data.append("cidade", LojaInputEdit.cidade);
            data.append("estado", LojaInputEdit.estado);
            data.append("categoria", LojaInputEdit.categoria);
            data.append("raio", LojaInputEdit.raio);
            data.append("latitude", LojaInputEdit.latitude);
            data.append("longitude", LojaInputEdit.longitude);
            data.append("filial", LojaInputEdit.filial);
            data.append("status", LojaInputEdit.status as any);

         
            const response = await api.post('/loja/atualizar/'+uuid, data);  

            const buscaAtiva = localStorage.getItem('salvarBuscaLoja');
            if(buscaAtiva && buscaAtiva !== ''){

                let search = JSON.parse(buscaAtiva);
                const responsepage = await api.get('/loja?nome='+search.nome+'&page='+page);
                
                setLoja(responsepage.data.itens);
                const datapage = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                
                setLojaPagination(datapage);
    
                setIsLoadingEdit(false);
    
            }else{
                let keysToRemove = ["buscaAtivaLoja", "salvarBuscaLoja"];
    
                keysToRemove.forEach(k =>
                    localStorage.removeItem(k));
                    
                const responsepage = await api.get('/loja?page='+page);    
                setLoja(responsepage.data.itens);
                const datapage = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                setLojaPagination(datapage);
        
                setIsLoadingEdit(false);
            }
           
            setIsLoadingEdit(false);
            // handleLoja();
            // Alert.success(response.data.message);

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoadingEdit(false);
        }
    } 
    
    async function limpaSearchLoja() {
        try {
            setIsLoadingSearch(true);
            
            handleLoja();
            let keysToRemove = ["buscaAtivaLoja", "salvarBuscaLoja"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));
                window.location.reload();

            setIsLoadingSearch(false);

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoadingSearch(false);
        }
    }
 

    async function lojaDelete(uuid : any, usuario: any, Modal: any) {
        try {
            setIsLoading(false);
        
            if(uuid === undefined){
                handleLoja();
            } else {
                await api.delete('/filial/usuario/'+usuario);
                 const response = await api.delete('/loja/'+uuid);  
                    
                 if(response.data.error === true ){
                    Alert.error(response.data.message);

                 }else {
                    setLoja(response.data.itens);
                    const datapage = [{
                        'pagina_atual' : response.data.pagina_atual,
                        'proxima_pagina' : response.data.proxima_pagina,
                        'pagina_anterior' : response.data.pagina_anterior,
                        'ultima_pagina' : response.data.ultima_pagina,
                        'total_itens' : response.data.total_itens
                        }
                    ];
                    setLojaPagination(datapage);
                 }
            }
            
            handleLoja();
            Modal();
            

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }
    

    return (
        <LojaContext.Provider value={{loja, createLoja, isLoading, getlojaPorUuid, lojaPorUUID, editLoja, lojaPagination, lojaPage, lojaUuidNome, lojaSearch, lojaNomeUUID, verlojaPorUuid, isLoadingEdit, limpaSearchLoja, isLoadingSearch, activePage, lojaDelete }}>
            {children} 
        </LojaContext.Provider>
    )

}


export function useLoja() {
    const context = useContext(LojaContext);

    return context;
}
