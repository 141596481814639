import styled from 'styled-components'


export const Container = styled.form`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 1rem 1rem;

    h2 {
        width: 100%;
        color: var(--text-title);
        font-size: 1.5rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid #d7d7d7;
        padding-left: 20px;
        padding-top: 20px;
    }

    input[type=text],
    input[type=email] ,
    input[type=number],
    input[type=password],
    select  {
        width: 100%;
        padding: 0 1.5rem;
        height: 4rem;
        border-radius: 0.25rem;
        border: 1px solid #d7d7d7;
        background: #e7e9ee;

        font-weight: 400;
        font-size: 1rem;

        &::placeholder{
            color: var(--text-body);
        }
        //todo input que tiver um input acima vai receber margin top
        & + input {
            margin-top: 0.25rem;
        }

    }

    button[type=submit] {
        width: 280px;
        padding: 0 1.5rem;
        height: 4rem;
        background: var(--green);
        color: #FFFFFF;
        border-radius: 0.25rem;
        border: 0;
        font-size: 1rem;
        margin-top: 1.8rem;
        font-weight: 600;
        margin-left: 20px;

        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.9);
        }
    }
`


export const FormFoto = styled.form`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    .rs-btn-toolbar{
        margin-top: 20px;
    }
`

export const ContainerSenha = styled.form`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 1rem 1rem;

    h2 {
        width: 100%;
        color: var(--text-title);
        font-size: 1.5rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid #d7d7d7;
        padding-left: 20px;
        padding-top: 20px;
    }

    input[type=password],
    select  {
        width: 100%;
        padding: 0 1.5rem;
        height: 4rem;
        border-radius: 0.25rem;
        border: 1px solid #d7d7d7;
        background: #e7e9ee;

        font-weight: 400;
        font-size: 1rem;

        &::placeholder{
            color: var(--text-body);
        }
        //todo input que tiver um input acima vai receber margin top
        & + input {
            margin-top: 0.25rem;
        }

    }

    button[type=submit] {
        width: 280px;
        padding: 0 1.5rem;
        height: 4rem;
        background: var(--green);
        color: #FFFFFF;
        border-radius: 0.25rem;
        border: 0;
        font-size: 1rem;
        margin-top: 1.8rem;
        font-weight: 600;
        margin-left: 20px;

        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.9);
        }
    }
`

export const ContainerCardForm = styled.div`
    width: 100%;
    max-width: 500px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    @media (max-width: 768px) {
        flex-direction: column;
    }

    .card-form {
        display: grid;
        flex: 1;
        gap: 2rem;
        margin: 0px 1rem;
        @media (max-width: 768px) {
            width: 100%;
            margin: 0px;
            h3{
                margin-top: 40px;
            }
        }

        .rs-uploader-picture .rs-uploader-trigger-btn {
            width: 150px;
            height: 150px;
        }
        .rs-uploader-picture .rs-uploader-trigger-btn {
            border-radius: 50%;
            border: solid 2px #CCC;
        }
        .rs-uploader-trigger-btn {
            img {
                object-fit: cover;
            }
        }
        .rs-icon-size-5x,
        .rs-icon-size-5x.rs-icon {
            font-size: 150px;
            height: 0px;
            margin-left: -2px;
            margin-top: -17px;
        }
        .rs-icon-size-5x, .cYHYoN .card-form .rs-icon-size-5x.rs-icon{
            margin-top: 0px !important;
        }
	

    }

`

export const ButtonImage = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: solid 2px #CCC;

    img {
        object-fit: cover;
    }
`

export const FotoContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    label{
        margin-top: 10px;
        margin-bottom:0px;
    }
    input{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 0px;
        opacity: 0;
        cursor: pointer;
        z-index: 1000;
    }

`
