import Admin from '../Layouts/admin';


export function Dashboard() {
    

    return (
        <Admin pageTitle={'Dashboard'} >

        </Admin> 
    );
}
