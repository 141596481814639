import { FormEvent, useState   } from 'react'
import Modal from 'react-modal'
import { useUsuarioFilial } from "../../hooks/useUsuarioFilial"
import { useFilial } from "../../hooks/useFilial"
import { useLoja } from "../../hooks/useLoja";

import { MdClose } from "react-icons/md"
import * as S from "./styles"

import {FormGroup} from 'rsuite';


interface SearchUsuarioModalProps {
    isOpenSearchUsuario: boolean;
    onRequestCloseSearchUsuario: () => void;
}



export function SearchUsuarioModal({isOpenSearchUsuario, onRequestCloseSearchUsuario}: SearchUsuarioModalProps) {
    const { searchUsuario, isLoadingSearch } = useUsuarioFilial();
    const { loja } = useLoja();

    const { filial } = useFilial();
    const tipoUsuario = Number(localStorage.getItem('TCHAMA'));

    const [status, setStatus] = useState('');
    const [nome, setNome] = useState('');
    const [cpf, setCPF] = useState('');
    const [tipo, setTipo] = useState('');
    const [filiais, setFilial] = useState('');
    const [lojas, setLoja] = useState('');

    async function handlSearchUsuario(e: FormEvent) {
        e.preventDefault();

        let data = {
            nome,
            cpf,
            status,
            tipo,
            filial: tipoUsuario !== 1 ? '' : filiais,
            loja: tipoUsuario !== 1 ? '' : lojas
        }

        await searchUsuario(data, onRequestCloseSearchUsuario);

    } 
    

    const status_lista = {
        1: "Ativo",
        2: "Inativo"
    };

    function optionsStatus() {
        var es = Object.entries(status_lista);
        return es.map((item) => (
            <option key={`status${item[0]}`} value={item[0]}>
                {item[1]}
            </option>
        ));
    }


    return (
        
        <Modal
            isOpen={isOpenSearchUsuario}
            onRequestClose={onRequestCloseSearchUsuario}
            overlayClassName="react-modal-overlay"
            className="react-modal-content-search"
        >
            <button type="button" onClick={onRequestCloseSearchUsuario} className="react-modal-close">
                <MdClose />
            </button>
            <S.Container onSubmit={handlSearchUsuario}>
                <h2>Pesquisar</h2> <S.ContainerCardForm>
                        <div className="card-form">
                            <FormGroup>
                                <label>Nome</label>
                                <input
                                    type="text"
                                    placeholder="Nome"
                                    value={nome}
                                    onChange={event => setNome(event.target.value)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>CPF</label>
                                <input
                                    type="text"
                                    placeholder="CPF"
                                    value={cpf}
                                    onChange={event => setCPF(event.target.value)}
                                />
                            </FormGroup>
                            {tipoUsuario === 1 ?
                            <FormGroup>
                                <label>Filial: </label>
                                <select
                                    value={filiais}
                                    onChange={event => setFilial(event.target.value)}
                                    name="filial">
                                    <option value="">Selecione a Filial do usuário</option>
                                    {filial && filial.map(filial => (
                                    <option value={filial.uuid} key={filial.uuid}>
                                        {filial.razao_social}
                                    </option>
                                    ))}
                                </select>
                            </FormGroup>
                            : null }
                            
                            {tipoUsuario === 1 ?
                            <FormGroup>
                                <label>Loja: </label>
                                <select
                                    value={lojas}
                                    onChange={event => setLoja(event.target.value)}
                                    name="loja">
                                    <option value="">Selecione a loja do usuário</option>
                                    {loja && loja.map(loja => (
                                    <option value={loja.uuid} key={loja.uuid}>
                                        {loja.razao_social}
                                    </option>
                                    ))}
                                </select>
                            </FormGroup>
                            : null }
                            <FormGroup>
                            <label>Status:</label>
                                <select
                                    placeholder="Status"
                                    onChange={(e) => {
                                        setStatus(e.target.value);
                                    }}
                                    value={status}
                                >
                                    <option key="" value="">
                                        Selecione o Status
                                    </option>
                                    {optionsStatus()}
                                </select>
                            </FormGroup>
                            <FormGroup>
                            { tipoUsuario === 1 as any ? 
                                <>
                                <FormGroup>
                                    <label>Tipo: </label>
                                    <select
                                        value={tipo}
                                        onChange={event => setTipo(event.target.value)}
                                        name="tipo">
                                        <option value="">Selecione o Tipo do usuário</option>
                                        <option value="1">Administrador</option>
                                        <option value="2">Filial</option>
                                    </select>
                                </FormGroup> 
                                </>
                              : null}
                            </FormGroup>
                        </div>
                    </S.ContainerCardForm>
                
                <button type="submit">{isLoadingSearch ? 'Carregando...' : 'Buscar'}</button>
            </S.Container>
        </Modal>
    )
}
