import {createContext, useState, useEffect, ReactNode, useContext, useCallback } from 'react'
import api from "../services/api";
import {Alert} from 'rsuite';

interface Categoria {
    id: any,
    uuid: any,
    nome: any,
    imagem: any,
    data_criacao: any,
    status: any
}

interface CategoriaPagination {
    pagina_atual: any,
    proxima_pagina: any,
    pagina_anterior: any,
    ultima_pagina: any,
    total_itens: any
}

interface CategoriaProviderProps {
    children: ReactNode; //pode retornar conteudo html, por exemplo
}

interface CategoriaContextData {
    categoria: CategoriaInput[]; 
    isLoading: boolean;
    categoriaPage: (categoria:any) => Promise<void>;
    categoriaSearch: (categoria:any, modal: any) => Promise<void>;
    categoriaDelete: (uuid:any, modal: any) => Promise<void>;
    editCategoria: (categoria: CategoriaInputEdit, modal: any, uuid: any, page:any) => Promise<void>; 
    createCategoria: (categoria: CategoriaInputCriar, modal: any) => Promise<void>; 
    getcategoriaUuid: (uuid: any, page:any) => Promise<void>;
    activePage: string;
    categoriaPorUUID: CategoriaInputEdit[];
    categoriaPagination: CategoriaPagination[];
    isLoadingSearch: boolean;
    limpaSearchCategoria: any;
}

type CategoriaInput = Omit<Categoria, 'data_atualizacao' | 'data_senha'  | 'data_acesso' | 'imagem' | 'avatar'>

type CategoriaInputCriar = Omit<Categoria, 'id' | 'uuid' | 'data_atualizacao' | 'data_senha'  | 'data_acesso' | 'data_criacao' | 'avatar'>

type CategoriaInputEdit = Omit<Categoria, 'id' | 'data_atualizacao' | 'data_senha'  | 'data_acesso' | 'data_criacao' | 'avatar'>

const CategoriaContext = createContext<CategoriaContextData>(
    {} as CategoriaContextData //criando um Contexto, começa com um objeto vazio como default
);


export function CategoriaProvider({children} : CategoriaProviderProps) {
    const [categoria, setCategoria] = useState<Categoria[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [categoriaPagination, setCategoriaPagination] = useState<CategoriaPagination[]>([]);
    const [categoriaPorUUID, setCategoriaPorUUID] = useState<Categoria[]>([]);
    const [isLoadingSearch, setIsLoadingSearch] = useState(true);
    const [activePage, setPageActive] = useState('');

    // pega o categoria
    const handleCategoria = useCallback(async () => {

        
        const buscaAtiva = localStorage.getItem('salvarBuscaCategoria');

        if(buscaAtiva && buscaAtiva !== ''){
            setIsLoading(true);
            let search = JSON.parse(buscaAtiva);
            const response = await api.get('/categoria?nome='+search.nome+'&status='+search.status); 
            setCategoria(response.data.itens);
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                }
            ];
            setCategoriaPagination(data);
            setIsLoading(false);
        }else{
            setIsLoading(true);
            const response = await api.get('/categoria');
            setCategoria(response.data.itens);
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                }
            ];
            setCategoriaPagination(data);
            setIsLoading(false);
        }
      
 
    }, []);

    useEffect(() => {
        handleCategoria();
    }, [handleCategoria]);

    // Paginação
    async function categoriaPage(page : any) {
        try {
            
            const buscaAtiva = localStorage.getItem('salvarBuscaCategoria');
            if(buscaAtiva && buscaAtiva !== ''){
                setIsLoading(true);

                let search = JSON.parse(buscaAtiva);
                const response = await api.get('/categoria?nome='+search.nome+'&status='+search.status+'&page='+page);

                setCategoria(response.data.itens);
                const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                setIsLoading(false);
                setCategoriaPagination(data);
            }else{

                 setIsLoading(true);
            
                const response = await api.get('/categoria?page='+page);             

                setCategoria(response.data.itens);
                const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                setIsLoading(false);
                setCategoriaPagination(data);
            }

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }

    // CATEGORIA POR UUID

    async function getcategoriaUuid(uuid: any, page:any) {
        try {
            setIsLoading(false);
                
            const responseedit = await api.get('/categoria/'+uuid);   // FilialInput são todos os inputs do form

            setCategoriaPorUUID([responseedit.data]);
            setPageActive(page);

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }
    
    // CRIAR CATEGORIA
    
    async function createCategoria(CategoriaInputCriar: CategoriaInputCriar, Modal: any) {
        try {
            setIsLoading(false);
                
            const data = new FormData();
            data.append("imagem", CategoriaInputCriar.imagem);
            data.append("nome", CategoriaInputCriar.nome);
            data.append("status", CategoriaInputCriar.status as any);
    
            const response = await api.post('/categoria', data);  
            let keysToRemove = ["buscaAtivaCategoria", "salvarBuscaCategoria"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));

            Alert.success(response.data.message);
            handleCategoria();
            Modal();

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); 
        }
    } 

    

    async function categoriaSearch(search : any, Modal: any) {
        try {
            setIsLoading(false);

            if(search.nome === undefined && search.status === ''){
                handleCategoria();
            } else {
                 const response = await api.get('/categoria?nome='+search.nome+'&status='+search.status); 
                 if(response.data.error === true ){
                    Alert.error(response.data.message);

                 }else {

                    localStorage.setItem('buscaAtivaCategoria', 'ativa');
                    localStorage.setItem('salvarBuscaCategoria', JSON.stringify(search));

                    setCategoria(response.data.itens);
                    const data = [{
                            'pagina_atual' : response.data.pagina_atual,
                            'proxima_pagina' : response.data.proxima_pagina,
                            'pagina_anterior' : response.data.pagina_anterior,
                            'ultima_pagina' : response.data.ultima_pagina,
                            'total_itens' : response.data.total_itens
                        }
                    ];
                    setCategoriaPagination(data);
                 }
            }
            
            Modal();
            

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }

    
    

    async function categoriaDelete(uuid : any, Modal: any) {
        try {
            setIsLoading(false);
        
            if(uuid === undefined){
                handleCategoria();
            } else {
                 const response = await api.delete('/categoria/'+uuid);  
                    
                 if(response.data.error === true ){
                    Alert.error(response.data.message);

                 }else {
                    setCategoria(response.data.itens);
                    const data = [{
                            'pagina_atual' : response.data.pagina_atual,
                            'proxima_pagina' : response.data.proxima_pagina,
                            'pagina_anterior' : response.data.pagina_anterior,
                            'ultima_pagina' : response.data.ultima_pagina,
                            'total_itens' : response.data.total_itens
                        }
                    ];
                    setCategoriaPagination(data);
                 }
            }
            
            handleCategoria();
            Modal();
            

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }
    
    async function editCategoria(CategoriaInputEdit: CategoriaInputEdit, Modal: any, uuid: any, page:any) {
        try {
            setIsLoading(false);
                
            const data = new FormData();
            data.append("imagem", CategoriaInputEdit.imagem);
            data.append("nome", CategoriaInputEdit.nome);
            data.append("status", CategoriaInputEdit.status as any);
    
            const response = await api.post('/categoria/atualizar/'+uuid, data);  // FilialInput são todos os inputs do form
            const buscaAtiva = localStorage.getItem('salvarBuscaCategoria');
            if(buscaAtiva && buscaAtiva !== ''){

                let search = JSON.parse(buscaAtiva);
                const responsepage = await api.get('/categoria?nome='+search.nome+'&page='+page+'&status='+search.status);
                
                setCategoria(responsepage.data.itens);
                const datapage = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                
                setCategoriaPagination(datapage);
    
                Alert.success(response.data.message);
                Modal();
                setIsLoading(false);
    
            }else{
                let keysToRemove = ["buscaAtivaFilial", "salvarBuscaFilial"];
    
                keysToRemove.forEach(k =>
                    localStorage.removeItem(k));
                    
                const responsepage = await api.get('/categoria?page='+page);    
                setCategoria(responsepage.data.itens);
                const datapage = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                setCategoriaPagination(datapage);
        
                Alert.success(response.data.message);
                Modal();
                setIsLoading(false);
            }

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    } 

    async function limpaSearchCategoria() {
        try {
            setIsLoadingSearch(true);
            
            handleCategoria();
            let keysToRemove = ["buscaAtivaCategoria", "salvarBuscaCategoria"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));
                window.location.reload();

            setIsLoadingSearch(false);

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoadingSearch(false);
        }
    }
 
    return (
       <CategoriaContext.Provider value={{ categoria, isLoading, createCategoria, getcategoriaUuid, categoriaPorUUID, editCategoria, categoriaSearch, categoriaPagination, categoriaPage, categoriaDelete, limpaSearchCategoria, isLoadingSearch, activePage }}>
            {children} 
        </CategoriaContext.Provider>
    )

}


export function useCategoria() {
    const context = useContext(CategoriaContext);

    return context;
}
