import React from "react";
import { isAuthenticated } from "../services/auth";
import { BrowserRouter, Route, Redirect, RouteProps, RouteComponentProps } from "react-router-dom";
import {routes} from "./constants";

// Rotas privadas - Lógica 
export const PrivateRoute = ({ component, ...rest }: RouteProps) => {
    if (!component) {
        throw Error("Componente indefinido");
    }

    // Component em uppercase
    const Component = component;

    // Render - Se autenticado 
    const render = (props: RouteComponentProps<any>): React.ReactNode => {
        if (isAuthenticated()) {
            return (
                <div>
                    <Component {...props} />
                </div>
            )
        }

        // Se não estivar autenticado redireciona para o Login
        return (<Redirect to={{ pathname: "/login", state: { from: props.location } }} />)
    };

    // Retorno
    return (<Route {...rest} render={render} />);
}



export function Menu() {
    const tipoUsuario = Number(localStorage.getItem('TCHAMA'));

    return (
        <BrowserRouter>
            {routes.map(item => {
                if(item.permissao.includes(tipoUsuario)){
                    return  <PrivateRoute key={item.path} exact path={item.path} component={item.component} />
                }
                return false;
            })}
        </BrowserRouter>
    );
}
