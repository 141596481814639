import { useUsuarioFilial } from "../../hooks/useUsuarioFilial"
import * as S from "./styles";
import Skeleton from "react-loading-skeleton";
import {useState} from 'react';
import {Loading} from '../Loading'
import { Pagination, IconButton, Icon, Modal, Button} from 'rsuite';

interface UsuarioFilialTableProps {
    onOpenNewUsuarioFilialModal: () => void;
    onOpenEditUsuarioFilialModal: () => void;
}


export function UsuarioFilialTable({
    onOpenNewUsuarioFilialModal, 
    onOpenEditUsuarioFilialModal
}:UsuarioFilialTableProps) {
    const { usuarioLista, isLoading, getUsuarioPorUuid, paginationUsuario, userPage, usuarioDelete} = useUsuarioFilial();
    const [deletar, setDeletar] = useState('');

    const tipoUsuario = Number(localStorage.getItem('TCHAMA'));

    const handleClickOpenEdit = async (uuid: any, page:any) => {
        await getUsuarioPorUuid(uuid, page);
        onOpenEditUsuarioFilialModal();
    };


    const handleSelect = async (eventKey:any) => {
        await userPage(eventKey);
    }

    // MODAL DELETE
    const [modalDelete, setModalDelete] = useState(false);

    function handleModalDeleteClose (){
        setModalDelete(false);
    }
    
    const handleModalshowDelete = async (uuid: any) => {
        setModalDelete(true);
        setDeletar(uuid);
    }

    const handleModalDeletar = async (uuid: any, modal: any) => {

        await usuarioDelete(uuid, modal);
    }


    return (
        <S.Container>
            {tipoUsuario !== 1 ?
            <S.ContainerButtonBusca>
                <button type="button" onClick={onOpenNewUsuarioFilialModal}>
                    Novo Usuário
                </button>
            </S.ContainerButtonBusca>
            : null}
            <div>
                {isLoading ? <Loading /> : ''}
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>CPF</th>
                        <th className="status">Status</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody>
                    {usuarioLista ? usuarioLista.map(lista => (
                        <tr 
                            key={lista.uuid}  
                           
                        >
                            <td  onClick={() => handleClickOpenEdit(lista.uuid,paginationUsuario[0].current_page)}>{isLoading ? <Skeleton /> : lista.nome}</td>
                            <td  onClick={() => handleClickOpenEdit(lista.uuid,paginationUsuario[0].current_page)}>
                                {isLoading ? <Skeleton /> : lista.cpf.br}
                            </td>
                            <td className={lista.status === 1 ? 'ativo' : 'inativo'}  onClick={() => handleClickOpenEdit(lista.uuid,paginationUsuario[0].current_page)}> 
                                {isLoading ? <Skeleton /> : <div></div>}
                            </td>
                            <td>
                                <IconButton size="lg" style={{padding: "0px", width: "42px", marginBottom: "0px"}} color="red" onClick={() => handleModalshowDelete(lista.uuid)} icon={<Icon icon="close" />} />
                            </td>
                        </tr>
                    )) : null}
                </tbody>
            </table>
            <S.ContainerPagination>
                {paginationUsuario.length ?
                <Pagination
                    prev
                    last
                    next
                    first
                    size="lg"
                    pages={paginationUsuario[0].last_page}
                    activePage={paginationUsuario[0].current_page}
                    onSelect={handleSelect}
                />
                : null}
            </S.ContainerPagination>

            <Modal backdrop="static" show={modalDelete} onHide={handleModalDeleteClose} size="xs">
                <Modal.Body>
                    <Icon
                    icon="remind"
                    style={{
                        color: '#990606',
                        fontSize: 24
                    }}
                    />
                    {'  '}
                    Tem certeza que deseja deletar esse usuário?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleModalDeletar(deletar, handleModalDeleteClose)} color="red">
                    Quero deletar
                    </Button>
                    <Button onClick={handleModalDeleteClose} appearance="subtle">
                    Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </S.Container>
    );
}
