import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const Centraliza = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`


export const Esquerdo = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-right: 1px solid #333;
    article {
        min-height: 90px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        background-color: #fff;
        padding: 20px;
        margin-bottom: 5px;
        cursor: pointer;
        .titulo {
            width: 100%;
            display: flex;
            justify-content: space-between;
            span{
                padding: 10px 10px;
                color: #FFF;
                border-radius: 20px;
                font-size: 0.8em;
                &.pagamento{
                    background-color: var(--info-color);
                }
                &.recebido{
                    background-color: var(--red);
                }
                &.preparando{
                    background-color: var(--warning-color);
                }
                &.entrega{
                    background-color: var(--purple);
                }
                &.entregue{
                    background-color: var(--success-color);
                }
                &.cancelado{
                    background-color: var(--brown);
                }
            }
        }
        
        &.pagamento{
            border-right: 5px solid  var(--info-color);
        }
        &.recebido{
            border-right: 5px solid var(--red);
        }
        &.preparando{
            border-right: 5px solid var(--warning-color);
        }
        &.entrega{
            border-right: 5px solid var(--purple);
        }
        &.entregue{
            border-right: 5px solid var(--success-color);
        }
        &.cancelado{
            border-right: 5px solid var(--brown);
        }
        &:hover,
        &.active{
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
            border-left: 1px solid  #999;
            border-top: 1px solid  #999;
            border-bottom: 1px solid  #999;
        }
    }
`
export const Direito = styled.div`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 20px;
    position: relative;
    .container{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
    }
    .pedido{
        height: 90%;
        margin-bottom: 10px;
        article {
            border-bottom: 1px solid #CCC;
            &:last-of-type {
                border-bottom: none;
            }
        }
        .produto {
            div {
                margin-left: 10px;
                border: 1px solid #eee;
                padding: 10px;
                & + div {
                    margin-top: 0.55rem;
                }
                p {
                    margin-bottom: 5px;
                    margin-left: 10px;
                }
                span {
                    color: #666;
                    font-weight: bold;
                    margin-top: 10px;
                }
                div {
                    border: none;
                    marging-left: 10px;
                    padding: 0px;
                    margin-top: 10px;
                    span {
                        padding: 2px 10px;
                    }
                    .itemPedido {
                        margin-bottom: 10px;
                        padding: 5px;
                        border-radius: 3px;
                        p {
                            margin-left: 10px;
                        }
                    }
                    table {
                        width: 100%;
                        border-spacing: 0 0.5rem;

                        th {
                            color: var(--text-body);
                            font-weight: 400;
                            text-align: left;
                            line-height: 1.2em;
                            background: var(--shape);
                            padding: 1rem;
                            border-bottom: 2px solid #333;
                        }

                        tr {
                            cursor: pointer;
                            border: 2px solid #eee;
                        }
                        
                        td {
                            padding: 1rem 2rem;
                            border: 0;
                            background: var(--shape);
                            font-weight: 400;
                            color: var(--text-body);
                            border-radius: 0.25rem;
                            border: 2px solid #ddd;

                            &:first-child(1){
                                border-left:none;
                                color: var(--text-title);
                            }

                        }

                    }
                }
            }
        }
    }
    .observacao{
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #CCC;
    }
    article {
        padding: 10px;
        margin-bottom: 5px;
        border-radius: 3px;
        h1 {
            padding-bottom: 2px;
        }
        &.cliente{
            padding: 10px;
        }
    }
    .botao {
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            width: 250px;
            height: 50px;
            color: #FFFFFF;
            border-radius:3px;
            &:hover{
                filter: brightness(0.9);
            }
            &.pagamento{
                background-color: var(--info-color);
            }
            &.recebido{
                background-color: var(--red);
            }
            &.preparando{
                background-color: var(--warning-color);
            }
            &.entrega{
                background-color: var(--purple);
                &:hover{
                    filter: none;
                }
            }
            &.entregue{
                background-color: var(--success-color);
            }
            &.cancelado{
                background-color: var(--brown);
            }
        }
    }
`

