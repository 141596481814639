import {createContext, useState, useEffect, ReactNode, useContext, useCallback } from 'react'
import api from "../services/api";
import {Alert} from 'rsuite';

interface Banner {
    id: any,
    uuid: any,
    url: any,
    titulo: string,
    imagem: any,
    filial: any,
    data_criacao: any,
    status: any
}

interface BannerPagination {
    pagina_atual: any,
    proxima_pagina: any,
    pagina_anterior: any,
    ultima_pagina: any,
    total_itens: any
}

interface BannerProviderProps {
    children: ReactNode; //pode retornar conteudo html, por exemplo
}

interface BannerContextData {
    banner: BannerInput[]; 
    isLoading: boolean;
    bannerPage: (banner:any) => Promise<void>;
    bannerSearch: (banner:any, modal: any) => Promise<void>;
    bannerDelete: (uuid:any, modal: any) => Promise<void>;
    editBanner: (banner: BannerInputEdit, modal: any, uuid: any, page:any) => Promise<void>; 
    createBanner: (banner: BannerInputCriar, modal: any) => Promise<void>; 
    getbannerUuid: (uuid: any, page:any) => Promise<void>;
    activePage: string;
    bannerPorUUID: BannerInputEdit[];
    bannerPagination: BannerPagination[];
    isLoadingSearch: boolean;
    limpaSearchBanner: any;
}

type BannerInput = Omit<Banner, 'data_atualizacao'  | 'imagem' >

type BannerInputCriar = Omit<Banner, 'id' | 'uuid' | 'data_atualizacao' | 'data_criacao' >

type BannerInputEdit = Omit<Banner, 'id' | 'data_atualizacao' | 'data_criacao' >

const BannerContext = createContext<BannerContextData>(
    {} as BannerContextData //criando um Contexto, começa com um objeto vazio como default
);


export function BannerProvider({children} : BannerProviderProps) {
    const [banner, setBanner] = useState<Banner[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [bannerPagination, setBannerPagination] = useState<BannerPagination[]>([]);
    const [bannerPorUUID, setBannerPorUUID] = useState<Banner[]>([]);
    const [isLoadingSearch, setIsLoadingSearch] = useState(true);
    const [activePage, setPageActive] = useState('');

    // pega o banner
    const handleBanner = useCallback(async () => {

        const buscaAtiva = localStorage.getItem('salvarBuscaBanner');

        if(buscaAtiva && buscaAtiva !== ''){
            setIsLoading(true);
            let search = JSON.parse(buscaAtiva);
            const response = await api.get('/banner?status='+search.status); 

            setBanner(response.data.itens);
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                }
            ];
            setBannerPagination(data);
            setIsLoading(false);

        }else{
            setIsLoading(true);
            const response = await api.get('/banner');
    
            setBanner(response.data.itens);
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                }
            ];
            setBannerPagination(data);
            setIsLoading(false);
        }
       
      
 
    }, []);

    useEffect(() => {
        handleBanner();
    }, [handleBanner]);

    // Paginação
    async function bannerPage(page : any) {
        try {
            
            const buscaAtiva = localStorage.getItem('salvarBuscaBanner');
            
            if(buscaAtiva && buscaAtiva !== ''){
                setIsLoading(true);

                let search = JSON.parse(buscaAtiva);
                const response = await api.get('/banner?status='+search.status+'&page='+page);

                setBanner(response.data.itens);
                const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                setIsLoading(false);
                setBannerPagination(data);
            }else{
                setIsLoading(true);
            
                const response = await api.get('/banner?page='+page);             

                setBanner(response.data.itens);
                const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                setIsLoading(false);
                setBannerPagination(data);
            }

           

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }

    // CATEGORIA POR UUID

    async function getbannerUuid(uuid: any, page:any) {
        try {
            setIsLoading(false);
                
            const responseedit = await api.get('/banner/'+uuid);   

            setBannerPorUUID([responseedit.data]);
            setPageActive(page);

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }
    
    // CRIAR CATEGORIA
    
    async function createBanner(BannerInputCriar: BannerInputCriar, Modal: any) {
        try {
            setIsLoading(true);
                
            const data = new FormData();
            data.append("imagem", BannerInputCriar.imagem);
            data.append("url", BannerInputCriar.url);
            data.append("titulo", BannerInputCriar.titulo);
            data.append("filial", BannerInputCriar.filial);
            data.append("status", BannerInputCriar.status as any);
    
            const response = await api.post('/banner', data);  
            let keysToRemove = ["buscaAtivaBanner", "salvarBuscaBanner"];

            Alert.success(response.data.message);
            keysToRemove.forEach(k =>
                localStorage.removeItem(k));

            setIsLoading(true);
            handleBanner();
            Modal();

        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false);
        }
    } 

    

    async function bannerSearch(search : any, Modal: any) {
        try {
            setIsLoading(false);

            if(search.status === undefined){
                handleBanner();
            } else {
                 const response = await api.get('/banner?status='+search.status); 
                 if(response.data.error === true ){
                    Alert.error(response.data.message);

                 }else {

                    localStorage.setItem('buscaAtivaBanner', 'ativa');
                    localStorage.setItem('salvarBuscaBanner', JSON.stringify(search));

                    setBanner(response.data.itens);
                    const data = [{
                            'pagina_atual' : response.data.pagina_atual,
                            'proxima_pagina' : response.data.proxima_pagina,
                            'pagina_anterior' : response.data.pagina_anterior,
                            'ultima_pagina' : response.data.ultima_pagina,
                            'total_itens' : response.data.total_itens
                        }
                    ];
                    setBannerPagination(data);
                 }
            }
            
            Modal();
            

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }

    
    

    async function bannerDelete(uuid : any, Modal: any) {
        try {
            setIsLoading(false);
        
            if(uuid === undefined){
                handleBanner();
            } else {
                 const response = await api.delete('/banner/'+uuid);  
                    
                 if(response.data.error === true ){
                    Alert.error(response.data.message);

                 }else {
                    setBanner(response.data.itens);
                    const data = [{
                            'pagina_atual' : response.data.pagina_atual,
                            'proxima_pagina' : response.data.proxima_pagina,
                            'pagina_anterior' : response.data.pagina_anterior,
                            'ultima_pagina' : response.data.ultima_pagina,
                            'total_itens' : response.data.total_itens
                        }
                    ];
                    setBannerPagination(data);
                 }
            }
            
            handleBanner();
            Modal();
            

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    }
    
    async function editBanner(BannerInputEdit: BannerInputEdit, Modal: any, uuid: any, page:any) {
        try {
            setIsLoading(true);
                
            const data = new FormData();
            data.append("imagem", BannerInputEdit.imagem);
            data.append("url", BannerInputEdit.url);
            data.append("titulo", BannerInputEdit.titulo);
            data.append("filial", BannerInputEdit.filial);
            data.append("status", BannerInputEdit.status as any);
    
            const response = await api.post('/banner/atualizar/'+uuid, data); 

            const buscaAtiva = localStorage.getItem('salvarBuscaBanner');
            if(buscaAtiva && buscaAtiva !== ''){

                let search = JSON.parse(buscaAtiva);
                const responsepage = await api.get('/banner?status='+search.status+'&page='+page);
                
                setBanner(responsepage.data.itens);
                const datapage = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total_itens' : response.data.total_itens
                    }
                ];
                
                setBannerPagination(datapage);
    
                Alert.success(response.data.message);
                Modal();
                setIsLoading(false);
    
            }else{
                let keysToRemove = ["buscaAtivaBanner", "salvarBuscaBanner"];
    
                keysToRemove.forEach(k =>
                    localStorage.removeItem(k));
                    
                    const responsepage = await api.get('/banner?page='+page); 

                    setBanner(responsepage.data.itens);
                    const datapage = [{
                        'pagina_atual' : response.data.pagina_atual,
                        'proxima_pagina' : response.data.proxima_pagina,
                        'pagina_anterior' : response.data.pagina_anterior,
                        'ultima_pagina' : response.data.ultima_pagina,
                        'total_itens' : response.data.total_itens
                        }
                    ];
                    setBannerPagination(datapage);
            
                    Alert.success(response.data.message);
                    Modal();
                    setIsLoading(false);
            }
     
        } catch (err: any) {
            Alert.error(err.response.data.message);
            setIsLoading(false); //pegando o response da requisição da api
        }
    } 

    async function limpaSearchBanner() {
        try {
            setIsLoadingSearch(true);
            
            handleBanner();
            let keysToRemove = ["buscaAtivaBanner", "salvarBuscaBanner"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));
                window.location.reload();

            setIsLoadingSearch(false);

        } catch (err: any) {            
            Alert.error(err.response.data.message);
            setIsLoadingSearch(false);
        }
    }
 
    return (
       <BannerContext.Provider value={{ banner, isLoading, createBanner, getbannerUuid, bannerPorUUID, editBanner, bannerSearch, bannerPagination, bannerPage, bannerDelete, limpaSearchBanner, isLoadingSearch, activePage }}>
            {children} 
        </BannerContext.Provider>
    )

}


export function useBanner() {
    const context = useContext(BannerContext);

    return context;
}
