import Modal from 'react-modal'
import { GlobalStyle } from "./styles/global"

import Routes from './routes/routes'
import  'rsuite/dist/styles/rsuite-default.css';
import 'leaflet/dist/leaflet.css';


Modal.setAppElement('#root');

export function App() {  
    return (
        <div>
            <Routes />
            <GlobalStyle />
        </div>
    );
}
