import { FormEvent, useState, useCallback, useEffect  } from 'react'
import * as S from "./styles"
import {FormGroup, InputGroup, Icon} from 'rsuite';
import { useLoja } from "../../hooks/useLoja"
import { useFilial } from "../../hooks/useFilial"
import InputMask from "react-input-mask";
import {
  useParams
} from "react-router-dom";

import { useCategoria } from "../../hooks/useCategoria"
import {Loading} from '../Loading';

import { MapContainer, Marker, TileLayer, useMapEvents, Circle } from 'react-leaflet'; 
import L from 'leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';


// import axios from 'axios'
import api from "../../services/api";


export function VerLoja() {
    const { lojaPorUUID, isLoadingEdit, editLoja, verlojaPorUuid, activePage } = useLoja();
    const { categoria } = useCategoria();
    const { filial } = useFilial();
    const { id } = useParams<{ id: any }>();

    const [nome, setNome] = useState(lojaPorUUID[0]?.nome || '');
    const [raio, setRaio] = useState(lojaPorUUID[0]?.raio || '');
    const [longitude, setLongitude] = useState(lojaPorUUID[0]?.longitude || 0);
    const [latitude, setLatitude] = useState(lojaPorUUID[0]?.latitude || 0);
    const [razao_social, setRazaoSocial] = useState(lojaPorUUID[0]?.razao_social || '');
    const [email, setEmail] = useState(lojaPorUUID[0]?.email || '');
    const [cnpj, setCNPJ] = useState(lojaPorUUID[0]?.cnpj.br || '');
    const [telefone, setTelefone] = useState(lojaPorUUID[0]?.telefone.br || '');
    const [celular, setCelular] = useState(lojaPorUUID[0]?.celular.br || '');
    const [cep, setCEP] = useState(lojaPorUUID[0]?.cep.br || '');
    const [logradouro, setLogradouro] = useState(lojaPorUUID[0]?.logradouro || '');
    const [complemento, setComplemento] = useState(lojaPorUUID[0]?.complemento || '');
    const [numero, setNumero] = useState(lojaPorUUID[0]?.numero || '');
    const [bairro, setBairro] = useState(lojaPorUUID[0]?.bairro || '');
    const [cidade, setCidade] = useState(lojaPorUUID[0]?.cidade || '');
    const [estado, setEstado] = useState(lojaPorUUID[0]?.estado || '');
    const [status, setStatus] = useState(lojaPorUUID[0]?.status || '');
    const [categorias, setCategoria] = useState(lojaPorUUID[0]?.categoria || ['']);
    const [logo, setLogo] = useState(lojaPorUUID[0]?.logo || '');
    const [logo_upload, setLogoUpload] = useState('');
    const [filiais, setFilial] = useState(lojaPorUUID[0]?.filial || '');

    const [selectedPosition, setSelectedPosition] = useState<[number, number]>([latitude,longitude]);

    let DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow
    });
    
    L.Marker.prototype.options.icon = DefaultIcon;

    const Markers = () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const map = useMapEvents({
            click(e) {          
                setLatitude(e.latlng.lat);
                setLongitude(e.latlng.lng); 

                setSelectedPosition([
                    e.latlng.lat,
                    e.latlng.lng
                ]);          
            },            
        })
    
        map.setView(selectedPosition, map.getZoom());
        let raioEmKm = raio * 1000;
        return (
            selectedPosition ? 
                <Marker           
                key={selectedPosition[0]}
                position={selectedPosition}
                interactive={false} 
                > 
                    <Circle 
                    center={selectedPosition}
                    fillColor="blue" 
                    radius={raioEmKm}/>
                </Marker>
            : null
        )   
        
    }

    async function handleEditLoja(event: FormEvent){
        event.preventDefault();
        let uuid = lojaPorUUID[0]?.uuid ? lojaPorUUID[0]?.uuid : '';
        let categoria = categorias.toString();

        const data = {
            uuid: uuid as any,
            nome,
            logo:logo_upload,
            razao_social,
            email,
            cnpj,
            telefone: telefone.replace(/\D/g, ""),
            celular: celular.replace(/\D/g, ""),
            cep,
            responsavel: lojaPorUUID[0]?.usuario?.nome,
            logradouro,
            complemento,
            numero,
            bairro,
            cidade,
            estado,
            categoria,
            status,
            raio,
            longitude,
            latitude,
            filial: Number(localStorage.getItem('TCHAMA')) === 1 ? filiais : localStorage.getItem('FCHAMA'),
            usuario: ''
        };

 
        await editLoja(data, lojaPorUUID[0]?.uuid, activePage);
    
    }
	/**
	 * Pesquisar cep
	 */


     const handleLojaEdit = useCallback(async () => {
        await verlojaPorUuid(id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);

    useEffect(() => {
        handleLojaEdit();
    }, [handleLojaEdit]);

    useEffect(() => {
        
        if(lojaPorUUID.length > 0){
            
            setNome(lojaPorUUID[0]?.nome || '');
            setFilial(lojaPorUUID[0]?.filial || '');
            setRaio(lojaPorUUID[0]?.raio || '');
            setLongitude(lojaPorUUID[0]?.longitude || '');
            setLatitude(lojaPorUUID[0]?.latitude || '');
            setRazaoSocial(lojaPorUUID[0].razao_social || '');
            setEmail(lojaPorUUID[0].email || '');
            setCNPJ(lojaPorUUID[0].cnpj.br || '');
            setTelefone(lojaPorUUID[0].telefone.br || '');
            setCelular(lojaPorUUID[0].celular.br || '');
            setCEP(lojaPorUUID[0].cep.br || '');
            setLogradouro(lojaPorUUID[0].logradouro || '');
            setComplemento(lojaPorUUID[0].complemento || '');
            setNumero(lojaPorUUID[0].numero || '');
            setBairro(lojaPorUUID[0].bairro || '');
            setCidade(lojaPorUUID[0].cidade || '');
            setEstado(lojaPorUUID[0].estado || '');
            setStatus(String(lojaPorUUID[0].status) || '');
            setCategoria(lojaPorUUID[0]?.categoria || '');
            setLogo(lojaPorUUID[0].logo || '');
            setSelectedPosition([
                lojaPorUUID[0].latitude,
                lojaPorUUID[0].longitude
            ]);
            
        }
    }, [lojaPorUUID]);

    async function pesquisacep(valor:any) {
		var cep = valor;

		if (cep !== "") {
            api.get('/endereco/geolocalizacao/'+cep+'/cep')
                .then((res: any) => {
                    setLogradouro(res.data.endereco.logradouro);
                    setBairro(res.data.endereco.bairro);
                    setCidade(res.data.endereco.localidade);
                    setEstado(res.data.endereco.uf);
                    setLatitude(res.data.latitude);
                    setLongitude(res.data.longitude);
                    setSelectedPosition([
                        res.data.latitude,
                        res.data.longitude
                    ]);
                 
            }).catch((error: any) => console.log(error.response));
                 
		}
	}
    
	const estados = {
		Estado: "Estado",
		AC: "Acre",
		AL: "Alagoas",
		AP: "Amapá",
		AM: "Amazonas",
		BA: "Bahia",
		CE: "Ceará",
		DF: "Distrito Federal",
		ES: "Espírito Santo",
		GO: "Goiás",
		MA: "Maranhão",
		MT: "Mato Grosso",
		MS: "Mato Grosso do Sul",
		MG: "Minas Gerais",
		PA: "Pará",
		PB: "Paraíba",
		PR: "Paraná",
		PE: "Pernambuco",
		PI: "Piauí",
		RJ: "Rio de Janeiro",
		RN: "Rio Grande do Norte",
		RS: "Rio Grande do Sul",
		RO: "Rondônia",
		RR: "Roraima",
		SC: "Santa Catarina",
		SP: "São Paulo",
		SE: "Sergipe",
		TO: "Tocantins",
	};

	function options() {
		var es = Object.entries(estados);
		return es.map((item) => (
			<option key={`estado${item[0]}`} value={item[0]}>
				{item[1]}
			</option>
		));
	}
    
 
    const onImageChange = (event: any) => {
        const { files } = event.target;
        setLogo(URL.createObjectURL(files[0]));
        setLogoUpload(files[0]);
        
    };

    const handlClearFoto = async () => {
        setLogo('');
        setLogoUpload('');
    };
    

    
    return (
        <div>
            { isLoadingEdit ? 
                <Loading /> 
            :
            <S.Container onSubmit={handleEditLoja} >
                <S.ContainerCardForm>
                    <S.Column>
                    <S.Row>
                    <div className="card-form">
                        <h3>Dados da empresa</h3>
                        <S.FotoContainer>
                            {logo ? (
                                <>
                                <InputGroup className="close_search" label="Excluir foto">
                                    <InputGroup.Button onClick={() => handlClearFoto()}>
                                        <Icon icon="close" />
                                    </InputGroup.Button>
                                </InputGroup>
                                <img src={logo} alt="imagem"  width="300" height="300" 
                                onChange={onImageChange} />
                                </>
                                ) : (
                                <>
                                <Icon icon="file-image-o" size="4x" />
                                <input
                                    type="file"
                                    id="upload-button"
                                    onChange={onImageChange}
                                />
                                <label>Clique aqui para inserir uma imagem</label>
                                </>
                                )}
                            </S.FotoContainer>

                        {Number(localStorage.getItem('TCHAMA')) === 1 ?
                        <FormGroup>
                            <label>Filial: </label>
                            <select
                                value={filiais}
                                onChange={event => setFilial(event.target.value)}
                                name="filial">
                                <option key="1" value="">Selecione a Filial do usuário</option>
                                {filial && filial.map(filial => (
                                <option value={filial.uuid} key={filial.uuid}>
                                    {filial.razao_social}
                                </option>
                                ))}
                            </select>
                        </FormGroup>
                        : null }
                        <FormGroup>
                            <label>Nome fantasia: </label>
                            <input 
                                type="text" 
                                placeholder="Nome fantasia"
                                value={nome}
                                onChange={event => setNome(event.target.value)}
                                
                            /> 
                            </FormGroup>
                        <FormGroup>
                            <label>Razão Social: </label>
                        <input 
                            type="text" 
                            placeholder="Razão social"
                            value={razao_social}
                            onChange={event => setRazaoSocial(event.target.value)}
                            
                        />
                        </FormGroup>
                        <FormGroup>
                            <label>E-mail: </label>
                            <input 
                                type="email" 
                                placeholder="E-mail" 
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                                
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>CNPJ:</label>
                            <InputMask 
                                type="text" 
                                mask="99.999.999/9999-99"
                                placeholder="CNPJ" 
                                value={cnpj}
                                onChange={event => setCNPJ(event.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Telefone:</label>
                            <InputMask 
                                type="text" 
                                mask="(99) 9999-9999"
                                placeholder="Telefone" 
                                onChange={event => setTelefone(event.target.value)}    
                                value={telefone} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Celular:</label>
                            <InputMask 
                                type="text" 
                                mask="(99) 99999-9999"
                                placeholder="Celular"  
                                value={celular}
                                onChange={event => setCelular(event.target.value)} 
                            />
                        </FormGroup>
                    </div>
                    <div className="card-form">
                        <h3>Endereço</h3>
                        <FormGroup>
                            <label>CEP:</label>
                            <InputMask
                                mask="99999-999"
                                value={cep}
                                placeholder="CEP"
                                type="text" 
                                onChange={(event) => {
                                    setCEP(event.target.value);
                                }}
                                onBlur={(event) =>
                                    pesquisacep(event.target.value)
                                }
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Logradouro:</label>
                            <input 
                                type="text" 
                                placeholder="Logradouro"    
                                value={logradouro}
                                onChange={event => setLogradouro(event.target.value)}
                                
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Complemento:</label>
                            <input 
                                type="text" 
                                placeholder="Complemento"    
                                value={complemento}
                                onChange={event => setComplemento(event.target.value)}
                                
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Número:</label>
                            <input 
                                type="text" 
                                placeholder="Número" 
                                value={numero}
                                onChange={event => setNumero(event.target.value)}
                                
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Bairro:</label>
                            <input 
                                type="text" 
                                placeholder="Bairro" 
                                value={bairro}
                                onChange={event => setBairro(event.target.value)}
                                
                        />
                        </FormGroup>
                        <FormGroup>
                            <label>Cidade:</label>
                            <input 
                                type="text" 
                                placeholder="Cidade" 
                                value={cidade}
                                onChange={event => setCidade(event.target.value)}
                                
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Estado:</label>
                            <select
                                placeholder="Estado"
                                onChange={(e) => {
                                    setEstado(e.target.value);
                                }}
                                value={estado}
                            >
                                {options()}
                        </select>
                        </FormGroup>
                    </div>
                    <div className="card-form">
                        <h3>Controle</h3>
                        <FormGroup>
                            <label>Status:</label>
                            <S.StatusLojaContainer>
                                <S.RadioBox 
                                    type="button"
                                    onClick={() => setStatus(String(1))}
                                    isActive={status === '1'}
                                    activeColor="green"
                                    activeColorFonte="white"
                                >   
                                    <span>Ativo</span>
                                </S.RadioBox>
                                <S.RadioBox 
                                    type="button"
                                    onClick={() => setStatus(String(2))}
                                    isActive={status === '2'}
                                    activeColor="red"
                                    activeColorFonte="white"
                                >
                                    <span>Inativo</span>
                                </S.RadioBox>
                            </S.StatusLojaContainer>
                            
                        </FormGroup>
                        <FormGroup>
                            <label>Categoria: </label>
                            <select
                                value={categorias}
                                onChange={event => setCategoria(event.target.value)}
                                name="filial">
                                <option value="">Selecione a categoria da loja</option>
                                {categoria && categoria.map(categoria => (
                                <option value={categoria.nome} key={categoria.uuid}>
                                    {categoria.nome}
                                </option>
                                ))}
                            </select>
                        </FormGroup>
                    </div>   
                    </S.Row>  
                </S.Column>
                </S.ContainerCardForm>
                    <S.ContainerMapa>
                        <h2>Digite nos inputs ou selecione no mapa a latitude e longitude</h2>
                        <div className="input">
                            <FormGroup>
                            <label>Latitude: </label>
                            <input 
                                type="text" 
                                placeholder="Latitude"
                                value={latitude}
                                onChange={event => setLatitude(Number(event.target.value))}
                                
                            /> 
                            </FormGroup>
                            <FormGroup>
                            <label>Longitude: </label>
                            <input 
                                type="text" 
                                placeholder="Longitude"
                                value={longitude}
                                onChange={event => setLongitude(Number(event.target.value))}
                                
                            /> 
                            </FormGroup>
                            <FormGroup>
                            <label>Raio(km): </label>
                            <input 
                                type="text" 
                                placeholder="Raio"
                                value={raio}
                                onChange={event => setRaio(event.target.value)}
                                
                            /> 
                            </FormGroup>
                            </div>
                            <MapContainer 
                            center={selectedPosition} 
                            zoom={12}    
                            className="leaflet-map"                       
                            >
                            <Markers />
                            <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />                        
                        </MapContainer>
                    </S.ContainerMapa>
                <button type="submit">{isLoadingEdit ? 'Carregando...' : 'Atualizar'}</button>
                </S.Container>
            }
        </div>
    )   
}
