import Admin from "../../components/Layouts/admin";
import * as S from "./styles";
import { PerfilForm } from "../../components/PerfilForm";
import { PerfilProvider } from '../../hooks/usePerfil'

export function Perfil() {

    return (
        <PerfilProvider>
            <Admin pageTitle={"Atualize seu Perfil"}>
                <S.Container>
                    <PerfilForm  />
                </S.Container>
            </Admin>
        </PerfilProvider>
    );
}
