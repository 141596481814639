import { CaixaLojaTable } from "../../components/CaixaLojaTable";
import {useState} from 'react'
import * as S from "./styles";
import { CaixalojaProvider } from '../../hooks/useCaixaloja'
import Admin from '../../components/Layouts/admin';
import { VerCaixaLojaModal } from '../../components/VerCaixaLojaModal';


export function CaixaLoja() {
    
    const [isEditCaixaModalOpen, setIsEditCaixaModalOpen] = useState(false);   

    function handleOpenEditCaixaModal(){
        setIsEditCaixaModalOpen(true);
    }

    function handleCloseEditCaixaModal(){
        setIsEditCaixaModalOpen(false);
    }
    
    return (
        <CaixalojaProvider>
            <Admin pageTitle={'Caixa'} > 
                <S.Container>
                    <CaixaLojaTable onOpenEditCaixaLojaModal={handleOpenEditCaixaModal} />
                    
                
                    {VerCaixaLojaModal ? 
                        <VerCaixaLojaModal 
                            isOpen={isEditCaixaModalOpen}
                            onRequestClose={handleCloseEditCaixaModal}
                        />
                    : null
                    }
                </S.Container>
            </Admin>    
        </CaixalojaProvider>
    );
}
