import { useFilial } from "../../hooks/useFilial";
import * as S from "./styles";
import { Pagination, Icon, Modal, Button, IconButton } from 'rsuite';
import {Loading} from '../Loading'
import {useState} from 'react';

import Skeleton from "react-loading-skeleton";


interface FilialTableProps {
    onOpenNewFilialModal: () => void;
    onOpenEditFilialModal: () => void;
}

export function FilialTable({
    onOpenNewFilialModal,
    onOpenEditFilialModal,
}: FilialTableProps) {

    const { filial, isLoading, getfilialPorUuid, filialPagination, filialPage, filialDelete } = useFilial();


    const [deletar, setDeletar] = useState('');
    const [usuarioDeletar, setusuarioDeletar] = useState('');
    const handleClickOpenEdit = async (url: any, page:any) => {
        await getfilialPorUuid(url, page);
        onOpenEditFilialModal();
    };
  
    const handleSelect = async (eventKey: any) => {
        await filialPage(eventKey);
    }


    // MODAL DELETE
    const [modalDelete, setModalDelete] = useState(false);

    function handleModalDeleteClose (){
        setModalDelete(false);
    }
    
    const handleModalshowDelete = async (uuid: any, usuario:any) => {
        setModalDelete(true);
        setDeletar(uuid);
        setusuarioDeletar(usuario);
    }

    const handleModalDeletar = async (uuid: any, modal: any, usuario:any) => {

        await filialDelete(uuid, usuario, modal);
    }

    console.log(filial);
    return (
        <S.Container>
            <div>
                {isLoading ? <Loading /> : ''}
            </div>
            <button type="button" onClick={onOpenNewFilialModal}>
                Nova Filial
            </button>
      
            <table>
                <thead>
                    <tr>
                        <th>Razão social</th>
                        <th>Data de criação</th>
                        <th className="status">Status</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody>
                    {filial && filial.map(filial => (
                        <tr 
                            key={filial.uuid}  
                        >
                            <td 
                            onClick={() => handleClickOpenEdit(filial.url, filialPagination[0].pagina_atual)}>{isLoading ? <Skeleton /> : filial.razao_social}</td>
                            <td 
                            onClick={() => handleClickOpenEdit(filial.url, filialPagination[0].pagina_atual)}>
                                {isLoading ? <Skeleton /> : filial.data_criacao.data}
                            </td>
                            <td className={filial.status === 1 ? 'ativo' : 'inativo'} 
                            onClick={() => handleClickOpenEdit(filial.url, filialPagination[0].pagina_atual)}> 
                                {isLoading ? <Skeleton /> : <div></div>}
                            </td>
                            <td>
                                <IconButton size="lg" style={{padding: "0px", width: "42px", marginBottom: "0px"}} color="red" onClick={() => handleModalshowDelete(filial.uuid, filial.usuario.uuid)} icon={<Icon icon="close" />} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <S.ContainerPagination>
            {filialPagination.length ?
                <Pagination
                prev
                last
                next
                first
                size="lg"
                pages={filialPagination[0].ultima_pagina}
                activePage={filialPagination[0].pagina_atual}
                onSelect={handleSelect}
                />
                : null
            }
            </S.ContainerPagination>
            <Modal backdrop="static" show={modalDelete} onHide={handleModalDeleteClose} size="xs">
                <Modal.Body>
                    <Icon
                    icon="remind"
                    style={{
                        color: '#990606',
                        fontSize: 24
                    }}
                    />
                    {'  '}
                    Tem certeza que deseja deletar essa filial?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleModalDeletar(deletar, handleModalDeleteClose, usuarioDeletar)} color="red">
                    Quero deletar
                    </Button>
                    <Button onClick={handleModalDeleteClose} appearance="subtle">
                    Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </S.Container>
    );
}
