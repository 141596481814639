import { FormEvent, useState   } from 'react'
import Modal from 'react-modal'
import { useLoja } from "../../hooks/useLoja"

import { MdClose } from "react-icons/md"
import * as S from "./styles"

import {FormGroup} from 'rsuite';


interface SearchLojaModalProps {
    isOpenSearchLoja: boolean;
    onRequestCloseSearchLoja: () => void;
}



export function SearchLojaModal({isOpenSearchLoja, onRequestCloseSearchLoja}: SearchLojaModalProps) {
    const { isLoading, lojaSearch } = useLoja();
    
    const [searchLoja, setSearchLoja] = useState(''); 


    
    async function handleClickOpenSearchLoja(event: FormEvent){
           event.preventDefault();
        const data = {
            nome: searchLoja,
        };

        await lojaSearch(data, onRequestCloseSearchLoja);
    };

    

    return (
        
        <Modal
            isOpen={isOpenSearchLoja}
            onRequestClose={onRequestCloseSearchLoja}
            overlayClassName="react-modal-overlay"
            className="react-modal-content-search"
        >
            <button type="button" onClick={onRequestCloseSearchLoja} className="react-modal-close">
                <MdClose />
            </button>
            <S.Container onSubmit={handleClickOpenSearchLoja}>
                <h2>Pesquisar</h2>
                <S.ContainerCardForm>
                    <div className="card-form">

                        <FormGroup>
                            <label>Digite um nome: </label>
                            <input 
                                type="text" 
                                placeholder="Nome Fantasia"
                                value={searchLoja}
                                onChange={event => setSearchLoja(event.target.value)}
                                
                            /> 
                        </FormGroup>
                     
                    </div>
                        
                </S.ContainerCardForm>
                
                <button type="submit">{isLoading ? 'Carregando...' : 'Buscar'}</button>
            </S.Container>
        </Modal>
    )
}
