import * as S from "./styles";
import {Loading} from '../Loading'
import {useState} from 'react';
import { Pagination, Modal, Button, Icon, IconButton } from 'rsuite';
import { useBanner } from "../../hooks/useBanner";

import Skeleton from "react-loading-skeleton";


interface BannerTableProps {
    onOpenNewBannerModal: () => void;
    onOpenEditBannerModal: () => void;
}
export function BannerTable({
    onOpenNewBannerModal,
    onOpenEditBannerModal
}: BannerTableProps) {

    const { banner, isLoading, getbannerUuid, bannerPagination, bannerPage, bannerDelete } = useBanner();

    const [deletar, setDeletar] = useState('');
  
    const handleClickOpenEdit = async (uuid: any, page:any) => {
        await getbannerUuid(uuid, page);
        onOpenEditBannerModal();
    };

    const handleSelect = async (eventKey: any) => {
        await bannerPage(eventKey);
    }
    const [modalDelete, setModalDelete] = useState(false);

    function handleModalDeleteClose (){
        setModalDelete(false);
    }
    
    const handleModalshowDelete = async (uuid: any) => {
        setModalDelete(true);
        setDeletar(uuid);
    }

    const handleModalDeletar = async (uuid: any, modal: any) => {

        await bannerDelete(uuid, modal);
    }

    return (
        <S.Container>
            <div>
                {isLoading ? <Loading /> : ''}
            </div>
            <button type="button" onClick={onOpenNewBannerModal}>
                Novo Banner
            </button>
      
            <table>
                <thead>
                    <tr>
                        <th>Título</th>
                        <th>Data de criação</th>
                        <th className="status">Status</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody>
                    {banner && banner.map(banner => (
                        
                        <tr 
                            key={banner.uuid}  
                        >
                            <td 
                            onClick={() => handleClickOpenEdit(banner.uuid, bannerPagination[0].pagina_atual)}
                            >{isLoading ? <Skeleton /> : banner.titulo}</td>
                            <td 
                            onClick={() => handleClickOpenEdit(banner.uuid, bannerPagination[0].pagina_atual)}
                            >
                                {isLoading ? <Skeleton /> : banner.data_criacao.data}
                            </td>
                            <td 
                            onClick={() => handleClickOpenEdit(banner.uuid, bannerPagination[0].pagina_atual)}
                             className={banner.status === 1 ? 'ativo' : 'inativo'}> 
                                {isLoading ? <Skeleton /> : <div></div>}
                            </td>
                            <td>
                                <IconButton size="lg" style={{padding: "0px", width: "42px", marginBottom: "0px"}} color="red" onClick={() => handleModalshowDelete(banner.uuid)} icon={<Icon icon="close" />} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <S.ContainerPagination>
            {bannerPagination.length ?
                <Pagination
                prev
                last
                next
                first
                size="lg"
                pages={bannerPagination[0].ultima_pagina}
                activePage={bannerPagination[0].pagina_atual}
                onSelect={handleSelect}
                />
                : null
            }
            </S.ContainerPagination>

            <Modal backdrop="static" show={modalDelete} onHide={handleModalDeleteClose} size="xs">
                <Modal.Body>
                    <Icon
                    icon="remind"
                    style={{
                        color: '#990606',
                        fontSize: 24
                    }}
                    />
                    {'  '}
                    Tem certeza que deseja deletar esse banner?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleModalDeletar(deletar, handleModalDeleteClose)} color="red">
                    Quero deletar
                    </Button>
                    <Button onClick={handleModalDeleteClose} appearance="subtle">
                    Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </S.Container>
    );
}
