import styled from 'styled-components'

export const ContainerDuplo = styled.div`
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .container{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    

    button {
        height: 3rem;
        padding: 0 2rem;
        font-size: 1rem;
        color: #FFF;
        background-color: var(--info-color);
        border-color: var(--info-color);
        border-radius: 0.25rem;
        border: none;
        margin-bottom: 1.25rem;

        transition: filter 0.2s;

        &:hover{
            filter: brightness(0.9)
        }
        &:focus{
            outline: none
        }
    }
    .tabela {
        flex: 1;
        &:first-of-type{
            margin-right: 2px;
            table{
                border-right: 1px solid var(--red);
            }
        }
        &.mobile{
            display: none;
        }
    }

    table {
        width: 100%;
        border-spacing: 0 0.5rem;
        &:nth-child(1){
            margin-right: 20px;
        }

        th {
            color: var(--text-body);
            font-weight: 400;
            text-align: left;
            line-height: 1.5em;
            background: var(--shape);
            padding: 1rem 2rem;
            border-bottom: 2px solid var(--red);
            &.status {
                text-align: center;
            }
        }

        tr {
            cursor: pointer;
            border-bottom: 2px solid #eee;
            &:hover{
                filter: brightness(0.9);
            }
        }
        
        td {
            padding: 1rem 2rem;
            border: 0;
            background: var(--shape);
            font-weight: 400;
            color: var(--text-body);
            border-radius: 0.25rem;


            &:first-child(1){
                color: var(--text-title);
            }


        }

        .verde  {
            vertical-align: middle;
            p {
                min-width: 100px;
                max-width: 100px;
                background: var(--green);
                color: #333;
                text-align:center;
                border-radius: 10px;
                line-height: inherit;
            }
        }

        .cinza {
            vertical-align: middle;
            p {
                min-width: 100px;
                max-width: 100px;
                background: var(--gray);
                color: #333;
                text-align:center;
                border-radius: 10px;
                line-height: inherit;
            }
        }
    }
    @media screen and (max-width: 800px) {
        .tabela{
            display: none;
        }
        .tabela.mobile{
            display: flex;
            h1{
            display: none;

            }
        }
        table{
            border: 1px solid transparent;
            box-shadow: none;
            thead{
                display: none;
            }
            tbody{
                tr{
				    border-bottom: 4px solid #F8F8F8;
                    padding: 10px 10px!important;
                    td:first-child{
                        padding-left:10px;

                    }
                    td:before{
                        content: attr(data-label);
                        float: left;
                        font-size:10px;
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                    td{
                        display: flex;
                        justify-content: space-between;
                        text-align: right;
                        font-size: 14px;
                        line-height: 40px;
                        padding: 10px 10px!important;
                        border-radius: none !important;
                        box-shadow: none !important;
                        border-top: 1px solid #eee;

                    }
                }
            }
        }	
    }

`

export const ContainerPagination = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .rs-pagination > li.rs-pagination-btn-active > a{
        color: var(--red);
    }
`
