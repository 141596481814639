import styled from 'styled-components'


export const VoltarPagina = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    a{
        color: #181818;
        &:hover{
            color: #181818;
            text-decoration: none;
        }
    }
    .voltar_page{
        margin-right: 20px;
        font-size: 22px;
        cursor: pointer;
    }
`

export const End = styled.div`
    display: flex;
    align-items: center;

    .close_search {
        margin-right: 5px;
        a {
            background-color: var(--red);
            color: rgb(255, 255, 255);
        }
        &:hover,
        &:focus{
            border-color: var(--red) !important;
        }
    }
`
