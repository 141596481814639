import styled from 'styled-components'
import { darken } from 'polished'

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 940px) {
        width: 100%;
    }
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 940px) {
        width: 100%;
    }
`;

export const Container = styled.form`
    width: 100%;
    max-width: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    overflow-x: hidden;
    @media (max-width: 768px) {
        padding: 0px 20px;
    }
    @media (max-width: 940px) {
        .produto {
            flex-direction: column;
            align-items:center;
            justify-content: center;
        }
    }
    h2 {
        width: 100%;
        color: var(--text-title);
        font-size: 1.5rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid #d7d7d7;
    }

    input[type=text],
    input[type=email] ,
    input[type=number],
    textarea,
    select  {
        width: 100%;
        padding: 0 1.5rem;
        height: 4rem;
        border-radius: 0.25rem;
        border: 1px solid #d7d7d7;
        background: #e7e9ee;

        font-weight: 400;
        font-size: 1rem;

        &::placeholder{
            color: var(--text-body);
        }
        //todo input que tiver um input acima vai receber margin top
        & + input {
            margin-top: 0.25rem;
        }

    }
    textarea.descricao {
        height: 10em;
        resize: none;
        padding: 10px 20px;
    }
    .rs-form-group {
        margin: 10px;
    }

    button[type=submit] {
        width: 50%;
        padding: 0 1.5rem;
        height: 4rem;
        background: var(--green);
        color: #FFFFFF;
        border-radius: 0.25rem;
        border: 0;
        font-size: 1rem;
        margin-top: 1.8rem;
        margin-bottom: 40px;
        font-weight: 600;

        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.9);
        }
    }
`


export const ContainerCardForm = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 40px;
    flex-direction: column;
    @media (max-width: 768px) {
        flex-direction: column;
    }

    .card-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0px 1rem;
        @media (max-width: 768px) {
            width: 100%;
            margin: 0px;
            h3{
                margin-top: 40px;
            }
        }
        .adicionar_categoria {
            display: block;
            width: 100px;
            max-width: 100px;
            color: var(--red);
            font-size: 1rem;
            line-height: 1em;
            margin-bottom: 20px;
            margin-top: 40px;
            p{
                font-weight: bold;
                cursor: pointer;
                &:hover {
                    border-bottom: 1px solid var(--red);
                }
            }
        }
        &.adicional {
            margin-bottom: 80px;
        }

    }

`

export const StatusFilialContainer = styled.div`
    margin: 1rem 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap : 0.5rem;

`

interface RadioBoxProps {
    isActive: boolean;
    activeColor: 'green' | 'red';
    activeColorFonte: 'white' | 'white'
}

const colors = {
    green: '#33CC95',
    red: '#A7282F'
}

const fonte = {
    white: '#FFFFFF',
}


export const RadioBox = styled.button<RadioBoxProps>`
    height: 4rem;
    border: 1px solid #d7d7d7;
    border-radius: 0.25rem;

    background: ${(props) => props.isActive 
        ? colors[props.activeColor]
        : 'transparent'
    };


    display : flex;
    align-items:center;
    justify-content: center;

    span {
        display: inline-block;
        font-size: 1rem;
        color: ${(props) => props.isActive 
            ? fonte[props.activeColorFonte]
            :  '#363F5F'
        };
    }

    transition:  border-color 0.2s;

    &:hover {
        border-color: ${darken(0.1, '#d7d7d7')};
    }

    &:focus {
        outline: ${darken(0.1, '#d7d7d7')};
    }
`

export const FotoContainer = styled.div`
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    margin-bottom: 40px;
    @media (max-width: 940px) {
        align-items:center;
        justify-content: center;
    }
    .crop {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dashed #333;
    }
    label{
        margin-top: 10px;
        margin-bottom:0px;
    }
    input{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 0px;
        opacity: 0;
        cursor: pointer;
        z-index: 1000;
    }
    .rs-input-group{
        width: 40px;
        margin-bottom: 5px;
    }

`

export const CardAdicional = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    background-color: #FFF;
    padding: 20px;
    margin-top: 20px;
    
    input[type=text],
    input[type=email] ,
    input[type=number],
    textarea,
    select  {
        width: 100%;
        padding: 0 1.5rem;
        height: 3rem;
        border-radius: 0.25rem;
        border: 1px solid #d7d7d7;
        background: #e7e9ee;

        font-weight: 400;
        font-size: 1rem;

        &::placeholder{
            color: var(--text-body);
        }
        //todo input que tiver um input acima vai receber margin top
        & + input {
            margin-top: 0.25rem;
        }

    }
    .excluirElemento {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 10px;
        right: 20px;
        background: var(--red);
        border-radius: 50%;
        color: #fff;
    }

    .quantidade{
        width: 100%;
        display: flex;
        align-items: flex-end;
        input {
            height: 40px;
        }
        .obrigatorio {
            display: flex;
            align-items: center;
            label {
                margin-left: 10px;
            }
        }
    }
    .adicionar_item {
        display: block;
        width: 140px;
        max-width: 140px;
        color: var(--red);
        font-size: 1rem;
        line-height: 1em;
        margin-bottom: 20px;
        margin-top: 20px;
        p{
            font-weight: bold;
            cursor: pointer;
            &:hover {
                border-bottom: 1px solid var(--red);
            }
        }
    }
    .container_item .containerAdicionalItem:nth-child(1) .excluirElementoItem {
        display: none;
    }
`
export const CardAdicionalItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    background-color: #FFF;
    border-bottom: 1px solid #EEE;
    padding-top: 20px;
    padding-bottom: 40px;
    .quantidade{
        width: 100%;
        display: flex;
        align-items: flex-end;
        input {
            height: 40px;
        }
        .obrigatorio {
            display: flex;
            align-items: center;
            label {
                margin-left: 10px;
            }
        }
    }
    .item {
        flex: 1;
    }
    .excluirElementoItem {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0px;
        right: 20px;
        margin-top: 10px;
        background: var(--red);
        border-radius: 50%;
        color: #fff;
    }

`