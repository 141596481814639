import styled from 'styled-components'
import { darken } from 'polished'

export const Container = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
        width: 100%;
        color: var(--text-title);
        font-size: 1.5rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid #d7d7d7;
    }

    input[type=text],
    input[type=email] ,
    input[type=number],
    input[type=password],
    select  {
        width: 100%;
        padding: 0 1.5rem;
        height: 4rem;
        border-radius: 0.25rem;
        border: 1px solid #d7d7d7;
        background: #e7e9ee;

        font-weight: 400;
        font-size: 1rem;

        &::placeholder{
            color: var(--text-body);
        }
        //todo input que tiver um input acima vai receber margin top
        & + input {
            margin-top: 0.15rem;
        }

    }

    button[type=submit] {
        width: 50%;
        padding: 0 1.5rem;
        height: 4rem;
        background: var(--green);
        color: #FFFFFF;
        border-radius: 0.25rem;
        border: 0;
        font-size: 1rem;
        margin-top: 1.8rem;
        font-weight: 600;

        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.9);
        }
    }
    .rs-form-group {
        width: 100%;
        margin-top: 1.25rem;
        .close_search {
            width: 40px;
            margin-right: 5px;
            margin-bottom: 5px;
            z-index: 2000;
            a {
                background-color: var(--red);
                color: rgb(255, 255, 255);
            }
            &:hover,
            &:focus{
                border-color: var(--red) !important;
            }
        }
    }
`


export const ContainerCardForm = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    @media (max-width: 768px) {
        flex-direction: column;
    }

    .card-form {
        display: grid;
        flex: 1;
        gap: 1rem;
        margin: 0px 1rem;
        @media (max-width: 768px) {
            width: 100%;
            margin: 0px;
            h3{
                margin-top: 40px;
            }
        }
    }

`

export const StatusFilialContainer = styled.div`
    margin: 1rem 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap : 0.5rem;

`

interface RadioBoxProps {
    isActive: boolean;
    activeColor: 'green' | 'red';
    activeColorFonte: 'white' | 'white';
}

const colors = {
    green: '#33CC95',
    red: '#A7282F'
}


const fonte = {
    white: '#FFFFFF',
}

export const RadioBox = styled.button<RadioBoxProps>`
    height: 4rem;
    border: 1px solid #d7d7d7;
    border-radius: 0.25rem;

    background: ${(props) => props.isActive 
        ? colors[props.activeColor]
        : 'transparent'
    };

    display : flex;
    align-items:center;
    justify-content: center;

    span {
        display: inline-block;
        font-size: 1rem;
        color: ${(props) => props.isActive 
            ? fonte[props.activeColorFonte]
            :  '#363F5F'
        };
    }

    transition:  border-color 0.2s;

    &:hover {
        border-color: ${darken(0.1, '#d7d7d7')};
    }

    &:focus {
        outline: ${darken(0.1, '#d7d7d7')};
    }
`

export const FotoContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    label{
        margin-top: 10px;
        margin-bottom:0px;
    }
    input{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 0px;
        opacity: 0;
        cursor: pointer;
        z-index: 1000;
    }

`
