import styled from 'styled-components'


export const Container = styled.form`
	width: 100%;
	max-width: 700px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 20px;
	position: absolute;
	margin: 0 auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	
	img {
		width: 200px;
		margin-bottom: 20px;
	}
	.senha {
		margin-top: 10px;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}

	input[type=text],
	input[type=email] ,
	input[type=number] ,
	input[type=password]  {
		width: 100%;
		max-width: 450px;
		padding: 0 1.5rem;
		height: 4rem;
		border-radius: 0.25rem;
		border: 1px solid #d7d7d7;
		background: #e7e9ee;

		font-weight: 400;
		font-size: 1rem;

		&::placeholder{
			color: var(--text-body);
		}
		//todo input que tiver um input acima vai receber margin top
		& + input {
			margin-top: 1.25rem;
		}

	}

	button[type=submit] {
		width: 50%;
		padding: 0 1.5rem;
		height: 4rem;
		background: var(--red);
		color: #FFFFFF;
		border-radius: 0.25rem;
		border: 0;
		font-size: 1rem;
		margin-top: 1.8rem;
		font-weight: 600;

		transition: filter 0.2s;

		&:hover {
			filter: brightness(0.9);
		}
	}

	.cancelar {
		width: 50%;
		padding: 0 1.5rem;
		height: 40px;
		background: #EEE;
		color: #333;
		border-radius: 0.25rem;
		border: 0;
		font-size: 1rem;
		margin-top: 1.8rem;
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		transition: filter 0.2s;

		&:hover {
			filter: brightness(0.9);
		}
	}
`;
