import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    :root {
        --background: #f0f2f5;
        --red: #A7282F;
        --red-light: #e8dbdd;
        --green: #33CC95;
        --red-dark: #dc969d;
        --purple: #800080;
        --brown: #964B00;
        --gray: #A0A0A0;


        --text-title: #363F5F;
        --text-body: #969CB3;

        --shape: #FFFFFF;
        --info-color: #2196f3;
        --info-light-color: #e9f5fe;

        --success-color: #4caf50;
        --success-light-color: #edfae1;

        --warning-color: #ffb300;
        --warning-light-color: #fff9e6;

        --error-color: #f44336;
        --error-light-color: #fde9ef;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: none;
    }

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    html{ 
        height: 100%;
        @media (max-width: 1080px){
            font-size: 93.75%;
        }
        @media (max-width: 720px){
            font-size: 87.5%;
        }
    }

    body {
        height: 100%;
        background: var(--background);
        -webkit-font-smoothing: antialiased;
        overflow:hidden;
    }

    body, input, textarea, button {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 600;
    }

    button { 
        cursor: pointer;
    }

    [disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }

    .react-modal-overlay{
        background: rgba(0,0,0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: visible;

        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 200;
    }

    .react-modal-content{
        width: 100%;
        max-width: 1200px;
        max-height: calc(100vh - 20px);
        background: var(--background);
        padding: 2rem;
        position: relative;
        margin: 10vh 20px 10vh 20px;
        border-radius: 0.25rem;
        overflow-y: auto;
    }
    .react-modal-content.sm{
        width: 100%;
        max-width: 700px;
        max-height: calc(100vh - 20px);
        background: var(--background);
        padding: 2rem;
        position: relative;
        margin: 10vh 20px 10vh 20px;
        border-radius: 0.25rem;
        overflow-y: auto;
    }
    .react-modal-content.full{
        width: 100%;
        max-width: 90%;
        min-height: 500px;
        max-height: calc(100vh - 20px);
        background: var(--background);
        padding: 2rem;
        position: relative;
        margin: 10vh 20px 10vh 20px;
        border-radius: 0.25rem;
        overflow-y: auto;
    }
    
    .react-modal-content-search{
        width: 100%;
        max-width: 500px;
        max-height: calc(100vh - 20px);
        background: var(--background);
        padding: 2rem;
        position: relative;
        margin: 10vh 10px 10vh 10px;
        border-radius: 0.25rem;
        overflow-y: auto;
    }

    .react-modal-close {
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
        border: 0;
        background: transparent;
        font-size: 1.5rem;

        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.8);
        }
    }
    .rs-nav-item-active{
        background-color: #e5e5ea;
    }
    .rs-nav-default .rs-nav-item > .rs-nav-item-content{
        border-radius: 0px;
    }
    .rs-nav-item-active .rs-nav-item-content{
        background-color: var(--red) !important;
        color: #FFFFFF !important;
    }

    .ul-pullRight a{
        width: 100%;
        height: 100%;
        display: flex !important;
        align-items: center !important;
    }
    .ul-pullRight a i{
        font-size: 25px;
    }

    .rs-nav.rs-nav-default.rs-navbar-right.rs-nav-horizontal ul{
        width: 100%;
        height: 100%;
    }
    
    .container_admin {
        height: calc(100vh - 70px); 
        overflow-y: scroll;
    }

    .container_admin::-webkit-scrollbar {
        width: 10px;
    }

    .container_admin::-webkit-scrollbar-thumb {
        background-color: var(--red);
        border-radius: 10px;
    }

    .container_admin::-webkit-scrollbar-track{
        background-color: #FFF;
    }

    
    
`;
