import React from 'react';
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.min.css';

import { login } from "../../services/auth";
import api from '../../services/api'
import * as S from "./styles";
import logo from "../../assets/logo_mascote.png";
import { Alert, Loader, Panel } from 'rsuite';

const Login: React.FC = () => {

    const history = useHistory();

    const usernameRef = React.createRef<HTMLInputElement>();
    const passwordRef = React.createRef<HTMLInputElement>();

    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
	const [loading, setLoading] = useState(false);
    const [mostraFormSenha, setMostraFormSenha] = useState(false);

    const renderLoader = () => {
        return (
            <Panel style={{ padding: "4rem", textAlign: 'center', backgroundColor: "rgba(15, 19, 26, 0.1)", width: "100vw", height: "100vh", position: "absolute" }}>
                <Loader size="md" content="Carregando" />
            </Panel>
        )
    }
    const handleClick = () => {
        
        setLoading(true)

        if (!username || !password) {
            setLoading(false)
            Alert.error('Digite a senha ou o email.')
            return;
        }

        api.post('/filial/auth/login', { email: username, password: password}).then((response) => {
            
            localStorage.setItem('LCHAMA', response.data.dados.loja);
            localStorage.setItem('TCHAMA', response.data.dados.tipo);
            localStorage.setItem('FCHAMA', response.data.dados.filial);
            login(response.data.access_token)
            Alert.success("Login efetuado com sucesso!");
            setTimeout(() => {
                return history.push("/")
            }, 1000);
            setLoading(false)
            
        }).catch((err) => {
            setLoading(false)
            Alert.error(err.response.data.message);
            return false
        })
    }

    const handleEnviaEmail = () => {
        
        setLoading(true)

        if (!username) {
            setLoading(false)
            Alert.error('Digite o email.')
            return;
        }

        api.post('/filial/auth/esqueci-minha-senha', { email: username}).then((response) => {
            Alert.success(response.data.message);
            setLoading(false);
            
        }).catch((err) => {
            setLoading(false);
            Alert.error(err.response.data.message);
            return false
        })
    }
    
    const handleSenha = async () => {
        setMostraFormSenha(!mostraFormSenha)
    };


    return (
        <S.Container>        
            {loading ? renderLoader() : ''}
            <div>
                <img className="logo" alt="logo" src={logo} />
            </div>
            <input
                type="text"
                className="text"
                placeholder="Email"
                onChange={(e) => setUsername(e.target.value)}
                ref={usernameRef}
            />
            {!mostraFormSenha ?
            <input
                type="password"
                className="text"
                placeholder="Senha"
                onChange={(e) => setPassword(e.target.value)}
                ref={passwordRef}
            />
            : null}
            {!mostraFormSenha ?
            <p className="senha" onClick={() => handleSenha()}>Esqueceu sua senha?</p>
            : null}
            {!mostraFormSenha ?
            <button type="submit" onClick={handleClick}>Entrar</button> 
            :
             <>
            <button type="submit" onClick={handleEnviaEmail}>Recuperar senha</button>
            <p className="cancelar" onClick={() => handleSenha()}>Cancelar</p>
            </>}
            
        </S.Container>
    )
}

export default Login;
