import { FormEvent, useState   } from 'react'
import Modal from 'react-modal'
import { useFilial } from "../../hooks/useFilial"

import { MdClose } from "react-icons/md"
import * as S from "./styles"

import {FormGroup} from 'rsuite';


interface SearchFilialModalProps {
    isOpenSearch: boolean;
    onRequestCloseSearch: () => void;
}



export function SearchFilialModal({isOpenSearch, onRequestCloseSearch}: SearchFilialModalProps) {
    const { isLoading, filialSearch } = useFilial();
    
    const [searchFilial, setSearchFilial] = useState(''); 
    const [nome_fantasia, setNomeFantasia] = useState(''); 
    const [status, setStatus] = useState('');


    
    async function handleClickOpenSearchFilial(event: FormEvent){
           event.preventDefault();
        const data = {
            status,
            razao_social: searchFilial,
            nome_fantasia
        };

        await filialSearch(data, onRequestCloseSearch);
    };

    
    const status_lista = {
        1: "Ativo",
        2: "Inativo"
    };

    function optionsStatus() {
        var es = Object.entries(status_lista);
        return es.map((item) => (
            <option key={`status${item[0]}`} value={item[0]}>
                {item[1]}
            </option>
        ));
    }


    return (
        
        <Modal
            isOpen={isOpenSearch}
            onRequestClose={onRequestCloseSearch}
            overlayClassName="react-modal-overlay"
            className="react-modal-content-search"
        >
            <button type="button" onClick={onRequestCloseSearch} className="react-modal-close">
                <MdClose />
            </button>
            <S.Container onSubmit={handleClickOpenSearchFilial}>
                <h2>Pesquisar</h2>
                <S.ContainerCardForm>
                    <div className="card-form">

                        <FormGroup>
                            <label>Digite a razão social: </label>
                            <input 
                                type="text" 
                                placeholder="Razão Social"
                                value={searchFilial}
                                onChange={event => setSearchFilial(event.target.value)}
                                
                            /> 
                        </FormGroup>
                        <FormGroup>
                            <label>Digite o nome fantasia: </label>
                            <input 
                                type="text" 
                                placeholder="Nome fantasia"
                                value={nome_fantasia}
                                onChange={event => setNomeFantasia(event.target.value)}
                                
                            /> 
                        </FormGroup>
                        <FormGroup>
                        <label>Status:</label>
                            <select
                                placeholder="Status"
                                onChange={(e) => {
                                    setStatus(e.target.value);
                                }}
                                value={status}
                            >
                                <option key="" value="">
                                    Selecione o status
                                </option>
                                {optionsStatus()}
                            </select>
                        </FormGroup>
                     
                    </div>
                        
                </S.ContainerCardForm>
                
                <button type="submit">{isLoading ? 'Carregando...' : 'Buscar'}</button>
            </S.Container>
        </Modal>
    )
}
