import { FilialTable } from "../../components/FilialTable";
import {useState} from 'react'
import * as S from "./styles";
import { NewFilialModal } from '../../components/NewFilialModal';
import { EditFilialModal } from '../../components/EditFilialModal';
import { FilialProvider } from '../../hooks/useFilial'
import Admin from '../../components/Layouts/admin';


export function Filial() {
    const [isNewFilialModalOpen, setIsNewFilialModalOpen] = useState(false);   
    const [isEditFilialModalOpen, setIsEditFilialModalOpen] = useState(false);   

    function handleOpenNewFilialModal(){
        setIsNewFilialModalOpen(true);
    }

    function handleCloseNewFilialModal(){
        setIsNewFilialModalOpen(false);
    }

    
    function handleOpenEditFilialModal(){
        setIsEditFilialModalOpen(true);
    }

    function handleCloseEditFilialModal(){
        setIsEditFilialModalOpen(false);
    }
    
    return (
        <FilialProvider>
            <Admin pageTitle={'Filial'} > 
                <S.Container>
                    <FilialTable onOpenNewFilialModal={handleOpenNewFilialModal} onOpenEditFilialModal={handleOpenEditFilialModal} />
                    
                    <NewFilialModal 
                        isOpen={isNewFilialModalOpen}
                        onRequestClose={handleCloseNewFilialModal}
                    />
                    { isEditFilialModalOpen ?  <EditFilialModal 
                    isOpenEdit={isEditFilialModalOpen}
                    onRequestCloseEdit={handleCloseEditFilialModal}
                    /> : null }
                
                </S.Container>
            </Admin>    
        </FilialProvider>
    );
}
