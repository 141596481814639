import { useLoja } from "../../hooks/useLoja";
import * as S from "./styles";
import { Pagination, IconButton, Icon, Modal, Button } from 'rsuite';
import {Loading} from '../Loading'
import {useState} from 'react';

import Skeleton from "react-loading-skeleton";



export function LojaTable() {

    const { loja, isLoading, getlojaPorUuid, lojaPagination, lojaPage, lojaDelete } = useLoja();

    const [deletar, setDeletar] = useState('');
    const [usuarioDeletar, setusuarioDeletar] = useState('');
  
    const handleClickOpenEdit = async (url: any, page:any) => {
        await getlojaPorUuid(url, page);
        // onOpenEditLojaModal();
    };
  
    const handleSelect = async (eventKey: any) => {
        await lojaPage(eventKey);
    }

    const handleClickNewLoja = async (url: any) => {
        setTimeout(() => {
            window.location.pathname = '/nova-loja/';
        }, 1000);
        // onOpenEditLojaModal();
    };

    // MODAL DELETE
    const [modalDelete, setModalDelete] = useState(false);

    function handleModalDeleteClose (){
        setModalDelete(false);
    }
    
    const handleModalshowDelete = async (uuid: any, usuario:any) => {
        setModalDelete(true);
        setDeletar(uuid);
        setusuarioDeletar(usuario);
    }

    const handleModalDeletar = async (uuid: any, modal: any, usuario:any) => {

        await lojaDelete(uuid, usuario, modal);
    }

    return (
        <S.Container>
            <div>
                {isLoading ? <Loading /> : ''}
            </div>
            <button type="button" onClick={handleClickNewLoja}>
                Nova Loja
            </button>
      
            <table>
                <thead>
                    <tr>
                        <th>Nome fantasia</th>
                        <th>Data de criação</th>
                        <th className="status">Status</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody>
                    {loja && loja.map(loja => (
                        <tr 
                            key={loja.uuid}  
                        >
                            <td
                            onClick={() => handleClickOpenEdit(loja.url, lojaPagination[0].pagina_atual)}>{isLoading ? <Skeleton /> : loja.nome}</td>
                            <td
                            onClick={() => handleClickOpenEdit(loja.url, lojaPagination[0].pagina_atual)}>
                                {isLoading ? <Skeleton /> : loja.data_criacao.data}
                            </td>
                            <td 
                            onClick={() => handleClickOpenEdit(loja.url, lojaPagination[0].pagina_atual)} className={loja.status === 1 ? 'ativo' : 'inativo'}> 
                                {isLoading ? <Skeleton /> : <div></div>}
                            </td>
                            <td>
                                <IconButton size="lg" style={{padding: "0px", width: "42px", marginBottom: "0px"}} color="red" onClick={() => handleModalshowDelete(loja.uuid, loja.usuario.uuid)} icon={<Icon icon="close" />} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <S.ContainerPagination>
            {lojaPagination.length ?
                <Pagination
                prev
                last
                next
                first
                size="lg"
                pages={lojaPagination[0].ultima_pagina}
                activePage={lojaPagination[0].pagina_atual}
                onSelect={handleSelect}
                />
                : null
            }
            </S.ContainerPagination>
            <Modal backdrop="static" show={modalDelete} onHide={handleModalDeleteClose} size="xs">
                <Modal.Body>
                    <Icon
                    icon="remind"
                    style={{
                        color: '#990606',
                        fontSize: 24
                    }}
                    />
                    {'  '}
                    Tem certeza que deseja deletar essa loja?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleModalDeletar(deletar, handleModalDeleteClose, usuarioDeletar)} color="red">
                    Quero deletar
                    </Button>
                    <Button onClick={handleModalDeleteClose} appearance="subtle">
                    Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </S.Container>
    );
}
