import React from 'react';
import { useState } from 'react'
/* import { useHistory } from 'react-router-dom' */
import 'react-toastify/dist/ReactToastify.min.css';

import api from '../../services/api';
import * as S from "./styles";
import logo from "../../assets/logo_mascote.png";
import { Alert, Loader, Panel } from 'rsuite';

import {
    useParams
  } from "react-router-dom";

const NovaSenha: React.FC = () => {

    const [confirmar_senha, setPasswordConfirme] = useState<string>('')
    const [nova_senha, setPassword] = useState<string>('')
	const [loading, setLoading] = useState(false);
    
    const { token } : any = useParams();
    
    const renderLoader = () => {
        return (
            <Panel style={{ padding: "4rem", textAlign: 'center', backgroundColor: "rgba(15, 19, 26, 0.1)", width: "100vw", height: "100vh", position: "absolute" }}>
                <Loader size="md" content="Carregando" />
            </Panel>
        )
    }
    
    const handleEnviaSenha = (e: any) => {
        e.preventDefault();
        
        setLoading(true);

        const config = {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
        };

        api.put('/filial/usuario/nova-senha', {nova_senha, confirmar_senha}, config).then(response => {
            if(response){
                Alert.success(response.data.message);
                setLoading(false);
                  setTimeout(() => {
                        window.location.pathname = '/';
                    }, 1000);
            }         
        }).catch(err => {
            if(err.response) {
                Alert.error(err.response.data.message);
                setLoading(false);
                return false;
            }
        });
    };



    return (
        <S.Container>        
            {loading ? renderLoader() : ''}
            <div>
                <img className="logo" alt="logo" src={logo} />
            </div>
            <input
                type="password"
                className="text"
                placeholder="Senha nova"
                onChange={(e) => setPassword(e.target.value)}
            />
            <input
                type="password"
                className="text"
                placeholder="Confirme sua senha"
                onChange={(e) => setPasswordConfirme(e.target.value)}
            />
            <button type="submit" onClick={handleEnviaSenha}>Cadastrar nova senha</button>
        </S.Container>
    )
}

export default NovaSenha;
