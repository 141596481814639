import { FormEvent, useState } from 'react'
import Modal from 'react-modal'
import { useBanner } from "../../hooks/useBanner"

import { MdClose } from "react-icons/md"
import * as S from "./styles"

import {FormGroup, InputGroup, Icon} from 'rsuite';
import { useFilial } from "../../hooks/useFilial";
import {Loading} from '../Loading';


interface EditBannerModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
}

export function EditBannerModal({isOpen, onRequestClose}: EditBannerModalProps) {
    const { isLoading, editBanner, bannerPorUUID, activePage } = useBanner();
    const { filial } = useFilial();
    const tipoUsuario = Number(localStorage.getItem('TCHAMA'));
    const filialUsuario = Number(localStorage.getItem('FCHAMA'));
    
    const [imagem, setImagem] =  useState(bannerPorUUID[0]?.imagem ||'');
    const [imagem_uplaod, setImagemUpload] =  useState(bannerPorUUID[0]?.imagem ||'');
    const [url, setURL] = useState(bannerPorUUID[0]?.url || '');
    const [titulo, setNome] = useState(bannerPorUUID[0]?.titulo || '');
    const [status, setStatus] = useState(bannerPorUUID[0]?.status || '');
    const [filiais, setFilial] = useState(bannerPorUUID[0]?.filial || '');

     async function handleCreateNewUsuarioFilial(event: FormEvent){
        event.preventDefault();
        let uuid = bannerPorUUID[0].uuid ? bannerPorUUID[0].uuid : '';

        const data = {
            uuid,
            url,
            titulo,
            imagem: imagem_uplaod,
            filial: Number(tipoUsuario) === 1 ? filiais : filialUsuario,
            status
        };

        await editBanner(data, onRequestClose, bannerPorUUID[0].uuid, activePage);

      
    }

  
    const onImageChange = (event: any ) => {
        const { files } = event.target;
        setImagem(URL.createObjectURL(files[0]));
        setImagemUpload(files[0]);
        
    };

    const handlClearFoto = async () => {
        setImagem('');
        setImagemUpload('');
    };


    return (
        
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <button type="button" onClick={onRequestClose} className="react-modal-close">
                <MdClose />
            </button>
            <S.Container onSubmit={handleCreateNewUsuarioFilial}>
                <div>
                    {isLoading ? <Loading /> : ''}
                </div>
                <h2>Editar Banner</h2>
                <S.ContainerCardForm>
                <div className="card-form">
                    <FormGroup>
                        <S.FotoContainer>
                        {imagem ? (
                        <>
                        <InputGroup className="close_search" label="Excluir avatar">
                            <InputGroup.Button onClick={() => handlClearFoto()}>
                                <Icon icon="close" />
                            </InputGroup.Button>
                        </InputGroup>
                        <figure style={{ backgroundImage: `url(${imagem})` }} onChange={onImageChange}></figure>
                        </>
                        ) : (
                        <>
                        <Icon icon="file-image-o" size="4x" />
                        <input
                            type="file"
                            id="upload-button"
                            onChange={onImageChange}
                        />
                        <label>Clique aqui para inserir uma imagem</label>
                        </>
                        )}
                        </S.FotoContainer>
                    </FormGroup>
                    {tipoUsuario === 1 ?
                    <FormGroup>
                        <label>Filial: </label>
                        <select
                            value={filiais}
                            onChange={event => setFilial(event.target.value)}
                            name="filial">
                            <option value="">Selecione a Filial do usuário</option>
                            {filial && filial.map(filial => (
                            <option value={filial.uuid} key={filial.uuid}>
                                {filial.razao_social}
                            </option>
                            ))}
                        </select>
                    </FormGroup>
                    : null }
                    <FormGroup>
                        <label>URL</label>
                        <input
                            type="text"
                            placeholder="Nome"
                            value={url} 
                            onChange={event => setURL(event.target.value)}    
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Título</label>
                        <input
                            type="text"
                            placeholder="Nome"
                            value={titulo} 
                            onChange={event => setNome(event.target.value)}    
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Status:</label>
                        <S.StatusFilialContainer>
                            <S.RadioBox 
                                type="button"
                                onClick={() => setStatus(1)}
                                isActive={status === 1}
                                activeColor="green"
                                activeColorFonte="white"
                            >   
                                <span>Ativo</span>
                            </S.RadioBox>
                            <S.RadioBox 
                                type="button"
                                onClick={() => setStatus(2)}
                                isActive={status === 2}
                                activeColor="red"
                                activeColorFonte="white"
                            >
                                <span>Inativo</span>
                            </S.RadioBox>
                        </S.StatusFilialContainer>
                    </FormGroup>

                </div>
                </S.ContainerCardForm>
                
                <button type="submit">{isLoading ? 'Carregando...' : 'Atualizar'}</button>
            </S.Container>
        </Modal>
    )
}
