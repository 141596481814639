import Admin from "../../components/Layouts/admin";
import {useState} from 'react'
import * as S from "./styles";
import { BannerTable } from "../../components/BannerTable";
import { BannerProvider } from '../../hooks/useBanner'
import { FilialProvider } from '../../hooks/useFilial'
import { NewBannerModal } from '../../components/NewBannerModal';
import { EditBannerModal } from '../../components/EditBannerModal';


export function Banner() {
    const [isNewBannerModalOpen, setIsNewBannerModalOpen] = useState(false);  
    const [isEditBannerModalOpen, setIsEditBannerModalOpen] = useState(false);  

   function handleOpenNewBannerModal(){
        setIsNewBannerModalOpen(true);
    }

    function handleCloseNewBannerModal(){
        setIsNewBannerModalOpen(false);
    }

    function handleOpenEditBannerModal(){
        setIsEditBannerModalOpen(true);
    }

    function handleCloseEditBannerModal(){
        setIsEditBannerModalOpen(false);
    }

    return (
        <BannerProvider>
            <FilialProvider>
                <Admin pageTitle={"Banner"}>
                    <S.Container>
                        <BannerTable onOpenNewBannerModal={handleOpenNewBannerModal}  onOpenEditBannerModal={handleOpenEditBannerModal} />

                                {isNewBannerModalOpen ? 
                                    
                                    <NewBannerModal 
                                        isOpen={isNewBannerModalOpen}
                                        onRequestClose={handleCloseNewBannerModal}
                                    />
                                : null
                                }
                                {isEditBannerModalOpen ? 
                                    
                                    <EditBannerModal 
                                        isOpen={isEditBannerModalOpen}
                                        onRequestClose={handleCloseEditBannerModal}
                                    />
                                : null
                                }
                    </S.Container>
                </Admin>
            </FilialProvider>
        </BannerProvider>
    );
}
