import * as S from "./styles";
import { LojaProvider } from '../../hooks/useLoja'
import { CategoriaProvider } from '../../hooks/useCategoria'
import Admin from '../../components/Layouts/admin';
import { VerLoja } from "../../components/VerLoja";
import { FilialProvider } from '../../hooks/useFilial'

export function VisualizarLoja() {
    return (
        <LojaProvider>
            <FilialProvider>
                <CategoriaProvider>
                    <Admin pageTitle={'Visualizar Loja'} > 
                        <S.Container>
                            <VerLoja />
                        </S.Container>
                    </Admin>    
                </CategoriaProvider>
            </FilialProvider>
        </LojaProvider>
    );
}
